import React, { FunctionComponent} from 'react';
import { Redemption } from '../models/Redemption';
import NumberInputField, { NumberInputFieldKind } from '../../../common/components/input-fields/NumberInputField';
import { BooleanInputField } from '../../../common/components/input-fields/BooleanInputField';
import DropdownInputField from '../../../common/components/input-fields/DropdownInputField';
import { Translate } from 'react-localize-redux';
import { DebtType } from '../models/DebtType';
import BanksInputField from "../../../common/components/input-fields/BanksInputField";

interface EditLoanFormProps {
    redemption: Redemption;
    errors: string[];
    isComplete: boolean;
    onRedemptionChanged: (oldRedemption: Redemption, newRedemption: Redemption) => void;
}

const debtOwnerOptions = [
    { id: 'applicant', name: <Translate id="DEBT_OWNER.APPLICANT" /> },
    { id: 'co applicant', name: <Translate id="DEBT_OWNER.CO_APPLICANT" /> },
    { id: 'shared with co applicant', name: <Translate id="DEBT_OWNER.SHARED_WITH_CO_APPLICANT" /> }
];

const EditLoanForm: FunctionComponent<EditLoanFormProps> = (props) => {
    const onValueChanged: (value: string | number | Date | boolean | null | undefined, name: string) => void = (value, name) =>
        props.onRedemptionChanged(props.redemption, { ...props.redemption, [name]: value });

    const renderCreditLimit = () =>
        props.redemption.debtType === DebtType.CreditCard
            ? 
            <NumberInputField
                name="creditLimit"
                value={props.redemption.creditLimit}
                kind={NumberInputFieldKind.Money}
                editMode={true}
                descriptionKey="CREDIT_LIMIT"
                onValueChanged={onValueChanged}
                nullable={true}
            />
        : null;

    return (
        <div>
            <BanksInputField
                descriptionKey="BANK"
                value={props.redemption.lender}
                editMode
                required="RedemptionLenderRequired"
                onCustomInput={lender => onValueChanged(lender, 'lender')}
            />
            <NumberInputField
                name="balance"
                value={props.redemption.balance}
                kind={NumberInputFieldKind.Money}
                editMode={true}
                descriptionKey="LOAN_AMOUNT"
                onValueChanged={onValueChanged}
                required={props.isComplete && props.redemption.debtType !== DebtType.CreditCard && props.redemption.debtType !== DebtType.ConsumerLoan ? 'RedemptionBalanceRequired' : undefined}
                errors={props.errors}
                overrideInternalErrors={['valueMissing']}
            />
            {renderCreditLimit()}
            <div style={{ display: 'flex' }}>
                <div>
                    <NumberInputField
                        name="repaymentMonthlyCost"
                        value={props.redemption.repaymentMonthlyCost}
                        kind={NumberInputFieldKind.MoneyPerMonth}
                        editMode={true}
                        descriptionKey="INSTALLMENT_PER_MONTH"
                        onValueChanged={onValueChanged}
                        nullable={true}
                    />
                </div>
                <div style={{ marginLeft: '19px', minWidth: '97px', maxWidth: '97px' }}>
                    <NumberInputField
                        name="interestRate"
                        value={props.redemption.interestRate}
                        kind={NumberInputFieldKind.Percent}
                        editMode={true}
                        descriptionKey="INTEREST_RATE"
                        onValueChanged={onValueChanged}
                        nullable={true}
                        step={0.01}
                    />
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <div>
                    <NumberInputField
                        name="remainingRepaymentPeriod"
                        value={props.redemption.remainingRepaymentPeriod}
                        kind={NumberInputFieldKind.Months}
                        editMode={true}
                        descriptionKey="REMAINING_REPAYMENT_PERIOD"
                        onValueChanged={onValueChanged}
                    />
                </div>
                <div style={{ marginLeft: '19px' }}>
                    <BooleanInputField
                        name="refinance"
                        value={props.redemption.refinance}
                        editMode={true}
                        disabled={false}
                        descriptionKey="REFINANCE"
                        onValueChanged={onValueChanged}
                    />
                </div>
            </div>
            <DropdownInputField
                name="debtOwner"
                value={props.redemption.debtOwner}
                editMode={true}
                descriptionKey="RESPONSIBLE_FOR_LOAN"
                items={debtOwnerOptions}
                onValueChanged={onValueChanged}
                required={props.isComplete ? 'RedemptionDebtOwnerRequired' : undefined}
                errors={props.errors}
                overrideInternalErrors={['valueMissing']}
            />
        </div>
    );
};

export default EditLoanForm;
