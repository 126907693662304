import React, { FunctionComponent } from "react";
import {
    getFilterProp,
    RecipientsFilterProps,
    renderBooleanInputFieldFilter,
    renderDateTimeInputFieldFilter,
    renderDropdownInputFieldFilter,
    renderStringCollectionInputFilter,
    renderSubstatusesInputFieldFilter
} from "./RecipientsFilterContainer";
import mapEnumToSelectableItems from "../../../../../../common/helpers/mapEnumToSelectableItems";
import {
    CampaignType
} from "../../../../../../common/models/Campaign";
import { getEnumTranslationKey } from "../../../../../../common/helpers/getTranslationKey";
import { getLanguageItems } from "../../../../../../common/helpers/getLanguageItems";
import { ProductType } from "../../../../../../common/models/ProductType";
import { MultiSelect } from "../../../../../../common/components/MultiSelect";
import CustomerAdvisorsInputField from "../../../../../../common/components/input-fields/CustomerAdvisorsInputField";
import {mapProductTypesToSelectableItems} from "../../../../../../common/helpers/productTypeFunctions";
import { ApplicationStatus } from "../../../../../../common/models/ApplicationStatus";

const RecipientsFilterPreview: FunctionComponent<RecipientsFilterProps> = (props) => (
    <div className="edit">
        {renderLastApplicationStatusFilter(props)}
        {renderLanguageFilter(props)}
        {renderProductTypesFilter(props)}
        {renderIncludedSubstatusesFilter(props)}
        {renderExcludedSubstatusesFilter(props)}
        {props.campaign ?
            props.campaign.type === CampaignType.SMS ? renderMobileNumbersFilter(props)
                : renderEmailsFilter(props)
            : null
        }
        {renderLastCampaignFilter(props)}
        {renderSkipActiveFollowUpsFilter(props)}
        {renderSubmittedFromFilter(props)}
        {renderSubmittedToFilter(props)}
        {renderLastStatusChangeFromFilter(props)}
        {renderLastStatusChangeToFilter(props)}
        {renderCustomerAdvisorsFilter(props)}
        {renderExcludeInkassoFilter(props)}
        {renderHasPropertyFilter(props)}
    </div>
);

const renderLastApplicationStatusFilter = (props: RecipientsFilterProps) => (
    renderDropdownInputFieldFilter(
        'lastApplicationStatus',
        'LOAN_STATUS.LABEL',
        mapEnumToSelectableItems(
            ApplicationStatus,
            item => props.translate(getEnumTranslationKey(ApplicationStatus, item, 'LOAN_STATUS', 'ALL')).toString(),
            true
        ),
        props
    )
);

const renderLanguageFilter = (props: RecipientsFilterProps) => (
    renderDropdownInputFieldFilter(
        'language',
        'LANGUAGE.LABEL',
        getLanguageItems(props.translate, false),
        props
    )
);

const renderProductTypesFilter = (props: RecipientsFilterProps) => {
    const productTypes = getFilterProp<ProductType[]>('productType', props) || [];
    const onProductTypesChanged = (productTypes: ProductType[]) => props.editRecipientsFilter({ productType: productTypes });
    const productTypesItems = mapProductTypesToSelectableItems(props.translate, true, false);

    return (
        <div>
            <MultiSelect
                descriptionKey="PRODUCT_TYPE"
                className={productTypes.length ? 'filter-active' : ''}
                selectedItems={productTypesItems.filter(item => productTypes.some(productType => item.id === productType))}
                items={productTypesItems}
                onSelectionChanged={items => onProductTypesChanged(items.map(item => item.id as ProductType))}
                placeHolder={props.translate('FILTER_PRODUCT_TYPES').toString()}
            />
        </div>
    );
}

const renderCustomerAdvisorsFilter = (props: RecipientsFilterProps) => {
    const customerAdvisors = getFilterProp<number[]>('customerAdvisors', props) || [];
    const onCustomerAdvisorsChanged = (customerAdvisors: number[]) => props.editRecipientsFilter({ customerAdvisors });
    return (
        <div>
            <CustomerAdvisorsInputField
                descriptionKey="CUSTOMER_ADVISOR"
                editMode={true}
                className={customerAdvisors.length ? 'filter-active' : ''}
                customerAdvisorIds={customerAdvisors}
                onCustomerAdvisorsChanged={onCustomerAdvisorsChanged}
                placeholder={props.translate('HEADER.FILTER_CUSTOMER_ADVISORS').toString()}
            />
        </div>
    );
}

const renderIncludedSubstatusesFilter = (props: RecipientsFilterProps) =>
    renderSubstatusesInputFieldFilter('includedSubstatuses', 'INCLUDED_SUBSTATUSES', props);

const renderExcludedSubstatusesFilter = (props: RecipientsFilterProps) =>
    renderSubstatusesInputFieldFilter('excludedSubstatuses', 'EXCLUDED_SUBSTATUSES', props);

const renderLastCampaignFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('lastCampaign', 'CAMPAIGNS_VIEW.LAST_CAMPAIGN', props);

const renderSkipActiveFollowUpsFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('skipActiveFollowUps', 'CAMPAIGNS_VIEW.SKIP_ACTIVE_FOLLOW_UPS', props);

const renderSubmittedFromFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('submittedFrom', 'SUBMITTED_FROM', props);

const renderSubmittedToFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('submittedTo', 'SUBMITTED_TO', props);

const renderExcludeInkassoFilter = (props: RecipientsFilterProps) =>
    renderBooleanInputFieldFilter('excludeInkasso', 'CAMPAIGNS_VIEW.EXCLUDE_INKASSO', props);

const renderHasPropertyFilter = (props: RecipientsFilterProps) =>
    renderBooleanInputFieldFilter('hasProperty', 'PROPERTY_REQUIRED', props);

const renderMobileNumbersFilter = (props: RecipientsFilterProps) =>
    renderStringCollectionInputFilter('mobileNumbers', 'MOBILE_NUMBERS', props);

const renderEmailsFilter = (props: RecipientsFilterProps) =>
    renderStringCollectionInputFilter('emails', 'EMAILS', props);

const renderLastStatusChangeFromFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('lastStatusUpdateFrom', 'CAMPAIGNS_VIEW.LAST_STATUS_UPDATE_FROM', props);

const renderLastStatusChangeToFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('lastStatusUpdateTo', 'CAMPAIGNS_VIEW.LAST_STATUS_UPDATE_TO', props);

export default RecipientsFilterPreview;