import React, {CSSProperties, FunctionComponent, useEffect} from 'react';
import { MultiSelect } from '../MultiSelect';
import { Substatus } from '../../../applications/applicants/models/Substatus';
import { loanStatusToColor } from '../../helpers/loanStatusToColor';
import { SubstatusesProps } from '../../interfaces/SubstatusesProps';
import SubstatusesActionsCreator from '../../../applications/applicants/actions/SubstatusesActionsCreator';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

interface SubstatusesInputFieldProps {
    className?: string;
    substatusIds: number[];
    onSubstatusesChanged?: (ids: number[]) => void;
    placeholder?: string;
    placeholderKey?: string;
    description?: string;
    descriptionKey?: string;
    editMode?: boolean;
    inlineEditing?: boolean;
    style?: CSSProperties;
}

interface LoadSubstatusesActions {
    loadSubstatuses: () => void;
}

const SubstatusesInputField: FunctionComponent<SubstatusesInputFieldProps & SubstatusesProps & LoadSubstatusesActions & LocalizeContextProps> = (props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { if (props.substatuses === undefined) { props.loadSubstatuses(); } }, []);
    const setSubstatuses = (newSubstatuses: Substatus[]) => props.onSubstatusesChanged && props.onSubstatusesChanged(newSubstatuses.map((x) => x.id));
    const substatuses = props.substatuses === undefined ? [] : props.substatuses;
    const selectedSubstatuses = (props.substatuses === undefined ? [] : props.substatuses.filter((x) => (props.substatusIds.indexOf(x.id) > -1)));
    const placeholder = props.placeholder || (props.placeholderKey && props.translate(props.placeholderKey).toString());
    
    if (!props.editMode) {
        const substatusReadonlyStyle: CSSProperties = {
            fontSize: '12px', 
            padding: '0 5px', 
            borderRadius: '0.25em', 
            marginRight: '8px',
            fontWeight: 'bold'
        };
        return (
            <>
                {
                    selectedSubstatuses.map((substatus) => (
                        <span key={substatus.id} className={styleSubstatus(substatus)} style={substatusReadonlyStyle}>
                            {substatus.name}
                        </span>
                    ))
                }
            </>
        )
    }

    return (
        <MultiSelect
            className={props.className}
            description={props.description}
            descriptionKey={props.descriptionKey}
            selectedItems={selectedSubstatuses}
            items={substatuses}
            onSelectionChanged={setSubstatuses}
            inlineEditing={props.inlineEditing}
            style={props.style}
            placeHolder={placeholder}
            getItemCustomCssClass={styleSubstatus}
        />
    );
};

const styleSubstatus = (s: Substatus): string => (s.loanStatuses.length === 1 ? loanStatusToColor(s.loanStatuses[0], 'status status-') : 'status status-gray');

const mapStateToProps = (state: any) => ({
    ...state.substatusesReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({ ...SubstatusesActionsCreator }, dispatch);

export default connect<SubstatusesProps, LoadSubstatusesActions, SubstatusesInputFieldProps, any>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(SubstatusesInputField));
