import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const grantMarketingConsents = async (email?: string | null, mobileNumber?: string | null): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`/api/MarketingConsents/grant`, { email, mobileNumber });

    return new CommandResult(result);
};
