import React, {FunctionComponent} from "react";
import {getEnumTranslationKey} from "../../../../common/helpers/getTranslationKey";
import mapEnumToSelectableItems from "../../../../common/helpers/mapEnumToSelectableItems";
import moment from "moment/moment";
import RadioButtonsInputField from "../../../../common/components/input-fields/RadioButtonsInputField";
import {BankDataField, BankDataKind, ImportBankDataScenario, ImportBankDataScenarioName} from "./model";
import {TFunction} from "i18next";
import {useTranslate_i18next} from "../../../../common/useTranslate_i18next";

export const importBankDataScenarios: Record<ImportBankDataScenarioName, ImportBankDataScenario> = {
    [ImportBankDataScenarioName.CLAWBACKS]: {
        name: ImportBankDataScenarioName.CLAWBACKS,
        entries: [
            {
                field: BankDataField.CASE_ID,
                kind: BankDataKind.Multi
            },
            {
                field: BankDataField.CLAWBACK,
                kind: BankDataKind.Multi
            },
            {
                field: BankDataField.ISSUED_ON,
                kind: BankDataKind.Date,
                default: moment().subtract(1, 'months').date(15).toDate()
            }
        ],
        matchedEntries: [
            {
                field: BankDataField.LOAN_STATUS,
                kind: BankDataKind.LoanStatus
            },
            {
                field: BankDataField.PAID_AMOUNT,
                kind: BankDataKind.Money
            },
            {
                field: BankDataField.PAYMENT_DATE,
                kind: BankDataKind.Date
            }
        ]
    }
}
interface ImportBankDataScenariosProps {
    scenario: ImportBankDataScenario;
    onScenarioChanged: (scenario: ImportBankDataScenario) => void;
}
const ImportBankDataScenarios: FunctionComponent<ImportBankDataScenariosProps> = (props) => {
    const translate_i18next = useTranslate_i18next();
    const importBankDataScenarioOptions = getImportBankDataScenarioOptions(translate_i18next);
    
    return (
        <div>
            <RadioButtonsInputField
                className="import-bank-data-scenario"
                name="importBankDataScenario"
                value={props.scenario.name}
                descriptionKey="IMPORT_BANK_DATA_SCENARIO.LABEL"
                items={importBankDataScenarioOptions}
                editMode
                onValueChanged={name => props.onScenarioChanged(importBankDataScenarios[name])}
            />
        </div>
    )
}


function getImportBankDataScenarioOptions(translate_i18next: TFunction) {
    const nameMapper = (name: string | null) => translate_i18next(getEnumTranslationKey(ImportBankDataScenarioName, name, 'IMPORT_BANK_DATA_SCENARIO')).toString();
    return mapEnumToSelectableItems(ImportBankDataScenarioName, nameMapper, false, false);
}

export default ImportBankDataScenarios;