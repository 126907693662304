import { LoanStatus } from '../models/LoanStatus';

export function loanStatusToColor(loanStatus?: LoanStatus, prefix?: string, progress?: number | null) {
    prefix = prefix || '';

    switch (loanStatus) {
        case LoanStatus.Incomplete:
            return prefix + 'lightgoldenrodyellow';
        case LoanStatus.Investigation:
            return prefix + 'gray';
        case LoanStatus.Granted:
            return prefix + 'blue';
        case LoanStatus.GrantedSelected:
            return prefix + 'orange';
        case LoanStatus.Sent:
            return prefix + 'turquoise';
        case LoanStatus.Paid:
            return prefix + 'green';
        case LoanStatus.Denied:
            if (progress && progress > 0 && progress < 100) {
                return prefix + 'moccasin';
            }

            return prefix + 'red';
        default:
            return '';
    }
}
