import Axios, { AxiosResponse } from 'axios';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { ConsumerLoanLog } from '../models/ConsumerLoanLog';
import { CommentType } from '../../../common/models/CommentType';

export const getConsumerLoanLogs = (
    personId: number,
    applicationId: number | undefined,
    includeCoApplicant: boolean,
    pageNumber: number,
    pageSize: number,
    orderDirection: number,
    logTypes: number[]): Promise<{ loanLogs: ConsumerLoanLog[], loanLogsCount: number }> => {
    const promise = new Promise<{ loanLogs: ConsumerLoanLog[], loanLogsCount: number }>((executor) => {
        const httpClient = AxiosClientFactory.getInstance();

        const getLoanLogsCountRequestParams = {
            orderDirection,
            applicationId,
            includeCoApplicant
        };

        const getLoanLogsRequestParams = {
            ...getLoanLogsCountRequestParams,            
            pageSize,
            pageNumber,
            commentType: applicationId ? CommentType.Appliaction : CommentType.Insurance,
            applicationId
        };

        // workaround for axios weirdly formatting array into query string
        const logTypesParamString = logTypes.length > 0 ? '?logTypes=' + logTypes.join('&logTypes=') : '';

        const getLoanLogsRequest = httpClient.get<ConsumerLoanLog[] | any>(
            `/api/applicants/${personId}/logs${logTypesParamString}`,
            { params: getLoanLogsRequestParams });
        const getLoanLogsCountRequest = httpClient.get<number | any>(
            `/api/applicants/${personId}/logs/count${logTypesParamString}`,
            { params: getLoanLogsCountRequestParams });

        const mergeAndResolve = (logsResponse: AxiosResponse<ConsumerLoanLog[] | any>, logsCountResponse: AxiosResponse<number | any>) => {
            executor({
                loanLogs: logsResponse.data,
                loanLogsCount: logsCountResponse.data
            });
        };

        Axios.all([getLoanLogsRequest, getLoanLogsCountRequest]).then(Axios.spread(mergeAndResolve));
    });

    return promise;
};
