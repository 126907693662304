import {getTranslate, TranslateOptions, TranslatePlaceholderData} from "react-localize-redux";
import {useStore} from "react-redux";

export type Translate = (id: string, data?: TranslatePlaceholderData, options?: TranslateOptions) => string;

export const useTranslate = () => {
    const store = useStore();
    const translateFn = getTranslate((store.getState() as any).localize)
    return ((id: string,
             data?: TranslatePlaceholderData,
             options?: TranslateOptions) => translateFn(id, data, options).toString()) as Translate;
}