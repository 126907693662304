import React, { FunctionComponent } from 'react';
import { Nav } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { UserImage } from './UserImage';
import { SettingsProps } from '../interfaces/SettingsProps';
import { Location } from 'history';
import TopbarButtons from './TopbarButtons';
import { getSidebarBackgroundColorStyle, getSidebarFontColor, getLogo, getSidebarMenuItems } from '../helpers/settingsHelpers';
import { ToggleSidebarActionCreator, ToggleSidebarActionProps } from '../actions/SidebarActionCreator';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import { UserProps } from '../interfaces/UserProps';
import { MenuItem, MenuItemType } from '../models/MenuItem';
import { getRealEstateInformationPageUrl } from '../../applications/applicant/api/getRealEstateInformationPageUrl';
import ExternalSystemLink from './ExternalSystemLink';
import { User } from '../models/User';
import {SidebarState} from "../reducers/sidebarReducer";
import {useLocation} from "react-router";
import {isAdmin} from "../helpers/isAdmin";

interface SidebarProps extends SettingsProps, UserProps, SidebarState {
}

const isActiveRouteFunctionCreator = (route: string, location: Location, allowDefault?: boolean) => {
    return () => location.pathname.startsWith(`/${route}`) || (!!allowDefault && location.key === 'default');
};

const Sidebar: FunctionComponent<SidebarProps & ToggleSidebarActionProps> = (props) => {
    const location = useLocation();
    let email = '';
    let name = '';

    if (props.userData.user !== undefined) {
        email = props.userData.user.username;
        name = props.userData.user.displayName;
    }

    const sidebarStyle = { ...getSidebarBackgroundColorStyle(props) };
    
    const isActiveApplicantsRoute = isActiveRouteFunctionCreator('applicant', location, true);
    const isActiveCampaignsRoute = isActiveRouteFunctionCreator('campaign', location, false);
    const isActiveMarketingListsRoute = isActiveRouteFunctionCreator('marketing-list', location, false);
    const isActiveAdministrationRoute = isActiveRouteFunctionCreator('administration', location, false);

    return (
        <React.Fragment>
            <Nav className={classNames('sidebar', { hidden: !props.sidebarExpanded })} style={sidebarStyle}>
                <TopbarButtons logoSetting={getLogo(props)} />
                {renderMenu(
                    props, 
                    isActiveApplicantsRoute, 
                    isActiveCampaignsRoute, 
                    isActiveMarketingListsRoute,
                    isActiveAdministrationRoute
                )}
                <div className="user-info">
                    <div>
                        <UserImage email={email} />
                    </div>
                    <div style={getSidebarFontColor(props)} className="user-name">
                        {name}
                    </div>
                </div>
            </Nav>
            <div className={classNames('overlay', { hidden: !props.sidebarExpanded })} onClick={props.toggleSidebar} />
        </React.Fragment>
    );
};

function renderMenu(
    props: SidebarProps & ToggleSidebarActionProps, 
    isActiveApplicantsRoute: () => boolean,
    isActiveCampaignsRoute: () => boolean,
    isActiveMarketingListsRoute: () => boolean,
    isActiveAdministrationRoute: () => boolean
) {
    const settingsMenuItems = getSidebarMenuItems(props);
    const sidebarFontColorStyle = getSidebarFontColor(props);
    return (
        <div className="nav-links-container" onClick={props.toggleSidebar}>
            {renderStandardMenu(
                props, 
                isActiveApplicantsRoute, 
                isActiveCampaignsRoute, 
                isActiveMarketingListsRoute,
                isActiveAdministrationRoute
            )}
            {settingsMenuItems !== null && settingsMenuItems.length > 0 ? renderSettingsMenuItems(settingsMenuItems, props.userData.user, sidebarFontColorStyle) : null}
        </div>
    );
}

function renderStandardMenu(
    props: SidebarProps & ToggleSidebarActionProps,
    isActiveApplicantsRoute: () => boolean,
    isActiveCampaignsRoute: () => boolean,
    isActiveMarketingListsRoute: () => boolean,
    isActiveAdministrationRoute: () => boolean
) {
    const sidebarFontColorStyle = getSidebarFontColor(props);
    return (
        <>
            <NavLink style={sidebarFontColorStyle} to="/applicants?pageNumber=0" className={isActiveApplicantsRoute() ? 'active' : undefined}>
                <Translate id="APPLICANTS" />
            </NavLink>
            <NavLink style={sidebarFontColorStyle} to="/campaigns" className={isActiveCampaignsRoute() ? 'active' : undefined}>
                <Translate id="CAMPAIGNS" />
            </NavLink>
            <NavLink style={sidebarFontColorStyle} to="/marketing-lists" className={isActiveMarketingListsRoute() ? 'active' : undefined}>
                <Translate id="MARKETING_LISTS" />
            </NavLink>
            {isAdmin(props) &&
                <NavLink style={sidebarFontColorStyle} to="/administration" className={isActiveAdministrationRoute() ? 'active' : undefined}>
                    <Translate id="ADMINISTRATION" />
                </NavLink>
            }
        </>
    );
}

function renderSettingsMenuItems(menuItems: MenuItem[], user: User | undefined, style: {color: string | undefined}) {
    return menuItems
        .filter((mi) => !mi.roles || mi.roles.length === 0 || (user !== undefined && mi.roles.includes(user.role)))
        .map((m) => renderMenuItem(m, style));
}

function renderMenuItem(item: MenuItem, style: {color: string | undefined}) {
    if (item.type === MenuItemType.RealEstateInformationPage) {
        const getUrl = () => getRealEstateInformationPageUrl();
        return <ExternalSystemLink getUrl={getUrl} key={item.label} as="a" style={style}>{item.label}</ExternalSystemLink>;
    } else if (item.url) {
        return <a href={item.url} target="_blank" rel="noreferrer" style={style} key={item.label}>{item.label}</a>;
    }

    return null;
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.settingsActionsReducer,
    ...state.sidebarReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(ToggleSidebarActionCreator, dispatch);

export default connect<SidebarProps, ToggleSidebarActionProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(Sidebar);
