import {BankDataEntry, ImportBankDataScenario, ImportBankDataScenarioName, MatchedBankData} from "./model";
import React, {FunctionComponent, ReactElement} from "react";
import {getTranslationKey} from "../../../../common/helpers/getTranslationKey";
import {isValueSet} from "../../../../common/helpers/isValueSet";
import {isDateType, isLoanStatusType, isMoneyType, isMultiType} from "./helpers";
import NumberFormatter from "../../../../common/components/NumberFormatter";
import {loanStatusToColor} from "../../../../common/helpers/loanStatusToColor";
import {getLoanStatusTranslateKey} from "../../../../common/helpers/loanStatusFunctions";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import Translate_i18next from "../../../../common/components/Translate_i18next";
import {LoanStatus} from "../../../../common/models/LoanStatus";

interface MatchedBankDataPreviewProps {
    scenario: ImportBankDataScenario;
    data: MatchedBankData[];
}
const MatchedBankDataPreview: FunctionComponent<MatchedBankDataPreviewProps> = (props) => {

    const renderBankDataFieldCell = (data: MatchedBankData, entry: BankDataEntry) => {
        const value = data[entry.field];
        if (!isValueSet(value)) {
            return null;
        }
        let cellContent: unknown = value;
        if (isMultiType(value, entry.kind)) {
            cellContent = value;
        }
        if (isDateType(value, entry.kind)) {            
            cellContent = new Date(value).toLocaleDateString();
        }
        if (isMoneyType(value, entry.kind)) {
            cellContent = <NumberFormatter value={value}/>;
        }
        if (isLoanStatusType(value, entry.kind)) {
            const statusClassName = `status ${loanStatusToColor(value, 'status-')}`;
            cellContent = (
                <div className={statusClassName}>
                    <Translate_i18next id={getLoanStatusTranslateKey(value)} />
                </div>
            );
        }
        return (
            <td key={JSON.stringify(value)}>
                {cellContent}
            </td>
        )
    }
    
    const isDataMatched = (data: MatchedBankData) => data.applicationId && data.personId;
    
    const renderWarning = (content: string | ReactElement) => {
        const warningStyle = {
            color: '#ff9900',
            display: 'flex',
            columnGap: '8px',
            alignItems: 'center',
            justifyContent: 'center'
        };
        return (
            <span style={warningStyle}>
                <FontAwesomeIcon icon={faExclamationTriangle}/>
                {content}
            </span>
        )
    }
    
    const renderApplicationLinkCell = (data: MatchedBankData) => {
        if (!isDataMatched(data)) {
            return renderWarning(<Translate_i18next id="NOT_MATCHED"/>);
        }
        return (
            <NavLink to={`/applicant/${data.personId}/application/${data.applicationId}`} target="_blank">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </NavLink>
        )
    }
    
    const renderImportWarning = () => renderWarning(<Translate_i18next id={`${props.scenario.name.toUpperCase()}_IMPORT_WARNING`}/>);
    
    const scenarioValidationWarning = (data: MatchedBankData) => {
        switch (props.scenario.name) {
            case ImportBankDataScenarioName.CLAWBACKS:
                return data.loanStatus !== LoanStatus.Paid;
            default:
                return false;
        }
    }

    return (
        <>
            <table>
                <thead>
                <tr>
                    {props.scenario.entries.map((entry, id) => (
                        <th key={id}><Translate_i18next id={getTranslationKey(entry.field, 'BANK_DATA_FIELD')}/></th>
                    ))}
                    {props.scenario.matchedEntries.map((entry, id) => (
                        <th key={id}><Translate_i18next id={getTranslationKey(entry.field, 'BANK_DATA_FIELD')}/></th>
                    ))}
                    <th style={{textAlign: 'center'}}></th>
                </tr>
                </thead>
                <tbody>
                {props.data.map((row, id) => (
                    <tr key={id}>
                        {props.scenario.entries.map((entry, index) => (
                            renderBankDataFieldCell(row, entry)
                        ))}
                        {props.scenario.matchedEntries.map((entry, index) => (
                            renderBankDataFieldCell(row, entry)
                        ))}
                        <td colSpan={100} style={{textAlign: 'center'}}>
                            {renderApplicationLinkCell(row)}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            {props.data.length && props.data.some(d => !isDataMatched(d) || scenarioValidationWarning(d)) ?
                renderImportWarning() : null
            }
        </>
    )
}
export default MatchedBankDataPreview;