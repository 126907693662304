import {AxiosClientFactory} from '../../common/helpers/AxiosClientFactory';
import {
    AggregatedBankData,
    ImportBankDataScenarioName,
    MatchedBankData
} from "../components/Modules/ImportBankData/model";

export const validateBankData = async (bankId: number, scenario: ImportBankDataScenarioName, data: AggregatedBankData[]) => {
        return AxiosClientFactory
            .getInstance()
            .post<MatchedBankData[]>(`api/bank/${bankId}/${scenario}/validate`, data);
};
