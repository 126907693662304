import React, {FunctionComponent, MouseEvent, MouseEventHandler, useState} from 'react';
import {Translate} from 'react-localize-redux';
import {getLoanStatusTranslateKey} from '../../../common/helpers/loanStatusFunctions';
import {LoanStatus} from '../../../common/models/LoanStatus';
import {Applicant} from '../models/Applicant';
import DateTimeFormatter from '../../../common/components/DateTimeFormatter';
import {loanStatusToColor} from '../../../common/helpers/loanStatusToColor';
import getProgressCompletionStyling from '../../applicant/helpers/getProgressCompletionStyling';
import {ApplicantsTableStateProps} from '../../../common/interfaces/ApplicantsTableStateProps';
import {connect} from 'react-redux';
import {faEllipsisVertical, faHome, faQuestionCircle, faUserFriends} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ApplicantTooltip from './ApplicantTooltip';
import classNames from "classnames";
import {Team} from "../../applicant/models/Team";
import Tooltip from "../../../common/components/Tooltip";
import TableRows from "../../../common/components/table/TableRows";
import {UserProps} from "../../../common/interfaces/UserProps";
import {isLimitedCaseWorker} from "../../../common/helpers/limitedCaseWorkerLogic";
import NumberFormatter from "../../../common/components/NumberFormatter";
import {SubstatusesProps} from '../../../common/interfaces/SubstatusesProps';
import SubstatusesActionsCreator from '../actions/SubstatusesActionsCreator';
import {bindActionCreators, Dispatch} from 'redux';
import {Substatus} from '../models/Substatus';
import ProductCell from "../../../common/components/table-cells/ProductCell";
import FollowUpCell from "../../../common/components/table-cells/FollowUpCell";
import {Overlay, Popover} from "react-bootstrap";
import moment from "moment/moment";
import ApplicantActionsMenu from "./ApplicantActionsMenu";

interface ApplicantsTableRowsProps {
    isLoading: boolean;
    applicants: Applicant[];
    teams: Team[];
    editApplicantFollowUp?: Applicant;
    onEditApplicantFollowUpChange?: (applicant?: Applicant) => void;
    onApplicantFollowUpChange?: (applicant: Applicant, followUpDate?: Date | null) => void;
    onSendSmsApplicantChange?: (applicant: Applicant) => void;
    onSendEmailApplicantChange?: (applicant: Applicant) => void;
    onSendSmsAndEmailApplicantChange?: (applicant: Applicant) => void;
    onApplicantCommentSave?: (applicant: Applicant, comment: string) => void;
    onApplicantNotificationsChange?: (applicant: Applicant, disable: boolean) => void;
    onCreateNewApplication?: (applicant: Applicant) => void;
    onClick(applicantId: number, applicationId: number, newTab: boolean): void;
}

const ApplicantsTableRows: FunctionComponent<ApplicantsTableRowsProps & ApplicantsTableStateProps & UserProps & SubstatusesProps> = (props) => {
    const {editApplicantFollowUp, onEditApplicantFollowUpChange} = {...props};
    const [contextMenuAnchor, setContextMenuAnchor] = useState<Element | null>(null);
    
    const renderLoanStatus = (loanStatus: LoanStatus, substatusesIds: number[], substatuses: Substatus[] | undefined, applicantFormProgress: number | null) => {
        const statusClassName = `status${loanStatusToColor(loanStatus, ' status-', applicantFormProgress)}`;
        const itemStyle: React.CSSProperties = { width: 'fit-content' };
        const substatus = substatuses !== undefined
            ? substatusesIds.filter(id => substatuses.some(s => s.id === id)).map(id => {
                const substatus = substatuses.find(s => s.id === id)!;
                return <div className={getSubstatusClassNames(loanStatus, substatus.loanStatuses)} style={itemStyle}>{substatus.name}</div>;
            })
            : null;

        return (
            <div className="statuses" style={{ display: 'flex' }}>
                <span className={statusClassName}>
                    <Translate id={getLoanStatusTranslateKey(loanStatus)} />
                </span>
                {substatus}
            </div>
        );
    };

    const getSubstatusClassNames = (status: LoanStatus, substatusLoanStatuses: LoanStatus[]): string => (
        substatusLoanStatuses.some(s => s === status)
            ? `status${loanStatusToColor(status, ' status-')}`
            : 'status status-gray'
    );

    const renderApplicationFormProgress = (value: number | null) => {
        if (value !== null) {
            return (<span style={getProgressCompletionStyling(value)}>{Math.min(value, 100)}%</span>);
        }

        return null;
    };

    const renderApplicationFormProgressCell = (value: number | null) =>
        (props.tableState.loanStatus === LoanStatus.Incomplete ? (<td>{renderApplicationFormProgress(value)}</td>) : null);

    const renderPaymentDateCell = (value?: Date | null) =>
        (props.tableState.loanStatus === LoanStatus.Paid ? (<td><DateTimeFormatter date={value}/></td>) : null);
    
    const renderFollowUpCell = (applicant: Applicant) => (
        <td className="follow-up-cell" onClick={e => e.preventDefault()}>
            <FollowUpCell
                followUpDate={editApplicantFollowUp?.applicationId === applicant.applicationId ? editApplicantFollowUp.followUpDate : applicant.followUpDate}
                hasReminder={applicant.followUpReminder}
                editMode={editApplicantFollowUp?.applicationId === applicant.applicationId}
                onChange={followUpDate => onEditApplicantFollowUpChange?.({...editApplicantFollowUp!, followUpDate})}
                onFocus={() => {
                    if (!editApplicantFollowUp) {
                        onEditApplicantFollowUpChange?.({...applicant});
                    }
                }}
                onBlur={() => {
                    if (moment(applicant.followUpDate).toISOString() !== moment(editApplicantFollowUp?.followUpDate).toISOString()) {
                        props.onApplicantFollowUpChange?.(applicant, editApplicantFollowUp?.followUpDate);
                    }
                    onEditApplicantFollowUpChange?.(undefined);
                }}
            />
        </td>
    )

    const showContextMenu = (e: MouseEvent, applicant: Applicant) => {
        if (e.button === 2 && contextMenuAnchor?.getAttribute('temp')) { // right click
            contextMenuAnchor?.remove();
        }
        let anchor = e.currentTarget;
        if (anchor.id !== `${applicant.id}-${applicant.applicationId}`) {
            anchor = document.createElement('div');
            document.body.insertAdjacentElement('afterend', anchor);
            (anchor as HTMLDivElement).style.position = 'absolute';
            (anchor as HTMLDivElement).style.top = `${e.pageY}px`;
            (anchor as HTMLDivElement).style.left = `${e.pageX}px`;
            anchor.setAttribute('id', `${applicant.id}-${applicant.applicationId}`);
            anchor.setAttribute('temp', 'true');
        }
        e.preventDefault();
        setContextMenuAnchor(anchor);
    };

    const hideContextMenu = (e?: Event) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (contextMenuAnchor?.getAttribute('temp')) {
            contextMenuAnchor?.remove();
        }
        setContextMenuAnchor(null);
    }

    const renderActionsContextMenu = (applicant: Applicant) => (
        <ApplicantActionsMenu
            {...applicant}
            onSendSmsClick={() => props.onSendSmsApplicantChange?.(applicant)}
            onSendEmailClick={() => props.onSendEmailApplicantChange?.(applicant)}
            onSendSmsAndEmailClick={() => props.onSendSmsAndEmailApplicantChange?.(applicant)}
            onNotificationsChange={disabledNotifications => props.onApplicantNotificationsChange?.(applicant, disabledNotifications)}
            onCreateNewApplication={() => props.onCreateNewApplication?.(applicant)}
            onCommentSave={comment => {
                hideContextMenu();
                props.onApplicantCommentSave?.(applicant, comment)
            }}
        />
    )

    const renderActionsMenu = (applicant: Applicant) =>  (
        <FontAwesomeIcon
            id={`${applicant.id}-${applicant.applicationId}`}
            icon={faEllipsisVertical} 
            style={{ padding: '0 8px', margin: '0 16px 0 auto'}} 
            onClick={e => showContextMenu(e, applicant)} 
        />
    ) 

    const renderApplicantRow = (applicant: Applicant, props: ApplicantsTableRowsProps & UserProps & SubstatusesProps) => {
        const clickHandler = (e) => {
            if (!e.defaultPrevented && !editApplicantFollowUp && !contextMenuAnchor) {
                props.onClick(applicant.id, applicant.applicationId, false)
            }
        };
        const mouseDownHandler: MouseEventHandler<HTMLTableRowElement> = (e) => {
            if (e.button === 1) {
                props.onClick(applicant.id, applicant.applicationId, true);
                e.preventDefault();
            }
        };
        const numericStyle = {textAlign: 'right', paddingRight: '55px', whiteSpace: 'nowrap'} as React.CSSProperties;
        const propertyIcon = <FontAwesomeIcon  style={{marginLeft: '5px', color: '#707070', opacity: applicant.hasProperty ? 1 : 0}} icon={faHome}/>;

        return (
            <tr
                onClick={clickHandler}
                onMouseDown={mouseDownHandler}
                onContextMenu={e => showContextMenu(e, applicant)}
                key={`applicant-${applicant.id}/${applicant.applicationId}`}
                className={classNames('cursor-pointer', {'applicant-has-debt-collections': (applicant.numberOfDebtCollections || 0) > 0})}
            >
                <td className="uppercase" style={{display: 'flex', alignItems: 'center'}}>
                    {!isLimitedCaseWorker(props) && renderTeamName(applicant, props.teams)}
                    {renderNameWithOverlayTrigger(props, applicant)}
                    {renderActionsMenu(applicant)}
                </td>
                {renderApplicationFormProgressCell(applicant.applicationFormProgress)}
                <td><DateTimeFormatter date={applicant.submittedDate}/></td>
                {renderFollowUpCell(applicant)}
                {renderPaymentDateCell(applicant.paymentDate)}
                <td style={numericStyle}><NumberFormatter value={applicant.loanAmount}/>{propertyIcon}</td>
                <td style={numericStyle}><NumberFormatter value={applicant.grantedAmount}/></td>
                <td><ProductCell product={applicant.product}/></td>
                <td>{applicant.advertisementSite}</td>
                <td className="status-cell">{renderLoanStatus(applicant.loanStatus, applicant.substatusesIds, props.substatuses, applicant.applicationFormProgress)}</td>
                {contextMenuAnchor &&
                    <Overlay
                        show={contextMenuAnchor?.id === `${applicant.id}-${applicant.applicationId}`}
                        target={contextMenuAnchor}
                        placement="right"
                        rootClose
                        onHide={hideContextMenu}
                    >
                        {(props) => (
                            <Popover {...props} style={{...props.style, maxWidth: 'fit-content', border: 'none'}}>
                                <Popover.Body style={{padding: 0}} onClick={(e: unknown) => hideContextMenu(e as Event)}>
                                    {renderActionsContextMenu(applicant)}
                                </Popover.Body>
                            </Popover>
                        )}
                    </Overlay>
                }
            </tr>
        );
    };

    return (
        <TableRows 
            rows={props.applicants} 
            renderRow={applicant => renderApplicantRow(applicant, props)}
            isLoading={props.isLoading}
        />
    );
};

const renderNameWithOverlayTrigger = (props: ApplicantsTableRowsProps, applicant: Applicant) => (
    <ApplicantTooltip
        {...applicant}
        personId={applicant.id}
        onSendSmsClick={() => props.onSendSmsApplicantChange?.(applicant)}
        onSendEmailClick={() => props.onSendEmailApplicantChange?.(applicant)}
        onSendSmsAndEmailClick={() => props.onSendSmsAndEmailApplicantChange?.(applicant)}
        onNotificationsChange={disabledNotifications => props.onApplicantNotificationsChange?.(applicant, disabledNotifications)}
        onCreateNewApplication={() => props.onCreateNewApplication?.(applicant)}
        onCommentSave={comment => props.onApplicantCommentSave?.(applicant, comment)}
    >
        <span>{applicant.firstName} {applicant.lastName}{renderCoApplicantIcon(applicant)}</span>
    </ApplicantTooltip>
);

const renderCoApplicantIcon = (applicant: Applicant) => applicant.hasCoApplicant ?
    <FontAwesomeIcon style={{marginLeft: '5px', color: '#707070'}} icon={faUserFriends}/> : null;

const renderTeamName = (applicant: Applicant, teams: Team[]) => {
    if (!teams.length) {
        return null;
    }
    const team = teams.find(team => team.id === applicant.teamId);
    const teamName = team ? team.name : undefined;
    const teamNameCountry = teamName ? teamName.toLowerCase().includes('polska') ? 'PL' : 'NO' : null;
    const teamNameSrc = teamNameCountry ? `http://purecatamphetamine.github.io/country-flag-icons/3x2/${teamNameCountry}.svg` : null;
    if (!teamNameSrc) {
        const unassignedTeamStyle = {
            height: '12px',
            marginRight: '12px',
            minWidth: '18px',
            maxWidth: '18px',
            background: 'linear-gradient(0deg, darkgray, lightgray)'
        };
        return (
            <Tooltip content={<Translate id={'UNASSIGNED'}/>}>
                <div style={unassignedTeamStyle}>
                    <FontAwesomeIcon style={{ height: '9px', position: 'relative', top: '-6px', left: '2px' }} icon={faQuestionCircle} />
                </div>
            </Tooltip>
        );
    }
    const teamNameStyle = { display: 'inline-block', marginRight: '12px', height: '12px' } as React.CSSProperties;
    return (
        <Tooltip content={teamName}>
            <img
                style={teamNameStyle}
                alt={teamName}
                src={teamNameSrc} />
        </Tooltip>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.substatusesReducer,
    ...state.applicantsViewDataReducer,
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...SubstatusesActionsCreator
}, dispatch);

export default connect<ApplicantsTableStateProps & UserProps & SubstatusesProps, typeof SubstatusesActionsCreator, {}, any>(mapStateToProps, mapActionCreatorsToProps)(ApplicantsTableRows);
