import React, {FunctionComponent, ReactElement} from "react";
import "./credit-information-modal.css";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {CreditInformation, CreditInformationPaymentItem} from "../../models/CreditInformation";
import {formatDate} from "../../../../common/helpers/dateFormatter";
import NumberFormatter from "../../../../common/components/NumberFormatter";

interface CreditInformationDetailsProps {
    details?: CreditInformation;
}

const CreditInformationDetails: FunctionComponent<CreditInformationDetailsProps & LocalizeContextProps> = (props) => {
    if (!props.details) {
        return <Translate id="NO_DATA"/>;
    }

    return (
        <div className="credit-information-details">
            <CreditInformationPersonalInfo details={props.details!}/>
            {props.details.incomes?.length ?
                <CreditInformationDetailsTable
                    data={props.details.incomes}
                    headers={incomesTableHeaders}
                    title={<Translate id="CREDIT_INFORMATION_MODAL.INCOMES"/>}
                    renderRow={income => (
                        <>
                            <td>{income.incomeYear}</td>
                            <td><NumberFormatter value={income.grossIncome}/></td>
                            <td><NumberFormatter value={income.netIncome}/></td>
                            <td><NumberFormatter value={income.sumTax}/></td>
                        </>
                    )}
                /> : null
            }
            <CreditInformationPaymentsTable 
                data={props.details.paymentRemarks}                                            
                title={<Translate id="CREDIT_INFORMATION_MODAL.PAYMENT_REMARKS"/>}
            />
            <CreditInformationPaymentsTable 
                data={props.details.voluntaryPledges}
                title={<Translate id="CREDIT_INFORMATION_MODAL.VOLUNTARY_PLEDGES"/>}
            />
            <CreditInformationDetailsTable
                data={props.details.companyEngagements}
                headers={companyEngagementsTableHeaders}
                title={<Translate id="CREDIT_INFORMATION_MODAL.ROLES"/>}
                renderRow={companyEngagement => (
                    <>
                        <td>{companyEngagement.function}</td>
                        <td>{formatDate(companyEngagement.appointmentDate) || '-'}</td>
                        <td>{companyEngagement.companyName}</td>
                        <td>{companyEngagement.companyNumber}</td>
                        <td>{formatDate(companyEngagement.companyRegistrationDate) || '-'}</td>
                        <td>{companyEngagement.companyStatusText}</td>
                        <td><NumberFormatter value={companyEngagement.creditLimit}/></td>
                        <td>{companyEngagement.creditRating}</td>
                    </>
                )}
            />
        </div>
    );
}

const CreditInformationPersonalInfo: FunctionComponent<Required<CreditInformationDetailsProps>> = ({details}) => (
    <div className="personal-info">
        <div>                    
            <span className="uppercase title">
                {details.firstName} {details.middleName} {details.lastName}
            </span>
            <span>{details.address}, {details.postCode} {details.city}</span>
            <span className="secondary">
                {formatDate(details.birthDate)}, {details.gender}
            </span>
            <span className="secondary">{details.statusText}</span>
        </div>
        <div>
            <span className="uppercase secondary"><Translate id="CREDIT_INFORMATION_MODAL.CREDIT_SCORE"/></span>
            <span className="credit-score">{details.creditScore}</span>
        </div>
    </div>
)

interface CreditInformationDetailsTableProps<T> {
    data: T[];
    headers: (string | ReactElement)[];
    title: string | ReactElement;
    renderRow: (row: T) => ReactElement;
}

export function CreditInformationDetailsTable<T>(props: CreditInformationDetailsTableProps<T>) {
    return (
        <div>
            <span className="title">{props.title}</span>
            {props.data.length ?
                <table>
                    <thead>
                        <tr>
                            {props.headers.map((header, id) => <th key={id}>{header}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {props.data.map((row, id) => <tr key={id}>{props.renderRow(row)}</tr>)}
                    </tbody>
                </table>
                : <Translate id="NO_DATA"/>
            }
        </div>
    );
}

type CreditInformationPaymentsTableProps = Omit<CreditInformationDetailsTableProps<CreditInformationPaymentItem>, 'headers' | 'renderRow'>;
const CreditInformationPaymentsTable: FunctionComponent<CreditInformationPaymentsTableProps> = (props) => (
    <CreditInformationDetailsTable
        {...props}
        headers={paymentsTableHeaders}
        renderRow={paymentItem => (
            <>
                <td className="span-2">{paymentItem.type || '-'}</td>
                <td>{paymentItem.source || '-'}</td>
                <td>{formatDate(paymentItem.date) || '-'}</td>
                <td>{paymentItem.refNr || '-'}</td>
                <td><NumberFormatter value={paymentItem.amount}/></td>
                <td>{paymentItem.creditor || '-'}</td>
                <td>{paymentItem.status || '-'}</td>
            </>
        )}
    />
)

export const incomesTableHeaders = [
    <Translate id="CREDIT_INFORMATION_MODAL.YEAR"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.GROSS_INCOME"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.NET_INCOME"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.TAX"/>
];
export const paymentsTableHeaders = [
    <Translate id="CREDIT_INFORMATION_MODAL.TYPE"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.SOURCE"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.DATE"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.REF_NR"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.AMOUNT"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.CREDITOR"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.STATUS"/>
];
export const companyEngagementsTableHeaders = [
    <Translate id="CREDIT_INFORMATION_MODAL.FUNCTION"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.FROM"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.COMPANY_NAME"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.COMPANY_NUMBER"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.COMPANY_REGISTRATION_DATE"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.COMPANY_STATUS"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.CREDIT_LIMIT"/>,
    <Translate id="CREDIT_INFORMATION_MODAL.CREDIT_RATING"/>
];

export default withLocalize(CreditInformationDetails);