import {useTranslate_i18next} from "../useTranslate_i18next";
import {FunctionComponent} from "react";
import type {$Dictionary} from "i18next/typescript/helpers";

interface Translate_i18nextProps {
    id: string;
    data?: $Dictionary;
}
const Translate_i18next: FunctionComponent<Translate_i18nextProps> = (props) => {
    const translate_i18next = useTranslate_i18next();
    return (
        <>
            {translate_i18next(props.id, props.data)}
        </>
    )
}
export default Translate_i18next;