import {
    CreditInformation,
    CreditInformationCompanyEngagement,
    CreditInformationIncome, CreditInformationPaymentItem
} from "../../models/CreditInformation";

export interface Compare<T> {
    latest?: T;
    previous?: T;
}
type CreditInformationCompare<T> = T extends (infer U)[] ? Compare<U>[] : Compare<T>;

type CreditInformationRecorded = Partial<Record<keyof CreditInformation, CreditInformationCompare<CreditInformation[keyof CreditInformation]>>>;

export class CreditInformationComparison implements CreditInformationRecorded {
    applicantId?: Compare<number>;
    timestamp?: Compare<string>;
    firstName?: Compare<string>;
    middleName?: Compare<string>;
    lastName?: Compare<string>;
    birthDate?: Compare<string>;
    gender?: Compare<string>;
    statusText?: Compare<string>;
    address?: Compare<string>;
    city?: Compare<string>;
    postCode?: Compare<string>;
    creditScore?: Compare<number>;
    companyEngagements?: Compare<CreditInformationCompanyEngagement>[] = [];
    incomes?: Compare<CreditInformationIncome>[] = [];
    paymentRemarks?: Compare<CreditInformationPaymentItem>[] = [];
    voluntaryPledges?: Compare<CreditInformationPaymentItem>[] = [];
}

type CreditInformationRow = CreditInformationCompanyEngagement | CreditInformationIncome | CreditInformationPaymentItem;
export const compare = (latest: CreditInformation, previous: CreditInformation) => {
    if (JSON.stringify(latest) === JSON.stringify(previous)) {
        return undefined;
    }
    const comparison = new CreditInformationComparison();
    Object.keys(comparison).forEach(key => {
        // show whole latest model
        if (Array.isArray(comparison[key])) {
            // add all new rows and rows that changed 
            latest[key].forEach((latestRow: CreditInformationRow) => {
                const previousRow = findEquivalentRow(latestRow, previous[key]);
                comparison[key].push({
                    latest: latestRow,
                    previous: previousRow
                });
            });
            // add all removed rows
            previous[key].forEach((previousRow: CreditInformationRow) => {
                const latestRow = findEquivalentRow(previousRow, latest[key]);
                if (!latestRow) {
                    comparison[key].push({
                        latest: latestRow,
                        previous: previousRow
                    });
                }
            });
        }
        else {
            comparison[key] = {
                latest: latest[key],
                previous: previous[key]
            }
        }
    });
    return comparison;
}

const findEquivalentRow = (target: CreditInformationRow, array: CreditInformationRow[]) => {
    return array.find(row =>
        (row.hasOwnProperty('companyNumber') && (row as CreditInformationCompanyEngagement).companyNumber === (target as CreditInformationCompanyEngagement).companyNumber) ||
        (row.hasOwnProperty('incomeYear') && (row as CreditInformationIncome).incomeYear === (target as CreditInformationIncome).incomeYear) ||
        (row.hasOwnProperty('refNr') && (row as CreditInformationPaymentItem).refNr === (target as CreditInformationPaymentItem).refNr)
    );
}