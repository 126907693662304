export interface TableState<T = unknown> {
    orderBy?: number;
    orderDirection: OrderDirection;
    orderings?: Ordering<T>[];
    pageNumber: number;
    pageSize: number;
}

export interface Ordering<T> {
    column: keyof T;
    direction: OrderDirection;
}

export enum OrderDirection {
    Ascending,
    Descending
}