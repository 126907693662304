import React from 'react';
import {FormControl} from 'react-bootstrap';
import InputField, {InputFieldProps} from './InputField';
import {useValidation, ValidationProps} from '../../helpers/useValidation';
import {Translate, useTranslate} from "../../helpers/useTranslate";

interface TextInputFieldProps extends InputFieldProps, ValidationProps {
    name: string;
    value: string | null | undefined;
    maxLength?: number;
    disabled?: boolean;
    rows?: number;
    onValueChanged?: (value: string, name: string) => void;
    type?: string;
}
function TextInputField<WrapperProps>(props: TextInputFieldProps)  {
    const [errorCode, internalError, ref, onTouched] = useValidation(props);
    const translate = useTranslate();

    return (
        <InputField<WrapperProps>
            inputRef={ref}
            className={props.className}
            afterContent={props.afterContent}
            clearable={!!props.value}    
            descriptionKey={props.descriptionKey} 
            description={props.description} 
            editMode={props.editMode}
            error={internalError}
            errorCode={errorCode}
            wrapper={props.wrapper}
            wrapperProps={props.wrapperProps}
            onClear={() => {
                onTouched();
                props.onValueChanged && props.onValueChanged('', props.name)
            }}
        >
            {renderValue(props, ref, onTouched, translate)}
        </InputField>
    );
}

function renderValue(props: TextInputFieldProps, ref: React.RefObject<HTMLInputElement>, onTouched: () => void, translate: Translate) {
    if (!props.editMode || !props.onValueChanged) {
        return <span>{props.value}</span>;
    }

    const onValueChanged = (e: any) => {
        onTouched();
        if (props.onValueChanged) {
            props.onValueChanged(e.target.value, props.name);
        }
    };

    return props.rows && props.rows > 1 ? 
        (
            <FormControl
                as="textarea"
                name={props.name}
                type={props.type || 'text'}
                value={props.value || ''}
                onChange={onValueChanged}
                maxLength={props.maxLength}
                disabled={props.disabled}
                rows={props.rows}
                placeholder={props.placeholderKey ? translate(props.placeholderKey) : undefined}
                ref={ref as any}
            />
        ) :
        (
            <FormControl
                as="input"
                name={props.name}
                type={props.type || 'text'}
                value={props.value || ''}
                onChange={onValueChanged}
                maxLength={props.maxLength}
                disabled={props.disabled}
                placeholder={props.placeholderKey ? translate(props.placeholderKey) : undefined}
                ref={ref as any}
            />
        )
}

export default TextInputField;
