export enum MortgagePurpose {
    REFINANCE = 'Refinance',
    REFINANCE_AND_EXTRA_MONEY = 'RefinanceAndExtraMoney',
    EXTRA_MONEY = 'ExtraMoney',
    BUY_NEW_PROPERTY = 'BuyNewProperty',
    BETTER_LOAN_TERMS = 'BetterLoanTerms'
}

export enum ConsumerLoanPurpose {
    REFINANCING = 'Refinancing',
    VEHICLE = 'Vehicle',
    HOUSE_AND_HOME = 'HouseAndHome',
    LEISURE = 'Leisure',
    HEALTH = 'Health',
    BILLS = 'Bills',
    OTHER = 'Other'
}