import React, {FunctionComponent, useEffect, useState} from "react";
import "./Expenses.css";
import {ConsumerLoan} from "../../../../models/ConsumerLoan";
import {Translate} from "react-localize-redux";
import NumberInputField, {NumberInputFieldKind} from "../../../../../../common/components/input-fields/NumberInputField";
import {AdditionalInformation, AdditionalInformationType} from "../../../../../../common/models/AdditionalInformation";


interface ExpensesProps {
    application: ConsumerLoan;
    applicantAdditionalInformation: AdditionalInformation[];
    visibleFields?: Omit<AdditionalInformation, 'value' | 'id'>[];
    onChange?: (total: number) => void;
    expenseChange?: (expense: AdditionalInformation) => void;
    readonly?: boolean;
}
const Expenses: FunctionComponent<ExpensesProps> = (props) => {

    const createEmptyExpenseField = (name: string): AdditionalInformation => ({
        type: AdditionalInformationType.Expense,
        name,
        value: null
    });

    const createAdditionalExpenses = (): AdditionalInformation[] => {
        const visibleExpenses = (props.visibleFields || []).filter(f => f.type === AdditionalInformationType.Expense).map(f => f.name);
        const applicantExpenses = props.applicantAdditionalInformation.filter(f => f.type === AdditionalInformationType.Expense).map(f => f.name);
        const additionalExpensesNames = Array.from(new Set([...visibleExpenses, ...applicantExpenses]));
        return additionalExpensesNames.map(n => props.applicantAdditionalInformation.find(ai => ai.name === n && ai.type === AdditionalInformationType.Expense) || createEmptyExpenseField(n));
    }
    const [additionalExpenses, setAdditionalExpenses] = useState<AdditionalInformation[]>(createAdditionalExpenses());
    const [expenses, setExpenses] = useState<number>(0);

    useEffect(() => {
        setExpenses(calculateExpenses());
    }, [additionalExpenses]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setAdditionalExpenses(createAdditionalExpenses());
    }, [props.applicantAdditionalInformation]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props.onChange && props.onChange(expenses);
    }, [expenses]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const calculateExpenses = () => {
        return additionalExpenses.reduce((total: number, field) => total + (field.value ? parseFloat(field.value) : 0), 0);
    }

    const updateAdditionalExpense = (name: string, value: number | null) => {
        if (additionalExpenses.some(income => income.name === name)) {
            additionalExpenses.find(income => income.name === name)!.value = (value || 0).toString(10);
            setAdditionalExpenses([...additionalExpenses]);
        }
    }

    return (
        <>
            <span className="title">
                <Translate id="EXPENSES" />
            </span>
            <div className="expenses-fields">
                {additionalExpenses.map(expense =>
                    <div key={expense.name}>
                        <NumberInputField
                            name={expense.name}
                            editMode={!props.readonly}
                            value={expense.value ? parseFloat(expense.value) : null}
                            description={expense.name}
                            kind={NumberInputFieldKind.Money}
                            onValueChanged={(value) => updateAdditionalExpense(expense.name, value)}
                            onBlur={(value, dirty) => dirty && props.expenseChange && props.expenseChange(expense)}
                        />
                    </div>
                )}
            </div>            
            <div className="total">
                <NumberInputField
                    name="totalExpenses"
                    value={expenses}
                    descriptionKey="TOTAL"
                    kind={NumberInputFieldKind.Money}
                    readonlyPrecision={0}
                />
            </div>
        </>
    );
}
export default Expenses;