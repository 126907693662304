import {faPaperclip, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, MouseEventHandler, useEffect, useMemo, useState} from 'react';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import Checkbox from '../../../../common/components/Checkbox';
import {CommentNotification} from '../../models/CommentNotification';
import {connect} from 'react-redux';
import {SubstatusesProps} from '../../../../common/interfaces/SubstatusesProps';
import {ApplicantDataStateProps} from '../../models/ApplicantDataProps';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import DropdownInputField from "../../../../common/components/input-fields/DropdownInputField";
import {getCommentTemplates} from "../../../../common/helpers/settingsHelpers";
import {SettingsProps} from "../../../../common/interfaces/SettingsProps";
import {CommentTemplate, TemplatePostActionType} from '../../../../common/models/CommentTemplate';
import {UserProps} from "../../../../common/interfaces/UserProps";
import TemplateAction from './TemplateAction/TemplateAction';
import {MessageType} from "../../../../common/models/MessageType";

interface LogsAndCommentsFormProps extends LocalizeContextProps, SubstatusesProps, ApplicantDataStateProps, SettingsProps, UserProps {
    onSaveComment: (commentText: string, notification: CommentNotification, file: any[]) => void;
    onFormInputChanged: (isEmpty: boolean) => void;
    onSubstatusesChanged?: (ids: number[]) => void;
    onCommunicationTemplateChanged?: (name: string, type?: MessageType) => void;
    onDenyApplication?: () => void;
}

const LogsAndCommentsForm: FunctionComponent<LogsAndCommentsFormProps> = (props) => {
    const [fileInputReference, setFileInputReference] = useState<any>(undefined);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [commentText, setCommentText] = useState('');
    const [notification, setNotification] = useState(CommentNotification.None);
    const [commentTemplate, setCommentTemplate] = useState<CommentTemplate>();
    const commentTemplates = useMemo(() => 
        ((getCommentTemplates(props) || []) as CommentTemplate[]).filter(template => {
            const teamTemplate = template.teamId === props.userData.user?.teamId;
            const userTemplate = template.userIds?.some(id => id === props.userData.user?.id);
            return teamTemplate || userTemplate;
    }), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (commentTemplate) {
            onCommentTextChangedHandler(commentTemplate.content);
            setNotification(commentTemplate.notification || CommentNotification.None)
        }
    }, [commentTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

    const onNotificationUrgentClicked = () => setNotification(notification === CommentNotification.Urgent ? CommentNotification.None : CommentNotification.Urgent);
    const onNotificationCompleteClicked = () => setNotification(notification === CommentNotification.Complete ? CommentNotification.None : CommentNotification.Complete);
    const onNotificationCaseWorkerClicked = () => setNotification(notification === CommentNotification.CaseWorker ? CommentNotification.None : CommentNotification.CaseWorker);

    const onSaveCommentClickHandler = () => {
        props.onSaveComment(commentText, notification, selectedFiles);
        setCommentText('');
        setSelectedFiles([]);
        if (commentTemplate) {
            commentTemplate.actions?.forEach(action => {
                switch (action.type) {
                    case TemplatePostActionType.ADD_SUBSTATUS:
                        const postAddIds = Array.from<number>(new Set([...(props.currentApplication?.substatusIds || []), ...action.data]));
                        props.onSubstatusesChanged?.(postAddIds);
                        break;
                    case TemplatePostActionType.REMOVE_SUBSTATUS:
                        const postRemoveIds = props.currentApplication?.substatusIds.filter(id => !action.data.includes(id));
                        postRemoveIds && props.onSubstatusesChanged?.(postRemoveIds);
                        break;
                    case TemplatePostActionType.SEND_EMAIL:
                        props.onCommunicationTemplateChanged?.(action.data, MessageType.Email);
                        break;
                    case TemplatePostActionType.SEND_SMS:
                        props.onCommunicationTemplateChanged?.(action.data, MessageType.SMS);
                        break;
                    case TemplatePostActionType.SEND_EMAIL_AND_SMS:
                        props.onCommunicationTemplateChanged?.(action.data);
                        break;
                    case TemplatePostActionType.DENY_APPLICATION:
                        props.onDenyApplication?.();
                        break;
                }
            })
        }
        setCommentTemplate(undefined);
    };

    const onCommentTextChangedHandler = (value: string) => {
        props.onFormInputChanged(value === '');
        setCommentText(value);
    };

    const renderSelectedFileName = (file: any) => {
        const removeFile = () => setSelectedFiles(selectedFiles.filter((f: any) => f.name !== file.name));

        return (
            <span key={`file-${file.name}`} className="deletable-file" style={{ padding: '2px 1px' }}>
                <FontAwesomeIcon icon={faPaperclip} />
                {file.name}
                <span onClick={removeFile}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </span>
        );
    }

    const renderSelectedFiles = () => selectedFiles.length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            {selectedFiles.map(renderSelectedFileName)}
        </div>
    ) : null;

    const onAddFileButtonClickedHandler = () => {
        if (fileInputReference !== undefined) {
            fileInputReference.click();
        }
    };

    const onDrop = (files: any[]) => setSelectedFiles(selectedFiles.concat(files));

    const onFileChangedHandler = (event: any) => {
        const files: any[] = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < event.target.files.length; i++) {
            files.push(event.target.files[i]); // event.target.files is actually a FileList, not an array
        }
        setSelectedFiles(selectedFiles.concat(files));
    };
    const commentTextPlaceholder = props.translate('WRITE_COMMENT').toString();

    const isAnySubstatusWithUserId: () => boolean = () => {
        const applicationSubstatuses = (props.currentApplication && props.currentApplication.substatusIds.length > 0) ? props.currentApplication.substatusIds : [];
        let result = false;
        applicationSubstatuses.forEach((as) => {
            const subStatus = props.substatuses && props.substatuses.find((ss) => ss.id === as);
            if (subStatus !== undefined && subStatus.userId !== null) {
                result = result || true;
            }
        });

        return result;
    };

    const renderNotificationCheckboxes = () => (isAnySubstatusWithUserId() ? (
        <div className="notification-selection">
            <div>
                <Translate id="COMMENT_NOTIFICATION" />
            </div>
            <div>
                <Checkbox
                    checked={notification === CommentNotification.Complete}
                    onClick={onNotificationCompleteClicked}
                >
                    <Translate id="COMPLETE" />
                </Checkbox>
                <Checkbox
                    checked={notification === CommentNotification.Urgent}
                    onClick={onNotificationUrgentClicked}
                >
                    <Translate id="URGENT" />
                </Checkbox>
                <Checkbox
                    checked={notification === CommentNotification.CaseWorker}
                    onClick={onNotificationCaseWorkerClicked}
                >
                    <Translate id="CASE_WORKER" />
                </Checkbox>
            </div>
        </div>
    ) : null);

    const renderDropzoneContents = ({ getRootProps, getInputProps, isDragActive }) => {
        const stopPropagation: MouseEventHandler<HTMLTextAreaElement> = (e) => e.stopPropagation();
        return (
            <div {...getRootProps()}>
                <div className="input-group">
                    <textarea
                        style={{ display: isDragActive ? 'none' : 'block' }}
                        onClick={stopPropagation}
                        className="form-control customized"
                        placeholder={commentTextPlaceholder}
                        value={commentText}
                        onChange={e => onCommentTextChangedHandler(e.target.value)}
                    />
                    <div className={classNames('drag-n-drop-zone', { 'drag-over': isDragActive })}>
                        <Translate id="DRAG_AND_DROP_AREA" />
                    </div>
                </div>
                <input {...getInputProps()} />
            </div>
        );
    };
    
    const renderCommentTemplates = () => {
        if (
            !commentTemplates.length ||
            !props.onCommunicationTemplateChanged || 
            !props.onSubstatusesChanged || 
            !props.onDenyApplication
        ) {
            return null;
        }
        return (
            <DropdownInputField
                editMode={true}
                name="template"
                searchable={commentTemplates.length > 10}
                filterPlaceholder={props.translate('TYPE_TEMPLATE_NAME').toString()}
                renderUndefinedItems
                onValueChanged={(_, __, template) => setCommentTemplate(template)}
                value={commentTemplate?.name}
                displayValue={template => template?.name || props.translate('SELECT_TEMPLATE').toString()}
                keyValue={template => template.name}
                items={commentTemplates}
            />
        )
    }
    
    const renderTemplatePostActions = () => {
        if (!commentTemplate || !commentTemplate.actions || !commentTemplate.actions.length) {
            return null;
        }
        return (
            <div className="template-post-actions">
                <span style={{fontWeight: 'bold'}}><Translate id="SAVING_THIS_COMMENT_WILL"/></span>
                {commentTemplate.actions.map((action, i) => <TemplateAction key={i} action={action}/>)}
            </div>
        )
    }

    return (
        <React.Fragment>
            {renderCommentTemplates()}
            {renderTemplatePostActions()}
            <Dropzone onDrop={onDrop}>
                {renderDropzoneContents}
            </Dropzone>
            {renderSelectedFiles()}
            {renderNotificationCheckboxes()}
            <div className="logs-and-comments-buttons">
                <button className="btn lc-buttons add-file-btn" onClick={onAddFileButtonClickedHandler}>
                    <Translate id="ADD_FILES" />
                    <input
                        style={{ display: 'none' }}
                        multiple={true}
                        type="file"
                        name="file"
                        ref={setFileInputReference}
                        onChange={onFileChangedHandler}
                    />
                </button>
                <button
                    className="btn lc-buttons save-comment-btn"
                    onClick={onSaveCommentClickHandler}
                    disabled={commentText === '' && selectedFiles.length === 0}
                >
                    <Translate id="SAVE_COMMENT" />
                </button>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    ...state.substatusesReducer,
    ...state.applicantDataReducer,
    ...state.settingsActionsReducer,
    ...state.userActionsReducer
});

export default connect<SubstatusesProps & ApplicantDataStateProps & SettingsProps & UserProps, {}, {}, any>(mapStateToProps)(withLocalize(LogsAndCommentsForm));
