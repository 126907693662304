import React, {FunctionComponent, useEffect, useState} from "react";
import StringCollectionInputField from "../../../../common/components/input-fields/StringCollectionInputField";
import {getTranslationKey} from "../../../../common/helpers/getTranslationKey";
import DateTimeInputField, {
    DateTimeInputFieldKind
} from "../../../../common/components/input-fields/DateTimeInputField";
import {isValueSet} from "../../../../common/helpers/isValueSet";
import {AggregatedBankData, BankData, BankDataEntry, BankDataField, ImportBankDataScenario} from "./model";
import {isDateType, isMultiType} from "./helpers";


interface AggregateBankDataProps {
    scenario: ImportBankDataScenario;
    onDataAggregated: (aggregated: AggregatedBankData[]) => void;
}
const AggregateBankData: FunctionComponent<AggregateBankDataProps> = (props) => {
    const [bankData, setBankData] = useState<BankData>({});
    const [errors, setErrors] = useState<string[]>([]);

    useEffect(() => {
        const aggregated = aggregateBankData();
        props.onDataAggregated(aggregated);
    }, [bankData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const data: BankData = {};
        props.scenario.entries.forEach(entry => {
            if (isValueSet(entry.default)) {
                data[entry.field] = entry.default;
            }
        });
        setBankData(data);
    }, [props.scenario]); // eslint-disable-line react-hooks/exhaustive-deps

    const aggregateBankData = () => {
        // Find the maximum length of the arrays in the BankData object
        const dataLengths = Object.values(bankData).filter(Array.isArray).map(arr => arr.length);
        const sameDataLength = dataLengths.every((length, _, lengths) => length === lengths[0]);
        if (!sameDataLength) {
            if (!dataLengths.includes(0)) {
                setErrors(['LengthMismatch']);
            }
            return [];
        }
        const plainValuesEmpty = Object.values(bankData).filter(v => !Array.isArray(v)).some(v => !isValueSet(v));
        if (plainValuesEmpty) {
            if (!dataLengths.includes(0)) {
                setErrors(['FieldIsRequired']);
            }
            return [];
        }
        const aggregated: AggregatedBankData[] = [];

        // Iterate up to the length of the longest array
        const maxLen = Math.max(...dataLengths);
        for (let i = 0; i < maxLen; i++) {
            let shouldSkip = false;
            const obj: AggregatedBankData = {};
            for (const field in bankData) {
                if (Array.isArray(bankData[field])) {
                    if (bankData[field][i] === '') {
                        shouldSkip = true;
                        break; // Skip this iteration if any value in array is missing
                    }
                    obj[field] = bankData[field][i];
                } else {
                    obj[field] = bankData[field]; // Propagate single value to all elements
                }
            }
            if (!shouldSkip) {
                aggregated.push(obj);
            }
        }
        setErrors([]);
        return aggregated;
    }

    const onBankDataChange = (field: BankDataField, value: unknown | unknown[]) => {
        setBankData({
            ...bankData,
            [field]: value
        });
    }

    const renderBankDataEntry = (entry: BankDataEntry, id: number) => {
        const value = bankData[entry.field];
        if (isMultiType(value, entry.kind)) {
            return (
                <StringCollectionInputField
                    key={id}
                    name={entry.field}
                    description={getTranslationKey(entry.field, 'BANK_DATA_FIELD')}
                    errors={errors}
                    errorCodes={['LengthMismatch']}
                    edit
                    value={value}
                    onValueChange={value => onBankDataChange(entry.field, value.map(v => v.replaceAll(' ', '')))}
                />
            )
        }
        if (isDateType(value, entry.kind)) {
            return (
                <div key={id}>
                    <DateTimeInputField
                        name={entry.field}
                        descriptionKey={getTranslationKey(entry.field, 'BANK_DATA_FIELD')}
                        editMode
                        kind={DateTimeInputFieldKind.Date}
                        errors={errors}
                        errorCodes={['FieldIsRequired']}
                        value={value}
                        onValueChanged={value => onBankDataChange(entry.field, value)}
                    />
                </div>
            )
        }
    }
    return (
        <div className="aggregate-bank-data">
            {props.scenario.entries.map(renderBankDataEntry)}
        </div>
    )
}
export default AggregateBankData;