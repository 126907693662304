import React, {FunctionComponent} from "react";
import {connect} from "react-redux";
import {ApplicantDataStateProps} from "../../../models/ApplicantDataProps";
import {Translate} from "react-localize-redux";
import {LoanStatus} from "../../../../../common/models/LoanStatus";
import {getEnumTranslationKey} from "../../../../../common/helpers/getTranslationKey";
import {loanStatusToColor} from "../../../../../common/helpers/loanStatusToColor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";

interface ChangeStatusProps extends ApplicantDataStateProps {
    status: LoanStatus;
}
const ChangeStatus: FunctionComponent<ChangeStatusProps> = (props) => {
    if (props.status === props.currentApplication?.loanStatus) {
        return null;
    }

    return (
        <div className="change-status-template-post-action">
            <div className="description"><Translate id="CHANGE_STATUS"/></div>
            <div className="content">
                <span className={loanStatusToColor(props.currentApplication?.loanStatus, 'status status-')}>
                    <Translate id={getEnumTranslationKey(LoanStatus, props.currentApplication?.loanStatus, 'LOAN_STATUS')}/>
                </span>
                <FontAwesomeIcon icon={faArrowRight}/>
                <span className={loanStatusToColor(props.status, 'status status-')}>
                    <Translate id={getEnumTranslationKey(LoanStatus, props.status, 'LOAN_STATUS')}/>
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.applicantDataReducer
});
export default connect<ApplicantDataStateProps, {}, {}, any>(mapStateToProps)(ChangeStatus);