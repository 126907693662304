import React, {FunctionComponent, PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import "./input-field.css";
import {Translate} from 'react-localize-redux';
import {FormControl, FormGroup} from 'react-bootstrap';
import classNames from 'classnames';
import Translation from '../Translation';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";

export interface InputFieldProps<WrapperProps = any> {
    descriptionKey?: string;
    description?: ReactNode | string;
    editMode?: boolean;
    errorCodesData?: any;
    placeholderKey?: string;
    afterContent?: ReactNode | string;
    className?: string;
    wrapper?: FunctionComponent<WrapperProps>;
    wrapperProps?: WrapperProps;
}

interface InputFieldInternalProps {
    inputRef?: React.RefObject<HTMLInputElement>;
    error?: string;
    errorCode?: string;
    clearable?: boolean;
    onClear?: () => void;
}

function InputField<WrapperProps = any>(props: PropsWithChildren<InputFieldProps<WrapperProps> & InputFieldInternalProps>) {
    const Wrapper = (props.wrapper || React.Fragment) as FunctionComponent;
    const [clearIconOffsetX, setClearIconOffsetX] = useState<number>();
    const [clearIconOffsetY, setClearIconOffsetY] = useState<number>();
    
    useEffect(() => {
        if (props.inputRef?.current && props.clearable) {
            const resizeObserver = new ResizeObserver(() => {
                positionClearIcon();
            });
            resizeObserver.observe(props.inputRef.current)
        }
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        positionClearIcon();
    }, [props.editMode, props.inputRef?.current?.value, props.error, props.errorCode]);  // eslint-disable-line react-hooks/exhaustive-deps
    
    const positionClearIcon = () => {
        if (props.inputRef?.current) {
            const clearIconRadius = 12;
            let additionalOffsetX = 0;
            const element = props.inputRef?.current;
            if (!element?.offsetHeight || !element.offsetWidth) {
                return;
            }
            if (element?.type === 'number') {
                additionalOffsetX = 16;
            }
            if (element.scrollHeight > element.clientHeight) {
                additionalOffsetX += 24;
            }
            const cs = getComputedStyle(element);
            const offsetY = (element.offsetHeight - clearIconRadius) / 2;
            const offsetX = element.offsetWidth - clearIconRadius - additionalOffsetX - (parseFloat(cs.paddingRight) + parseFloat(cs.paddingLeft));
            setClearIconOffsetY(offsetY);
            setClearIconOffsetX(offsetX);
        }
    }
    
    
    if (props.editMode) {
        return(
            <React.Fragment>
                {renderDescription(props)}
                <FormGroup className={classNames('value', {'was-validated': props.errorCode || props.error}, props.className)}>
                    <Wrapper {...props.wrapperProps}>
                        {props.children}
                        {props.clearable && 
                            <FontAwesomeIcon 
                                className="clear-icon" 
                                style={clearIconOffsetX && clearIconOffsetX ? {top: `${clearIconOffsetY}px`, left: `${clearIconOffsetX}px`} : {display: 'none'}}
                                icon={faTimesCircle}
                                onClick={props.onClear}
                            />
                        }
                    </Wrapper>
                    {props.afterContent}
                    {renderValidationMessage(props.errorCode || props.error, props.errorCodesData, !!props.errorCode)}
                </FormGroup>
            </React.Fragment>
        );
    }

    return(
        <React.Fragment>
            <div className={classNames('value', props.className)}>
                <Wrapper {...props.wrapperProps}>
                    {props.children}
                </Wrapper>
                {props.afterContent}
            </div>
            {renderDescription(props)}
        </React.Fragment>
    );
};

function renderDescription(props: InputFieldProps) {
    if (props.description) {
        return <div className="description">{props.description}</div>;
    }

    if (props.descriptionKey) {
        return <div className="description"><Translate id={props.descriptionKey} /></div>;
    }

    return null;
}

function renderValidationMessage(message: string | null | undefined, data: any | undefined, translate: boolean) {
    if (message) {
        return <FormControl.Feedback type="invalid">
            {translate ? <Translation id={message} prefix="ERRORS" data={data} /> : message}            
        </FormControl.Feedback>;
    }

    return null;
}

export default InputField;
