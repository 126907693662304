import { FunctionComponent, ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import { Translate } from "react-localize-redux";

interface StandByModalProps {
    show: boolean;
    title: string | ReactNode;
    message: string | ReactNode;
    onCancel: () => void;
}

const StandByModal: FunctionComponent<StandByModalProps> = (props) => (
    <Modal show={props.show} centered={true}>
        <Modal.Header>
            <Modal.Title>
                {props.title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.message}
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={props.onCancel}>
        <Translate id="CANCEL" />
    </Button>
        </Modal.Footer>
    </Modal>
);

export default StandByModal;