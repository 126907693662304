import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const putFollowUpDate = async (personId: string | number, loanId: string | number, followUpDate?: Date | string | null): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .put(`api/applicants/${personId}/consumerloans/${loanId}/followUpDate`, { followUpDate });

    return new CommandResult(result);
};
