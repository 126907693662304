import React, {FunctionComponent, useEffect, useMemo} from "react";
import {MessageType} from "../models/MessageType";
import {connect} from "react-redux";
import {
    CommunicationTemplatesDispatchProps,
    CommunicationTemplatesState
} from "../interfaces/CommunicationTemplatesProps";
import {bindActionCreators, Dispatch} from "redux";
import {CommunicationTemplatesActionCreator} from "../actions/CommunicationTemplatesActionCreator";
import {CommunicationTemplate} from "../models/CommunicationTemplate";
import DropdownInputField from "./input-fields/DropdownInputField";
import {UserProps} from "../interfaces/UserProps";
import {formatSignature} from "../helpers/formatSignature";
import {ProductType} from "../models/ProductType";
import {isValueSet} from "../helpers/isValueSet";

interface CommunicationTemplatesSelectorState {
    type: MessageType;
    language?: string | null;
    productType?: ProductType | null;
    default?: string;
    selected?: CommunicationTemplate;
    descriptionKey?: string;
    onChange: (template?: CommunicationTemplate, signature?: string, asDefault?: boolean) => void;
}
type CommunicationTemplatesSelectorStateProps = CommunicationTemplatesSelectorState & CommunicationTemplatesState & UserProps;
type CommunicationTemplatesSelectorDispatchProps = CommunicationTemplatesDispatchProps;
type CommunicationTemplatesSelectorProps = CommunicationTemplatesSelectorStateProps & CommunicationTemplatesSelectorDispatchProps;

const CommunicationTemplatesSelector: FunctionComponent<CommunicationTemplatesSelectorProps> = (props) => {
    const communicationTemplates = useMemo(() => {
        const templates = (props.communicationTemplates[props.type] || [])
            .filter((t) => {
                let fitsProductType = true;
                let fitsLanguage = true;
                if (props.productType !== null && props.productType !== undefined) {
                    fitsProductType = t.productType === null || t.productType === props.productType;
                }
                if (props.language) {
                    fitsLanguage = t.languageCode === props.language;
                }
                return fitsProductType && fitsLanguage;
            })
            .sort ((t1, t2) => (isValueSet(t1.sequence) ? t1.sequence : Number.MAX_SAFE_INTEGER) - (isValueSet(t2.sequence) ? t2.sequence :  Number.MAX_SAFE_INTEGER));
        return ([undefined] as (CommunicationTemplate | undefined)[]).concat(templates);
        
    }, [props.communicationTemplates[props.type], props.productType, props.language]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (!props.communicationTemplates[props.type]) {
            props.loadCommunicationTemplates(props.type);
        }

        if (!props.signatureTemplates[props.type]) {
            props.loadSignatures(props.type);
        }
    }, [props.type]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        onChange(undefined, true);
    }, [props.signatureTemplates[props.type], props.language]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (props.default) {
            const defaultTemplate = communicationTemplates.find(t => t?.name === props.default);
            defaultTemplate && props.onChange(defaultTemplate);
        }
    }, [communicationTemplates]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onChange = (template?: CommunicationTemplate, asDefault?: boolean) => {
        const language = template ? template.languageCode : props.language;
        const signature = formatSignature(props.signatureTemplates[props.type], language, props.userData.user);
        props.onChange(template, signature, asDefault);
    }
    
    return (
        <DropdownInputField<CommunicationTemplate | undefined>
            descriptionKey={props.descriptionKey || 'SELECT_TEMPLATE'}
            editMode={true}
            name={`communicationTemplatesSelector${props.type}`}
            onValueChanged={(_, __, template) => onChange(template)}
            value={props.selected ? props.selected.templateContent : undefined}
            displayValue={template => template ? template.name : ''}
            keyValue={template => template ? template.templateContent : undefined}
            items={communicationTemplates}
            renderUndefinedItems={true}
        />
    )
}

const mapStateToProps = (state: any) => ({
    ...state.communicationTemplates,
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CommunicationTemplatesActionCreator
} as any, dispatch);

export default connect<CommunicationTemplatesState & UserProps, CommunicationTemplatesDispatchProps, CommunicationTemplatesSelectorState, {}>(mapStateToProps, mapActionCreatorsToProps)(CommunicationTemplatesSelector);