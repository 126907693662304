import React, {FunctionComponent, PropsWithChildren, useState} from 'react';
import './administration.css';
import {SettingsProps} from '../../common/interfaces/SettingsProps';
import {connect} from 'react-redux';
import {getMainBackgroundColorStyle} from '../../common/helpers/settingsHelpers';
import {AdministrationModule} from "./Modules/AdministrationModule";
import Dashboard from "./Dashboard/Dashboard";
import Sidebar from './Sidebar/Sidebar';
import ImportBankDataModule from "./Modules/ImportBankData/ImportBankData";

const AdministrationLayout: FunctionComponent<PropsWithChildren<SettingsProps>> = props => {
	const [selectedModule, setSelectedModule] = useState<AdministrationModule | undefined>(ImportBankDataModule);
	
	return (
		<main className="main-content" style={getMainBackgroundColorStyle(props)}>
			<div className="jumbotron text-center bg-white flex-column justify-content-center row pb-4 px-0 administration">
				{selectedModule ? 
					<div className="module-view">
						<Sidebar selectedModule={selectedModule} onModuleChange={setSelectedModule}/>
						{selectedModule.render()}
					</div> : 
					<Dashboard onModuleClick={setSelectedModule}/>
				}
			</div>
		</main>
	);
};

const mapStateToProps = (state: any) => ({ ...state.settingsActionsReducer });

export default connect<SettingsProps, {}, {}, any>(mapStateToProps)(
	AdministrationLayout
);
