import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {Clawback, ClawbackType} from '../../models/Clawback';
import {Transaction} from '../../models/Transaction';
import {Button, Col, Row} from 'react-bootstrap';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import NumberInputField, {NumberInputFieldKind} from '../../../../common/components/input-fields/NumberInputField';
import {BankStatus} from "../../../../common/models/BankStatus";
import {isAdmin} from '../../../../common/helpers/isAdmin';
import {UserProps} from "../../../../common/interfaces/UserProps";
import {connect} from "react-redux";
import DateTimeInputField, { DateTimeInputFieldKind } from "../../../../common/components/input-fields/DateTimeInputField";
import GenericDropdown from "../../../../common/components/GenericDropdown";
import mapEnumToSelectableItems from "../../../../common/helpers/mapEnumToSelectableItems";
import {getEnumTranslationKey} from "../../../../common/helpers/getTranslationKey";
import TextInputField from "../../../../common/components/input-fields/TextInputField";

interface BankRepliesFormContentProps extends LocalizeContextProps, UserProps {
    transaction: Transaction;
    editMode: boolean;
    onTransactionUpdated: (transaction: Transaction) => void;
    onFormInputChanged: (isEmpty: boolean) => void;
}

const BankRepliesFormContent: FunctionComponent<BankRepliesFormContentProps> = (props) => {
    const [clawbackEditMode, setClawbackEditMode] = useState<boolean>(false);
    const clawbackItems = useMemo(() => mapEnumToSelectableItems<ClawbackType>(ClawbackType, type => props.translate(getEnumTranslationKey(ClawbackType, type!, 'CLAWBACK_TYPE')).toString(), false), []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!props.editMode) {
            setClawbackEditMode(false);
        }
    }, [props.editMode]);
    
    const valueChangedCallback = (value: string | number | null, name: string) => {
        const newTransaction = { ...props.transaction };
        newTransaction[name] = value;
        props.onTransactionUpdated(newTransaction);
    };
    
    const clawbackChanged = (propName: keyof Clawback, value: Clawback[keyof Clawback]) => {
        props.onTransactionUpdated({
            ...props.transaction,
           clawback: {
                ...(props.transaction.clawback || {}),
               [propName]: value
           } 
        });
    }

    return (
        <div className="bank-replies-form">
            <Row>
                <Col>
                    <NumberInputField
                        name="amount"
                        value={props.transaction.amount}
                        descriptionKey="BANK_REPORT.AMOUNT"
                        kind={NumberInputFieldKind.Money}
                        step={0.01}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
                <Col>
                    <NumberInputField
                        name="nominalInterest"
                        value={props.transaction.nominalInterest}
                        descriptionKey="BANK_REPORT.NOMINAL_INTEREST"
                        kind={NumberInputFieldKind.Percent}
                        step={0.01}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
                <Col>
                    <NumberInputField
                        name="effectiveInterest"
                        value={props.transaction.effectiveInterest}
                        descriptionKey="BANK_REPORT.EFFECTIVE_INTEREST"
                        kind={NumberInputFieldKind.Percent}
                        step={0.01}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <NumberInputField
                        name="installmentFee"
                        value={props.transaction.installmentFee}
                        descriptionKey="BANK_REPORT.INSTALLMENT_FEE"
                        kind={NumberInputFieldKind.Money}
                        step={0.01}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
                <Col>
                    <NumberInputField
                        name="monthlyCost"
                        value={props.transaction.monthlyCost}
                        descriptionKey="BANK_REPORT.MONTHLY_COST"
                        kind={NumberInputFieldKind.Money}
                        step={0.01}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
                <Col>
                    <NumberInputField
                        name="setupFee"
                        value={props.transaction.setupFee}
                        descriptionKey="BANK_REPORT.SETUP_FEE"
                        kind={NumberInputFieldKind.Money}
                        step={0.01}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <NumberInputField
                        name="dueDay"
                        value={props.transaction.dueDay}
                        descriptionKey="BANK_REPORT.DUE_DAY"
                        kind={NumberInputFieldKind.Days}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
                <Col>
                    <NumberInputField
                        name="repaymentPeriod"
                        value={props.transaction.repaymentPeriod}
                        descriptionKey="BANK_REPORT.REPAYMENT_PERIOD"
                        kind={NumberInputFieldKind.Months}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
                <Col>
                    <NumberInputField
                        name="maxAmount"
                        value={props.transaction.maxAmount}
                        descriptionKey="BANK_REPORT.MAX_AMOUNT"
                        kind={NumberInputFieldKind.Money}
                        step={0.01}
                        editMode={props.editMode}
                        onValueChanged={valueChangedCallback}
                    />
                </Col>
            </Row>
            {isAdmin(props) && props.transaction.bankStatus === BankStatus.Paid && 
                <>
                    {(clawbackEditMode || !!props.transaction.clawback) ?
                        <Row>
                            <Col>
                                <NumberInputField
                                    name="amount"
                                    value={props.transaction.clawback?.amount}
                                    descriptionKey="BANK_REPORT.CLAWBACK.AMOUNT"
                                    kind={NumberInputFieldKind.Money}
                                    step={0.01}
                                    editMode={clawbackEditMode || (!!props.transaction.clawback && props.editMode)}
                                    onValueChanged={amount => clawbackChanged('amount', amount || undefined)}
                                />
                            </Col>
                            <Col>
                                <DateTimeInputField
                                    name="issued"
                                    value={props.transaction.clawback?.issuedOn}
                                    descriptionKey="BANK_REPORT.CLAWBACK.ISSUED"
                                    editMode={clawbackEditMode || (!!props.transaction.clawback && props.editMode)}
                                    onValueChanged={issued => clawbackChanged('issuedOn', issued?.toISOString())}
                                    kind={DateTimeInputFieldKind.Date}
                                />
                            </Col>
                            <Col>
                                {clawbackEditMode || (!!props.transaction.clawback && props.editMode) ?
                                    <>
                                        <div className="description"><Translate id="BANK_REPORT.CLAWBACK.TYPE"/></div>
                                        <div className="value">
                                            <GenericDropdown
                                                dropdownId="type"
                                                selectedItem={clawbackItems.find((i) => i.id === (props.transaction.clawback?.type || ClawbackType.Refinancing))}
                                                items={clawbackItems}
                                                onSelectionChanged={item => clawbackChanged('type', item.id!)}
                                            />
                                        </div>
                                    </> :
                                    <TextInputField
                                        name="type"
                                        value={props.translate(getEnumTranslationKey(ClawbackType, props.transaction.clawback?.type, 'CLAWBACK_TYPE')).toString()}
                                        descriptionKey="BANK_REPORT.CLAWBACK.TYPE"
                                    />
                                }
                            </Col>
                        </Row> :
                        <Button variant="link" onClick={() => setClawbackEditMode(true)} className="bank-replies-form-add-clawback">{props.translate('ADD_CLAWBACK')}</Button>
                    }
                </>
            }
            {renderComment(props, valueChangedCallback)}
        </div>
    );
};

function renderComment(props: BankRepliesFormContentProps, valueChangedCallback: (value: string | number | null, name: string) => void) {
    const onCommentChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        props.onFormInputChanged(e.currentTarget.value === '');
        valueChangedCallback(e.currentTarget.value, 'comment');
    };

    if (!props.editMode) {
        return null;
    }

    return (
        <textarea
            name="comment"
            value={props.transaction.comment || undefined}
            placeholder={props.translate('NOTES').toString()}
            onChange={onCommentChange}
            className="bank-replies-form-comment form-control customized"
        />
    );
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer
});

export default connect<UserProps>(mapStateToProps)(withLocalize(BankRepliesFormContent));
