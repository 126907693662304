import {FunctionComponent} from "react";
import "./template-action.css";
import {TemplatePostAction, TemplatePostActionType} from "../../../../../common/models/CommentTemplate";
import AddSubstatus from "./AddSubstatus";
import RemoveSubstatus from "./RemoveSubstatus";
import SendMessage from "./SendMessage";
import {MessageType} from "../../../../../common/models/MessageType";
import ChangeStatus from "./ChangeStatus";
import {LoanStatus} from "../../../../../common/models/LoanStatus";

interface TemplateActionProps {
    action: TemplatePostAction;
}
const TemplateAction: FunctionComponent<TemplateActionProps> = (props) => {
    switch (props.action.type) {
        case TemplatePostActionType.ADD_SUBSTATUS:
            return <AddSubstatus substatusIds={props.action.data}/>;
        case TemplatePostActionType.REMOVE_SUBSTATUS:
            return <RemoveSubstatus substatusIds={props.action.data}/>;
        case TemplatePostActionType.SEND_EMAIL:
            return <SendMessage type={MessageType.Email} name={props.action.data}/>
        case TemplatePostActionType.SEND_SMS:
            return <SendMessage type={MessageType.SMS} name={props.action.data}/>
        case TemplatePostActionType.SEND_EMAIL_AND_SMS:
            return <SendMessage name={props.action.data}/>
        case TemplatePostActionType.DENY_APPLICATION:
            return <ChangeStatus status={LoanStatus.Denied}/>
        default:
            return null;
    }
}
export default TemplateAction;