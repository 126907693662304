import {FunctionComponent} from "react";
import "./sidebar.css";
import {AdministrationModule} from "../Modules/AdministrationModule";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {AdministrationModules} from "../Modules/AdministrationModules";

interface SidebarProps {
    selectedModule: AdministrationModule;
    onModuleChange: (module?: AdministrationModule) => void;
}
const Sidebar: FunctionComponent<SidebarProps> = (props) => {
    return (
        <div className="administration-modules-sidebar">
            <h3>{props.selectedModule.title}</h3>
            <div className="sidebar-item" onClick={() => props.onModuleChange()}>
                <FontAwesomeIcon icon={faArrowLeft}/>
                Back to Dashboard
            </div>
            {AdministrationModules.filter(module => module !== props.selectedModule).map((module, id) => (
                <div key={id} className="sidebar-item" onClick={() => props.onModuleChange(module)}>
                    <h5>{module.title}</h5>
                </div>
            ))}
        </div>
    )
}
export default Sidebar;