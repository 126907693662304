import { FunctionComponent, useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { Translate } from "react-localize-redux";
import { useParams } from "react-router";
import { getUnqualifiedBanks } from "../../api/getUnqualifiedBanks";
import { ApplicantViewRouteParams } from "../../models/ApplicantViewRouteParams";
import { connect } from "react-redux";
import { MetadataProps } from "../../../../common/interfaces/MetadataProps";
import { Bank } from "../../models/Bank";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCog, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";

interface SendToBanksConfirmationModalProps {
    selectedBankIds: number[];
    show: boolean;
    onSendWithValidation: () => void;
    onSendWithoutValidation: () => void;
    onCancel: () => void;
}

const SendToBanksConfirmationModal: FunctionComponent<SendToBanksConfirmationModalProps & MetadataProps> = (props) => {
    const params = useParams<ApplicantViewRouteParams>();
    const [bankIds, setBankIds] = useState<number[] | null>(null);
    const unqualifiedBankIds = bankIds !== null
        ? bankIds.filter(bankId => props.selectedBankIds.indexOf(bankId) > -1)
        : null;

    useEffect(() => {
        if (params.applicantId && params.applicationId && props.show) {
            getUnqualifiedBanks(params.applicantId, params.applicationId).then(response => setBankIds(response.data));
            return;
        }

        if (props.show === false) {
            setBankIds(null);
        }

    }, [params.applicantId, params.applicationId, props.show]);

    return (
        <Modal show={props.show} centered={true}>
            <Modal.Header>
                <Modal.Title>
                    <Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.TITLE" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {renderBody(unqualifiedBankIds, props.metadata.banks)}
            </Modal.Body>
            <Modal.Footer>
                {renderButtons(unqualifiedBankIds, props)}
            </Modal.Footer>
        </Modal>
    );
}

const renderButtons = (bankIds: number[] | null, props: SendToBanksConfirmationModalProps) => {
    if (bankIds === null) {
        return (
            <>
                <Button variant="secondary" onClick={props.onCancel}><Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.CANCEL" /></Button>
            </>
        );
    }

    if (bankIds.length > 0) {
        return (
            <>
                <Button variant="primary" onClick={props.onSendWithValidation}><Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.SEND_WITH_VALIDATION" /></Button>
                <Button variant="warning" onClick={props.onSendWithoutValidation}><Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.SEND_WITHOUT_VALIDATION" /></Button>
                <Button variant="secondary" onClick={props.onCancel}><Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.CANCEL" /></Button>
            </>
        );
    }

    return (
        <>
            <Button variant="primary" onClick={props.onSendWithValidation}><Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.SEND" /></Button>
            <Button variant="secondary" onClick={props.onCancel}><Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.CANCEL" /></Button>
        </>
    );
}

const renderBody = (bankIds: number[] | null, banks: Bank[]) => {
    if (bankIds === null) {
        return (
            <>
                <Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.CHECKING_BANKS" />
                <FontAwesomeIcon icon={faCog} spin={true} style={{ marginLeft: '10px', color: '#707070' }} />
            </>
        );
    }

    if (bankIds.length > 0) {
        return (
            <>
                <div style={{ marginBottom: '30px' }}>
                    <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: '#ff9900', marginRight: '5px' }} />
                    <Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.UNMET_CRITERIA" />
                    {bankIds.map(bankId => renderBank(bankId, banks))}
                </div>
                <Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.BODY" />
            </>
        );
    }

    return (
        <>
            <div style={{ marginBottom: '30px' }}>
                <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#42d253', marginRight: '5px' }} />
                <Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.MET_CRITERIA" />
            </div>
            <Translate id="SEND_TO_BANKS_CONFIRMATION_MODAL.BODY" />
        </>
    );
}

const renderBank = (bankId: number, banks: Bank[]) => {
    var bank = banks.find((bank) => bank.id === bankId);

    if (bank === undefined) {
        return null;
    }

    return (<div key={`bank-id-${bankId}`}><b>{bank.name}</b></div>);
}

const mapStateToProps = (state: any) => ({ ...state.metadataActionsReducer });

export default connect<MetadataProps, {}, SendToBanksConfirmationModalProps, any>(mapStateToProps)(SendToBanksConfirmationModal);