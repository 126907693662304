import React, {Dispatch, FunctionComponent, SetStateAction, useEffect, useState} from 'react';
import {ApplicantDataDispatchProps, ApplicantDataStateProps} from '../../models/ApplicantDataProps';
import {UserProps} from '../../../../common/interfaces/UserProps';
import {showToastMessage, ShowToastMessageProps} from '../../../../common/actions/ToastMessagesActionCreator';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ApplicationDataActionsCreator from '../../actions/applicantDataActionsCreator';
import {getMainBackgroundColorStyle} from '../../../../common/helpers/settingsHelpers';
import {SettingsProps} from '../../../../common/interfaces/SettingsProps';
import ApplicantInformation from '../ApplicantInformation';
import GlobalSearch from '../../../../common/components/GlobalSearch';
import LoanPicker from '../LoanPicker';
import {ConsumerLoanBrief} from '../../models/ConsumerLoanBrief';
import {LoanStatus} from '../../../../common/models/LoanStatus';
import InsuranceActions from './InsuranceActions';
import {InsuranceBasicDetails} from '../../models/InsuranceBasicDetails';
import {InsuranceType} from '../../models/InsuranceType';
import ProductTabs from '../ProductTabs';
import {ProductType} from '../../../../common/models/ProductType';
import Insurances from './Insurances';
import LogsAndComments from '../logs-and-comments/LogsAndComments';
import {Button, Modal} from 'react-bootstrap';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import InsuranceDetails from './InsuranceDetails';
import {cancelInsurance} from '../../api/insurances/cancelInsurance';
import {getEnumTranslationKey, getTranslationKey} from '../../../../common/helpers/getTranslationKey';
import {postInsurance} from '../../api/insurances/postInsurance';
import {InsuranceHistoryReadModel} from '../../models/InsuranceHistoryReadModel';
import {getInsuranceHistory} from '../../api/insurances/getInsuranceHistory';
import {NavigateFunction, Params, useNavigate, useParams} from "react-router";
import {SetApplicationTitleActionProps} from "../../../../common/interfaces/SetApplicationTitleActionProps";
import {SetTitleActionCreator} from "../../../../common/actions/SetTitleActionCreator";
import {CommandResult} from "../../../../common/helpers/CommandResult";
import {activateInsurance as activateInsuranceAPI} from "../../api/insurances/activateInsurance";
import {InsuranceReadModel} from "../../models/InsuranceReadModel";
import {putInsurance} from "../../api/insurances/putInsurance";

type InsuranceViewStateProps = ApplicantDataStateProps & SettingsProps & UserProps & LocalizeContextProps;
type InsuranceViewDispatchProps = ShowToastMessageProps & ApplicantDataDispatchProps & SetApplicationTitleActionProps;

type InsuranceRouteParams = 'personId';

type InsuranceViewProps = InsuranceViewStateProps & InsuranceViewDispatchProps & LocalizeContextProps;

const InsuranceView: FunctionComponent<InsuranceViewProps> = (props: InsuranceViewProps) => {
    const params = useParams<InsuranceRouteParams>();
    const navigate = useNavigate();
    const personId = getPersonId(params);
    const translate = (key: string) => props.translate(key).toString();
    const onCommentInputChange = () => {};
    
    const [insurance, setInsurance] = useState<InsuranceReadModel | InsuranceBasicDetails | null>(null);
    const [errors, setErrors] = useState<string[]>([]);
    const [history, setHistory] = useState<{ [id: number]: InsuranceHistoryReadModel[] }>({});
    
    useEffect(() => loadData(props, params), []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (insurance !== null && isEditMode(insurance)) {
            setInsurance(props.insurances.find(i => i.id === insurance.id) || null);
        }
    }, [props.insurances]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (errors.some(e => e === 'ActionNotAllowedInCurrentStatus')) {
            props.showToastMessage('error', translate(`ERRORS.${getTranslationKey('ActionNotAllowedInCurrentStatus')}`), []);
        }
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (props.currentApplication !== undefined) {
            if (!props.currentApplication.applicant.lastName && !props.currentApplication.applicant.firstName) {
                props.setTitle(`Applicant ${props.currentApplication.applicant.socialSecurityNumber}`);
            } else {
                props.setTitle(`Applicant ${props.currentApplication.applicant.lastName || ''},  ${props.currentApplication.applicant.firstName || ''} `);
            }
        }
    }, [props.currentApplication]); // eslint-disable-line react-hooks/exhaustive-deps

    if (personId === null || personId === undefined || !props.userData.user || !props.userData.user.insurances) {
        return null;
    }

    const cancelInsurance = (id: number, cancellationDate: Date) => handleCancelInsurance(id, personId, cancellationDate, props, translate, history, setHistory);
    const showNewInsuranceModal = () => setInsurance(createNewInsurance(personId));
    const hideModal = () => {
        setInsurance(null);
        setErrors([]);
    };
    const create = (asActive: boolean) => createInsurance(insurance, setInsurance, asActive, personId, hideModal, props, params, translate, setErrors);
    const activate = (id: number) => activateInsurance(personId, id, insurance, hideModal, props, params, translate, setErrors);
    const save = (id: number) => saveInsurance(personId, id, insurance, hideModal, props, params, translate, setErrors);
    const onChange = (i: InsuranceBasicDetails) => setInsurance(i);
    const onExpanded = (id: number) => ensureHistory(personId, id, history, setHistory);
    const onEdit = (id: number) => setInsurance(props.insurances.find(i => i.id === id) || null);
    return (
        <main className="main-content" style={getMainBackgroundColorStyle(props)}>
            <div className="jumbotron text-center bg-white flex-column insurance-view">
                {renderTopPanel(props, showNewInsuranceModal, params, navigate)}
                <div className="content-panel">
                    <Insurances insurances={props.insurances} onCancel={cancelInsurance} history={history} onExpanded={onExpanded} onEdit={onEdit}/>
                    <LogsAndComments
                        personId={personId}
                        translate={translate}
                        showToastMessage={props.showToastMessage}
                        onFormInputChanged={onCommentInputChange}
                        includeCoApplicant={false}
                    />
                </div>

                <Modal show={insurance !== null} centered={true} className="insurance-details-modal" onHide={hideModal}>
                    <Modal.Header>
                        <Modal.Title>
                            {isEditMode(insurance)
                                ? <Translate 
                                    id="INSURANCE_TYPE_NUMBER" 
                                    data={{
                                        insuranceType: translate(getEnumTranslationKey(InsuranceType, insurance.insuranceType, 'INSURANCE_TYPES')),
                                        insuranceNumber: insurance.number
                                    }}
                                  />
                                : <Translate id="NEW_INSURANCE" />
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InsuranceDetails
                            insurance={insurance}
                            lastPaid={props.currentApplication}
                            editMode={true}
                            onChange={onChange}
                            errors={errors}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-secondary" onClick={hideModal}><Translate id="CANCEL" /></Button>
                        <Button 
                            variant="secondary" 
                            onClick={() => isEditMode(insurance) ? save(insurance.id) : create(false)}
                        >
                            <Translate id={isEditMode(insurance) ? 'SAVE' : 'SAVE_AS_DRAFT'} />
                        </Button>
                        <Button 
                            variant="primary" 
                            className="submit-button" 
                            onClick={() => isEditMode(insurance) ? activate(insurance.id) : create(true)}
                        >
                            <Translate id={isEditMode(insurance) ? 'ACTIVATE' : 'SAVE_AS_ACTIVE'} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </main>
    );
}

function loadData(props: InsuranceViewProps, params: Params<InsuranceRouteParams>) {
    const personId = getPersonId(params);

    if (personId) {
        props.loanInsurances(personId);
        props.loadApplications(personId).then((apps) => loadApplication(apps, personId, props.loadCurrentApplication));
    }
}

function loadApplication(apps: ConsumerLoanBrief[], personId: number, loadCurrentApplication: (personId: number, applicationId: number) => void) {
    const app = apps.find((a) => a.loanStatus === LoanStatus.Paid) || apps[0];

    if (app) {
        loadCurrentApplication(personId, app.id);
    }
}

async function createInsurance(
    details: InsuranceBasicDetails | null,
    setInsurance: Dispatch<SetStateAction<InsuranceReadModel | InsuranceBasicDetails | null>>,
    asActive: boolean, 
    personId: number, 
    hideModal: () => void, 
    props: InsuranceViewProps, 
    params: Params<InsuranceRouteParams>, 
    translate: (key: string) => string,
    setError: Dispatch<SetStateAction<string[]>>
) {
    if (details === null) {
        return;
    }
    const validationErrors = asActive ? validateBeforeActivation(details) : validate(details);
    if (validationErrors.length) {
        setError(validationErrors);
        return;
    }

    const response = await postInsurance(personId, details);
    const result = new CommandResult(response);
    const action = 'NEW_INSURANCE';

    if (result.success) {
        props.showToastMessage('success', translate(action), translate(`${action}_SUCCESS`), action);
        if (asActive) {
            setInsurance({...details, id: response.data.id});
            loadData(props, params);
            activateInsurance(personId, response.data.id, details, hideModal, props, params, translate, setError);
        } else {
            hideModal();
            loadData(props, params);
        }
    } else {
        setError(result.errors.map((e) => e.message || e.code));
    }
}

async function activateInsurance(
    personId: number, 
    insuranceId: number, 
    details: InsuranceBasicDetails | null, 
    hideModal: () => void, 
    props: InsuranceViewProps, 
    params: Params<InsuranceRouteParams>, 
    translate: (key: string) => string, 
    setError: Dispatch<SetStateAction<string[]>>
) {
    const validationErrors = validateBeforeActivation(details);
    if (validationErrors.length) {
        setError(validationErrors);
        return;
    }
    const action = 'ACTIVATE_INSURANCE';
    const result = await activateInsuranceAPI(personId, insuranceId, details || {});
    if (result.success) {
        props.showToastMessage('success', translate(action), translate(`${action}_SUCCESS`), action);
        hideModal();
        loadData(props, params)
    } else {
        setError(result.errors.map((e) => e.message || e.code));
    }
}

async function saveInsurance(
    personId: number, 
    insuranceId: number, 
    details: InsuranceBasicDetails | null, 
    hideModal: () => void, 
    props: InsuranceViewProps, 
    params: Params<InsuranceRouteParams>, 
    translate: (key: string) => string, 
    setError: Dispatch<SetStateAction<string[]>>
) {
    const validationErrors = validate(details);
    if (validationErrors.length) {
        setError(validationErrors);
        return;
    }
    const action = 'SAVE_INSURANCE';    
    const result = await putInsurance(personId, insuranceId, details || {});
    if (result.success) {
        props.showToastMessage('success', translate(action), translate(`${action}_SUCCESS`), action);
        hideModal();
        loadData(props, params)
    } else {
        setError(result.errors.map((e) => e.message || e.code));
    }
}

async function handleCancelInsurance(id: number, personId: number, cancellationDate: Date, props: InsuranceViewProps, translate: (key: string) => string, history: { [id: number]: InsuranceHistoryReadModel[] }, setHistory: Dispatch<SetStateAction<{ [id: number]: InsuranceHistoryReadModel[] }>>) {
    const commandResult = await cancelInsurance(personId, id, cancellationDate);
    const action = 'CANCEL_INSURANCE';

    if (commandResult.success) {
        props.loanInsurances(personId);
        loadHistory(personId, id, history, setHistory);
        props.showToastMessage('success', translate(action), translate(`${action}_SUCCESS`), action);
    } else {
        props.showToastMessage('error', translate(action), commandResult.errors.map((e) => translate(`ERRORS.${getTranslationKey(e.code)}`)), action);
    }
}

function ensureHistory(personId: number, id: number, history: { [id: number]: InsuranceHistoryReadModel[] }, setHistory: Dispatch<SetStateAction<{ [id: number]: InsuranceHistoryReadModel[] }>>) {
    if (history[id] === undefined) {
        loadHistory(personId, id, history, setHistory);
    }
}

async function loadHistory(personId: number, id: number, history: { [id: number]: InsuranceHistoryReadModel[] }, setHistory: Dispatch<SetStateAction<{ [id: number]: InsuranceHistoryReadModel[] }>>) {
    const insuranceHistory = await getInsuranceHistory(personId, id);
    setHistory({...history, [id]: insuranceHistory.data });
}

function validate(insurance: InsuranceBasicDetails | null) {
    const errors: string[] = [];
    errors.push(...validateInstalment(insurance?.instalment));
    if (insurance?.invoiceDeliveryMethod === undefined || insurance?.invoiceDeliveryMethod === null) {
        errors.push('InvoiceDeliveryMethodIsRequired');
    }
    return errors;
}

function validateInstalment(instalment?: number) {
    const errors: string[] = [];
    if (!instalment) {
        errors.push('InstalmentIsRequired');
    } else {
        if (instalment < 2000) {
            errors.push('InstalmentTooLow');
        }
        else if (instalment > 8000) {
            errors.push('InstalmentTooHigh');
        }
    }
    return errors;
}

function validateBeforeActivation(insurance: InsuranceBasicDetails | null) {
    const errors = validate(insurance);
    if (!insurance?.term) {
        errors.push('TermIsRequired');
    }
    if (!insurance?.startDate) {
        errors.push('StartDateIsRequired');
    }
    return errors;
}

function renderTopPanel(props: InsuranceViewProps, showNewInsuranceModal: () => void, params: Params<InsuranceRouteParams>, navigate: NavigateFunction) {
    const onLoanPicked = (applicationId: number) => navigate(`/applicant/${getPersonId(params)}/application/${applicationId}`);
    const onProductTypePicked = (productType: ProductType | 'insurance') => navigateToProduct(productType, props, params, navigate);
    if (!props.currentApplication) {
        return null;
    }

    return (
        <div className="top-panel">
            <div>
                <ApplicantInformation
                    {...props}
                    applicant={props.currentApplication.applicant}
                    countryId={props.currentApplication.applicant.countryId}
                    loanStatus={props.currentApplication.loanStatus}
                    useCreditScoreSettings={true}
                />
                <ProductTabs
                    selectedProduct={'insurance'}
                    otherLoans={props.applications}
                    onSelectionChanged={onProductTypePicked}
                    showInsuranceTab={true}
                    hasInurances={props.insurances.length > 0}
                    scopeSourceId={props.userData.user && props.userData.user.applicationSourceId}
                />
            </div>
            <div>
                <GlobalSearch />
                <InsuranceActions createInsurance={(showNewInsuranceModal)} />
                <LoanPicker
                    loans={props.applications}
                    onLoanPicked={onLoanPicked}
                    scopeSourceId = {props.userData.user && props.userData.user.applicationSourceId}
                />
            </div>
        </div>)
    ;
}

function navigateToProduct(productType: ProductType | 'insurance', props: InsuranceViewProps, params: Params<InsuranceRouteParams>, navigate: NavigateFunction) {
    if (productType === 'insurance') {
        return;
    }

    const scopeSourceId = props.userData.user && props.userData.user.applicationSourceId;
    const latestApplicationByOfType = props.applications.filter((x) => x.productType === productType && (scopeSourceId === undefined || scopeSourceId === null || x.sourceId === scopeSourceId))[0];
    if (latestApplicationByOfType !== undefined) {
        navigate(`/applicant/${params.personId}/application/${latestApplicationByOfType.id}`);
    }
}

export function isEditMode(insurance: InsuranceReadModel | InsuranceBasicDetails | null): insurance is InsuranceReadModel {
    return !!(insurance as InsuranceReadModel | null)?.id;
}

const createNewInsurance = (personId: number): InsuranceBasicDetails => ({
    personId: personId,
    paymentFrequency: 1,
    insuranceType: InsuranceType.ExpenseProtector
});

const getPersonId = (params: Params<InsuranceRouteParams>) => params.personId ? parseInt(params.personId) : null;

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer,
    ...state.settingsActionsReducer,
    ...state.applicantDataReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({
    showToastMessage,
    ...ApplicationDataActionsCreator,
    ...SetTitleActionCreator
} as any, dispatch);

export default connect<InsuranceViewStateProps, InsuranceViewDispatchProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(InsuranceView));
