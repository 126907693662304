import React, {MouseEvent, ReactNode, useEffect} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faSort, faSortDown, faSortUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Translate} from 'react-localize-redux';
import {OrderDirection, Ordering} from "../../models/TableState";

export interface TableHeaderCellModel<T = unknown> {
    index: number;
    name?: keyof T;
    translateKey: string;
    width?: number;
    numeric?: boolean;
    orderDirection?: OrderDirection;
}

interface TableHeaderProps<T> {
    cells: TableHeaderCellModel<T>[];
    customOrder?: boolean;
    sorting?: boolean;
    orderings?: Ordering<T>[];
    onClick?: (index: number) => void;
    onOrderingsChange?: (orderings: Ordering<T>[]) => void;
}

function TableHeader<T = unknown>(props: TableHeaderProps<T>) {
    const {cells, onOrderingsChange, orderings} = props;

    useEffect(() => {
        if (orderings?.length) {
            const validOrderings = orderings.filter(o => cells.some(c => c.name === o.column));
            if (validOrderings.length < orderings.length) {
                onOrderingsChange && onOrderingsChange(validOrderings);
            }
        }
    }, [cells, orderings, onOrderingsChange]);
    
    const mapCells = (cell: TableHeaderCellModel<T>, index: number) => {
        const clickHandler = (e: MouseEvent) => {
            props.onClick && props.onClick(cell.index);
            if (cell.name) {
                let orderings = props.orderings ? [...props.orderings] : [];
                const columnOrderIndex = orderings.findIndex(o => o.column === cell.name);
                if (columnOrderIndex !== -1 && e.shiftKey) {
                    // If column is already used in ordering and shift key is pressed
                    if (orderings[columnOrderIndex].direction === OrderDirection.Ascending) {
                        // Change order direction to descending
                        orderings[columnOrderIndex].direction = OrderDirection.Descending;
                    } else {
                        // Remove from ordering
                        orderings.splice(columnOrderIndex, 1);
                    }
                } else if (e.shiftKey) {
                    // If shift key is pressed but column is not used in ordering
                    orderings.push({ column: cell.name, direction: OrderDirection.Ascending });
                } else if (columnOrderIndex !== -1) {
                    // If column is already used in ordering
                    if (orderings[columnOrderIndex].direction === OrderDirection.Ascending) {
                        // Change order direction to descending
                        orderings = [{ column: cell.name, direction: OrderDirection.Descending }];
                    } else {
                        // Remove from ordering
                        orderings = [];
                    }
                } else {
                    // Set ordering only to this column
                    orderings = [{ column: cell.name, direction: OrderDirection.Ascending }];
                }
                props.onOrderingsChange && props.onOrderingsChange(orderings);
            }
        }
        
        let sortingIcon: ReactNode = null;
        if (props.sorting) {
            let icon: IconProp = faSort;

            if (cell.orderDirection !== undefined) {
                icon = cell.orderDirection === OrderDirection.Descending ? faSortDown : faSortUp;
            }
            sortingIcon = <FontAwesomeIcon icon={icon} />
        }
        if (props.orderings && cell.name) {
            let icon: IconProp = faSort;
            const columnOrderIndex = props.orderings.findIndex(o => o.column === cell.name);
            
            if (columnOrderIndex !== -1) {
                icon = props.orderings[columnOrderIndex].direction === OrderDirection.Descending ? faSortDown : faSortUp;
            }
            
            sortingIcon = <span>
                <FontAwesomeIcon icon={icon} style={{marginRight: '4px'}}/>
                {props.orderings.length > 1 && columnOrderIndex !== -1 ? columnOrderIndex + 1 : null}
            </span>
        }

        const style = {
            textAlign: cell.numeric ? 'right' : 'left',
            width: cell.width === undefined ? 'auto' : cell.width + 'px',
            paddingRight: cell.numeric ? '55px' : undefined,
            userSelect: 'none'
        } as React.CSSProperties;

        return (
            <th key={`table-header-${index}`} className="cursor-pointer" onClick={clickHandler} style={style}>
                <Translate id={cell.translateKey} /><span className="ms-1">{sortingIcon}</span>
            </th>
        );
    };

    return (
        <thead>
            <tr>
                {props.cells
                    .sort((c1, c2) => !props.customOrder ? c1.index - c2.index : 0)
                    .map(mapCells)}
            </tr>
        </thead>
    );
}

export default TableHeader;
