import React, {FunctionComponent, Dispatch, useMemo, useEffect, useReducer, useState, SetStateAction} from 'react';
import { SettingsProps } from '../../../common/interfaces/SettingsProps';
import { connect } from 'react-redux';
import { ConsumerLoanDetails } from '../models/ConsumerLoanDetails';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import { Redemption } from '../models/Redemption';
import {
    getMainBackgroundColorStyle,
    getCountryId,
    getCreditScoreRules,
    getAutomaticSubstatusesRules
} from '../../../common/helpers/settingsHelpers';
import ApplicantInformation from './ApplicantInformation';
import GlobalSearch from '../../../common/components/GlobalSearch';
import { mapProductTypesToSelectableItems } from '../../../common/helpers/productTypeFunctions';
import { ProductType } from '../../../common/models/ProductType';
import { SelectableItem } from '../../../common/models/SelectableItem';
import { withLocalize, LocalizeContextProps, Translate } from 'react-localize-redux';
import { AxiosResponse } from 'axios';
import { ConsumerLoan } from '../models/ConsumerLoan';
import { getConsumerLoan } from '../api/getConsumerLoan';
import LoanPicker from './LoanPicker';
import { ConsumerLoanBrief } from '../models/ConsumerLoanBrief';
import ApplicantBox from './ApplicantBox';
import EditApplication from './EditApplication';
import EditLoans from './EditLoans';
import { Button } from 'react-bootstrap';
import { LoanStatus } from '../../../common/models/LoanStatus';
import { postConsumerLoan } from '../api/postConsumerLoan';
import { ConsumerLoanApplicantDetails } from '../models/ConsumerLoanApplicantDetails';
import { getConsumerLoanApplicantDetails, getConsumerLoanCoApplicantDetails } from '../api/getConsumerLoanApplicantDetails';
import { saveAsComplete as saveAsCompleteApi } from '../api/saveAsComplete';
import { validateConsumerLoan } from '../api/validateConsumerLoan';
import { putConsumerLoan } from '../api/putConsumerLoan';
import { NewApplicationState } from '../models/NewApplicationState';
import { newApplicationActionsReducer } from '../reducers/newApplicationActionsReducer';
import {
    updateConsumerLoanDetails,
    switchApplicants,
    deleteCoApplicant,
    NewApplicationAction,
    setConsumerLoan,
    setApplicantDetails,
    setErrors,
    updateProductType,
    updateRedemptions,
    updateApplicant,
    updateCoApplicant,
    copyConsumerLoan,
    addCoApplicant,
    setCoApplicantDetails,
    showDeleteCoApplicantConfirmationModal,
    hideDeleteCoApplicantConfirmationModal,
    setValidationStatus,
    setSubstatuses,
    copyApplicantDetails,
    setNewApplicationDefaults,
    addExistingCoApplicant,
    hideAddCoApplicantConfirmationModal,
    showAddCoApplicantConfirmationModal
} from '../actions/newApplicationActionsCreator';
import AddCoApplicantButton from './AddCoApplicantButton';
import { useServerValidation } from '../helpers/newApplicationValidationLogic';
import { useNavigationConfirmation } from '../../../common/helpers/useNavigationConfirmation';
import { CommandError } from '../../../common/helpers/CommandResult';
import { bindActionCreators } from 'redux';
import { showToastMessage, ShowToastMessageProps } from '../../../common/actions/ToastMessagesActionCreator';
import './new-application.css';
import ConfirmationModal from '../../../common/components/ConfirmationModal';
import { ApplicantDataStateProps, ApplicantDataDispatchProps } from '../models/ApplicantDataProps';
import ApplicationDataActionsCreator from '../actions/applicantDataActionsCreator';
import DropdownInputField from '../../../common/components/input-fields/DropdownInputField';
import InformationModal from '../../../common/components/InformationModal';
import PropertyInformation from './PropertyInformation';
import classNames from 'classnames';
import { ApplicationRouteParams } from '../../../common/interfaces/ApplicationRouteParams';
import { UserProps } from '../../../common/interfaces/UserProps';
import { NewApplicationTemplate } from '../models/NewApplicationTemplate';
import AddCoApplicantModal from '../../../common/components/AddCoApplicantModal';
import {MetadataProps} from "../../../common/interfaces/MetadataProps";
import {NavigateFunction, Params, useNavigate, useParams} from "react-router";
import {SetTitleActionCreator} from "../../../common/actions/SetTitleActionCreator";
import {SetApplicationTitleActionProps} from "../../../common/interfaces/SetApplicationTitleActionProps";
import {SubstatusRule} from "../../../common/models/SubstatusRule";
import {useUpdatedApplicationNotification} from "../helpers/useUpdatedApplicationNotification";
import {showExternalChangeModal} from "../../../common/components/ExternalChangeModal";

type NewApplicationStateProps = SettingsProps & MetadataProps & ApplicantDataStateProps & UserProps & LocalizeContextProps;

type NewApplicationDispatchProps = ShowToastMessageProps & ApplicantDataDispatchProps & SetApplicationTitleActionProps;

type NewApplicationProps = LocalizeContextProps & NewApplicationStateProps & NewApplicationDispatchProps;

const NewApplication: FunctionComponent<NewApplicationProps> = (props: NewApplicationProps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const productTypes = useMemo(() => mapProductTypesToSelectableItems(props.translate, true, false), []);
    const countryId = getCountryId(props);
    const [state, dispatch] = useReducer(newApplicationActionsReducer, createEmptyState(countryId));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const params = useParams<ApplicationRouteParams>();
    const navigate = useNavigate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => loadData(props, dispatch, params), []);
    useEffect(() => {
        if (state.details.productType !== null && !isEditMode(props)) {
            dispatch(setSubstatuses(getAutomaticProductTypeSubstatuses(props, state)));
        }
    }, [state.details.productType]); // eslint-disable-line react-hooks/exhaustive-deps

    useUpdatedApplicationNotification((notification) => {
        if (isEditMode(props) && 
            props.currentApplication && 
            props.currentApplication.id === notification.applicationId && 
            props.userData.user && 
            (notification.username === null || props.userData.user.username === notification.username)
        ) {
            showExternalChangeModal({
                ...props,
                title: 'APPLICATION_CHANGED',
                message: 'APPLICATION_CHANGED_DESCRIPTION',
                onRefresh: () => loadData(props, dispatch, params)
            });
        }
    });
    
    useServerValidation(state, callServerValidation, (e) => dispatchSetErrorsAction(e, dispatch));
    const unblockNavigation = useNavigationConfirmation(state.modified, props.translate);
    const onUpdateDetails = (details: ConsumerLoanDetails) => dispatch(updateConsumerLoanDetails(details));
    const onUpdateRedemptions = (redemptions: Redemption[]) => dispatch(updateRedemptions(redemptions));
    const onUpdateApplicant = (applicant: ConsumerLoanApplicantDetails) => dispatch(updateApplicant(applicant));
    const onUpdateCoApplicant = (coApplicant: ConsumerLoanApplicantDetails) => dispatch(updateCoApplicant(coApplicant));
    const onApplicantsSwap = () => dispatch(switchApplicants());
    const onCoApplicantAdded = () => {
        dispatch(addCoApplicant(countryId));
        dispatch(hideAddCoApplicantConfirmationModal());
    };
    const onExistingCoApplicantAdded = (applicant: ConsumerLoanApplicantDetails) => {
        dispatch(addExistingCoApplicant(applicant));
        dispatch(hideAddCoApplicantConfirmationModal());
    };
    const onShowAddCoApplicantModal = () => dispatch(showAddCoApplicantConfirmationModal());
    const onHideAddCoApplicantModal = () => dispatch(hideAddCoApplicantConfirmationModal());
    const onSubstatusesChanged = (substatusIds: number[]) => dispatch(setSubstatuses(substatusIds));
    const onShowConfirmationModal = () => dispatch(showDeleteCoApplicantConfirmationModal());
    const onConfirmCoApplicantDelete = () => { dispatch(deleteCoApplicant()); dispatch(hideDeleteCoApplicantConfirmationModal()); };
    const onCancelCoApplicantDelete = () => { dispatch(hideDeleteCoApplicantConfirmationModal()); };
    const onSave = () => save(props, state, dispatch, params, navigate, unblockNavigation, setIsLoading);
    const onSaveAsComplete = () => saveAsComplete(props, state, dispatch, params, navigate, unblockNavigation, setIsLoading);
    const onCancel = () => { unblockNavigation(); navigate(-1) };
    const isComplete = (state.validationLoanStatus || state.loanStatus) !== LoanStatus.Incomplete;

    const coApplicantBox = renderCoApplicantBox(
        state.coApplicant,
        onUpdateCoApplicant,
        countryId,
        onApplicantsSwap,
        onShowConfirmationModal,
        onShowAddCoApplicantModal,
        state.errors,
        isComplete);

    const filteredApplications = isEditMode(props) && state.details.productType ? props.applications.filter((x) => x.productType === state.details.productType) : [];
    
    useEffect(() => {
        if (state !== undefined) {
            if (!state.applicant.lastName && !state.applicant.firstName) {
                props.setTitle(`Applicant ${state.applicant.socialSecurityNumber}`);
            } else {
                props.setTitle(`Applicant ${state.applicant.lastName || ''},  ${state.applicant.firstName || ''} `);
            }
        }
    }, [state.applicant, props.setTitle]); // eslint-disable-line react-hooks/exhaustive-deps

    if (state.loanStatus === LoanStatus.Investigation) {
        const creditScoreRules = getCreditScoreRules(props);
        if (creditScoreRules !== undefined && state.applicant.creditScore === null) {
            const onClose = () => navigate(`/applicant/${params.applicantId}/application/${params.applicationId}`);
            return (
                <InformationModal
                    show={true}
                    message={<Translate id="CANNOT_EDIT_APPLICATION_TITLE" />}
                    title={<Translate id="CANNOT_EDIT_APPLICATION_MESSAGE" />}
                    onClose={onClose}
                />
            );
        }
    }

    return (
        <main className="main-content" style={getMainBackgroundColorStyle(props)}>
            <div className={classNames('jumbotron text-center bg-white flex-column new-application', { 'edit-mode': isEditMode(props) })}>
                <div className="top-panel">
                    <div>
                        <ApplicantInformation
                            {...props}
                            applicant={state.applicant}
                            key="applicant-1"
                            countryId={countryId}
                            loanStatus={props.currentApplication !== undefined ? props.currentApplication.loanStatus : undefined}
                            useCreditScoreSettings={getCreditScoreRules(props) !== undefined && isCopyMode(props) === false}
                        />
                        {renderCoApplicant(state.coApplicant, countryId, props)}
                        {renderPropertyInformation(state, filteredApplications.length)}
                    </div>
                    <div>
                        <GlobalSearch />
                        {isEditMode(props) ? renderLoanPicker(props, filteredApplications, params, navigate) : renderProductsDropdown(props, productTypes, state, dispatch)}
                    </div>
                </div>
                <form className="content-panel">
                    <ApplicantBox applicant={state.applicant} countryId={countryId} onApplicantChanged={onUpdateApplicant} errors={state.errors} isComplete={isComplete} />
                    {coApplicantBox}
                    <EditLoans redemptions={state.redemptions} onRedemptionsChanged={onUpdateRedemptions} errors={state.errors} isComplete={isComplete} />
                    <div>
                        <EditApplication
                            details={state.details}
                            countryId={countryId}
                            errors={state.errors}
                            onDetailsChanged={onUpdateDetails}
                            isComplete={isComplete}
                            substatusIds={state.substatusIds}
                            onSubstatusesChanged={onSubstatusesChanged}
                        />
                        {renderButtons(state.loanStatus !== LoanStatus.Incomplete, isLoading, onSave, onSaveAsComplete, onCancel)}
                    </div>
                </form>
            </div>
            <ConfirmationModal
                show={state.showCoApplicantDeleteConfirmationModal}
                title={<Translate id="CONFIRM_COAPPLICANT_DELETE_TITLE" />}
                message={<Translate id="CONFIRM_COAPPLICANT_DELETE_MESSAGE" />}
                onConfirm={onConfirmCoApplicantDelete}
                onCancel={onCancelCoApplicantDelete}
            />
            <AddCoApplicantModal
                show={state.showCoApplicantAddConfirmationModal}
                onNewApplicant={onCoApplicantAdded}
                onSelectExistingApplicant={onExistingCoApplicantAdded}
                onCancel={onHideAddCoApplicantModal}
            />
        </main>
    );
};

function renderCoApplicantBox(
    coApplicant: ConsumerLoanApplicantDetails | undefined,
    onUpdateCoApplicant: (applicant: ConsumerLoanApplicantDetails) => void,
    countryId: number,
    swapFn: () => void,
    deleteFn: () => void,
    addFn: () => void,
    errors: string[],
    isComplete: boolean) {
    if (coApplicant === undefined) {
        return (<AddCoApplicantButton addCoApplicant={addFn} />);
    }

    return (
        <ApplicantBox
            applicant={coApplicant}
            countryId={countryId}
            onApplicantChanged={onUpdateCoApplicant}
            onApplicantsSwapped={swapFn}
            onDelete={deleteFn}
            isCoApplicant={true}
            errors={errors}
            isComplete={isComplete}
        />
    );
}

function loadData(props: NewApplicationProps, dispatch: Dispatch<NewApplicationAction>, params: Partial<Params<keyof ApplicationRouteParams>>) {
    const applicantId = params.applicantId;
    const applicationId = params.applicationId;

    if (applicantId && applicationId) {

        getConsumerLoan(applicantId, applicationId).then((loanResponse: AxiosResponse<ConsumerLoan>): void => {
            applyConsumerLoanData(props, dispatch, loanResponse.data);

            getConsumerLoanApplicantDetails(applicantId, applicationId, loanResponse.data.applicant.id).then((applicantDetails) => {
                applyApplicantDetails(props, dispatch, applicantDetails, loanResponse.data.details.submittedDate);
            });

            if (loanResponse.data.coApplicant && loanResponse.data.coApplicant.id) {
                getConsumerLoanCoApplicantDetails(applicantId, applicationId, loanResponse.data.coApplicant.id).then((applicantDetails) => {
                    dispatch(setCoApplicantDetails(applicantDetails));
                });
            }
        });

        if (isEditMode(props)) {
            props.loadApplications(parseInt(applicantId, 10));
        }
    } else {
        applyNewApplicationTemplate(props, dispatch);
    }
}

function applyNewApplicationTemplate(props: NewApplicationProps, dispatch: Dispatch<NewApplicationAction>) {
    const newApplicationTemplate = props.userData !== undefined && props.userData.user !== undefined && props.userData.user.newApplicationTemplate !== null
        ? JSON.parse(props.userData.user.newApplicationTemplate) as NewApplicationTemplate
        : null;
    dispatch(setNewApplicationDefaults(newApplicationTemplate));
}

function applyConsumerLoanData(props: NewApplicationProps, dispatch: Dispatch<NewApplicationAction>, consumerLoan: ConsumerLoan) {
    if (isEditMode(props)) {
        dispatch(setConsumerLoan(consumerLoan));
    } else {
        const newApplicationTemplate = props.userData !== undefined && props.userData.user !== undefined && props.userData.user.newApplicationTemplate !== null
            ? JSON.parse(props.userData.user.newApplicationTemplate) as NewApplicationTemplate
            : null;
        addAutomaticSubstatuses(props, consumerLoan);
        dispatch(copyConsumerLoan(consumerLoan, newApplicationTemplate));
    }
}

function addAutomaticSubstatuses(props: NewApplicationProps, consumerLoan: ConsumerLoan) {
    const advertisementSite = props.metadata.advertisementSites.find((site) => site.advertisements.some((advertisement) => advertisement.id === consumerLoan.details.advertisementId));
    const automaticSubstatusesRules = getAutomaticSubstatusesRules(props);
    const automaticSubstatusIds = automaticSubstatusesRules.filter(rule => {
        return (rule.AdvertisementId && rule.AdvertisementId === consumerLoan.details.advertisementId)
            || (rule.AdvertisementSiteId && advertisementSite && rule.AdvertisementSiteId === advertisementSite.id)
            || (rule.SourceId && rule.SourceId === consumerLoan.details.sourceId)
    }).map(rule => rule.SubstatusIds).flat();
    consumerLoan.substatusIds = Array.from(new Set([...consumerLoan.substatusIds, ...automaticSubstatusIds]));
}

function getAutomaticProductTypeSubstatuses(props: NewApplicationProps, state: NewApplicationState) {
    const automaticSubstatusesRules = getAutomaticSubstatusesRules(props);
    removeAllAutomaticProductTypeSubstatuses(automaticSubstatusesRules, state);
    const automaticSubstatusIds = automaticSubstatusesRules
        .filter(rule => rule.ProductType !== undefined && rule.ProductType === state.details.productType)
        .map(rule => rule.SubstatusIds)
        .flat();
    return Array.from(new Set([...state.substatusIds, ...automaticSubstatusIds]));
}

function removeAllAutomaticProductTypeSubstatuses(rules: SubstatusRule[], state: NewApplicationState) {
    const allAutomaticProductTypeSubstatuses = rules
        .filter(rule => rule.ProductType !== undefined)
        .map(rule => rule.SubstatusIds)
        .flat();
    state.substatusIds = state.substatusIds.filter(id => !allAutomaticProductTypeSubstatuses.includes(id));
}

function applyApplicantDetails(props: NewApplicationProps, dispatch: Dispatch<NewApplicationAction>, applicantDetails: ConsumerLoanApplicantDetails, submittedDate?: Date) {
    if (isEditMode(props)) {
        dispatch(setApplicantDetails(applicantDetails));
    } else {
        dispatch(copyApplicantDetails(applicantDetails, submittedDate));
    }
}

function renderCoApplicant(coApplicant: ConsumerLoanApplicant | undefined, countryId: number, props: NewApplicationProps) {
    if (!coApplicant) {
        return null;
    }

    return (
        <ApplicantInformation
            {...props}
            applicant={coApplicant}
            key="applicant-2"
            countryId={countryId}
            isCoApplicant={true}
        />
    );
}

function renderPropertyInformation(state: NewApplicationState, numberOfApplications: number) {
    if (state.details.productType !== ProductType.Mortgage || !state.property) {
        return null;
    }

    return (
        <PropertyInformation
            property={state.property}
            loanDetails={state.details}
            applicant={state.applicant}
            coApplicant={state.coApplicant}
            redemptions={state.redemptions}
            numberOfLoans={numberOfApplications}
        />
    );
}

function renderProductsDropdown(props: NewApplicationProps, productTypes: SelectableItem<number>[], state: NewApplicationState, dispatch: Dispatch<NewApplicationAction>) {
    const productType = productTypes.find((x) => x.id === (state.details.productType as number));

    const mapKeyValueToProductType = (x: string | number | null | undefined) => {
        if (x === '0' || x === 0) { return ProductType.ConsumerLoan; }
        if (x === '1' || x === 1) { return ProductType.CreditCard; }
        if (x === '2' || x === 2) { return ProductType.Mortgage; }
        throw Error('Unable to map key to product type');
    };

    const value = productType !== undefined && productType.id !== null ? productType.id : undefined;

    const updateProduct = (kv: string | number | null | undefined) => dispatch(updateProductType(mapKeyValueToProductType(kv)));

    const keyValue = (item: SelectableItem<number>) => (item.id !== null ? item.id : '');

    const displayValue = (item: SelectableItem<number>) => (item.name);

    return (
        <div>
            <DropdownInputField
                style={{ width: '210px' }}
                descriptionKey="PRODUCT_TYPE"
                name="products"
                value={value}
                keyValue={keyValue}
                displayValue={displayValue}
                items={productTypes}
                editMode={true}
                onValueChanged={updateProduct}
                required={'ProductTypeIsRequired'}
                errors={state.errors}
                overrideInternalErrors={['valueMissing']}
            />
        </div>
    );
}

function renderLoanPicker(props: NewApplicationProps, consumerLoans: ConsumerLoanBrief[], params: Partial<Params<keyof ApplicationRouteParams>>, navigate: NavigateFunction) {
    if (!params.applicationId || consumerLoans.length === 0 ) {
        return null;
    }

    const onLoanPicked = () => navigate(`/applicant/${params.applicantId}/application/${params.applicationId}`);

    return (
        <LoanPicker
            loans={consumerLoans}
            onLoanPicked={onLoanPicked}
            selectedLoanId={parseInt(params.applicationId, 10)}
            scopeSourceId={props.userData.user && props.userData.user.applicationSourceId}
            countLimit={props.currentApplication && props.currentApplication.coApplicant && props.currentApplication.property ? 3 : undefined}
        />
    );
}

function renderButtons(isComplete: boolean, isLoading: boolean, onSave: () => void, onSaveAsComplete: () => void, onCancel: () => void) {
    return (
        <div className="buttons">
            <Button key="cancel" variant="outline-secondary" className="text-button" onClick={onCancel}><Translate id="CANCEL" /></Button>
            <Button key="save" disabled={isLoading} variant={isComplete ? 'primary' : 'secondary'} className="save-button text-button submit-button" onClick={onSave}><Translate id="SAVE" /></Button>
            {renderSaveAsCompleteButton(isComplete, isLoading, onSaveAsComplete)}
        </div>
    );
}

function renderSaveAsCompleteButton(isComplete: boolean, isLoading: boolean, onSaveAsComplete: () => void) {
    if (isComplete) {
        return null;
    }

    return <Button key="save-as-complete" disabled={isLoading} className="text-button submit-button" onClick={onSaveAsComplete}><Translate id="SAVE_AS_COMPLETE" /></Button>;
}

function dispatchSetErrorsAction(errors: CommandError[], dispatch: Dispatch<NewApplicationAction>) {
    dispatch(setErrors(errors.map((e) => e.message || e.code)));
}

function handleError(errors: CommandError[], props: NewApplicationProps) {
    if (errors[0].code === 'ApplicationUpdateConcurrency') {
        props.showToastMessage('error', props.translate('UPDATE_APPLICATION').toString(), props.translate('ERRORS.APPLICATION_UPDATE_CONCURRENCY').toString());
    }
}

function createCommand(state: NewApplicationState) {
    return {
        applicant: state.applicant,
        coApplicant: state.coApplicant,
        details: state.details,
        redemptions: state.redemptions,
        substatusIds: state.substatusIds,
        parentId: state.parentId
    };
}

function updateDataInRedux(props: NewApplicationProps, state: NewApplicationState) {
    if (props.currentApplication) {
        const newApplication = {
            ...props.currentApplication,
            ...createCommand(state)
        } as ConsumerLoan;

        props.setCurrentApplication(newApplication);
    }
}

function callServerValidation(state: NewApplicationState, loanStatus?: LoanStatus) {
    const command = { ...createCommand(state), loanStatus: loanStatus || state.validationLoanStatus || state.loanStatus };
    return validateConsumerLoan(command);
}

function handleSuccesfulSave(props: NewApplicationProps, state: NewApplicationState, params: Partial<Params<keyof ApplicationRouteParams>>, navigate: NavigateFunction, unblockNavigation: () => void) {
    unblockNavigation();
    props.showToastMessage('success', props.translate('UPDATE_APPLICATION').toString(), props.translate('UPDATE_APPLICATION_SUCCESS').toString(), 'UPDATE_APPLICATION');
    updateDataInRedux(props, state);

    if (state.ssnChanged) {
        navigate('/applicants');
        return;
    }

    navigate(`/applicant/${params.applicantId}/application/${params.applicationId}`);
}

async function save(
    props: NewApplicationProps, 
    state: NewApplicationState, 
    dispatch: Dispatch<NewApplicationAction>, 
    params: Partial<Params<keyof ApplicationRouteParams>>, 
    navigate: NavigateFunction, 
    unblockNavigation: () => void, 
    setIsLoading: Dispatch<SetStateAction<boolean>>
) {
    setIsLoading(true);
    const validationResult = await callServerValidation(state);

    if (validationResult.length > 0) {
        dispatchSetErrorsAction(validationResult, dispatch);
        setIsLoading(false);
        return;
    }

    if (isEditMode(props) && params.applicantId && params.applicationId) {
        const command = {
            ...createCommand(state),
            personId: parseInt(params.applicantId, 10),
            applicationId: parseInt(params.applicationId, 10)
        };
        const result = await putConsumerLoan(command);
        setIsLoading(false);

        if (result.success) {
            handleSuccesfulSave(props, state, params, navigate, unblockNavigation);
        } else {
            handleError(result.errors, props);
            dispatchSetErrorsAction(result.errors, dispatch);
        }
    } else {
        await createConsumerLoan(state, props, false, navigate, unblockNavigation, setIsLoading);
    }
}

async function saveAsComplete(
    props: NewApplicationProps, 
    state: NewApplicationState, 
    dispatch: Dispatch<NewApplicationAction>, 
    params: Partial<Params<keyof ApplicationRouteParams>>, 
    navigate: NavigateFunction, 
    unblockNavigation: () => void, 
    setIsLoading: Dispatch<SetStateAction<boolean>>
) {
    setIsLoading(true);
    const validationResult = await callServerValidation(state, LoanStatus.Investigation);

    if (validationResult.length > 0) {
        dispatch(setValidationStatus(LoanStatus.Investigation));
        dispatchSetErrorsAction(validationResult, dispatch);
        setIsLoading(false);
        return;
    }

    state = clearApplicationProgess(state);
    if (isEditMode(props) && params.applicantId && params.applicationId) {
        const result = await saveAsCompleteApi(params.applicantId, params.applicationId, createCommand(state));
        setIsLoading(false);

        if (result.success) {
            handleSuccesfulSave(props, state, params, navigate, unblockNavigation);
        } else {
            handleError(result.errors, props);
            dispatchSetErrorsAction(result.errors, dispatch);
        }
    } else {
        createConsumerLoan(state, props, true, navigate, unblockNavigation, setIsLoading);
    }
}

async function createConsumerLoan(
    state: NewApplicationState, 
    props: NewApplicationProps, 
    complete: boolean, 
    navigate: NavigateFunction, 
    unblockNavigation: () => void, 
    setIsLoading: Dispatch<SetStateAction<boolean>>
) {
    const result = await postConsumerLoan(createCommand(state), complete);
    setIsLoading(false);

    if (!result.errors) {
        props.showToastMessage('success', props.translate('CREATE_APPLICATION').toString(), props.translate('CREATE_APPLICATION_SUCCESS').toString(), 'UPDATE_APPLICATION');
        unblockNavigation();
        updateDataInRedux(props, state);
        navigate(`/applicant/${result.personId}/application/${result.applicationId}`);
    }
}

function createEmptyState(countryId: number): NewApplicationState {
    return {
        loanStatus: LoanStatus.Incomplete,
        consumerLoans: [],
        details: { productType: null } as ConsumerLoanDetails,
        property: undefined,
        applicant: {
            countryId,
            firstName: '',
            lastName: '',
            afterTaxMonthlyIncome: 0
        } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
        coApplicant: undefined,
        redemptions: [] as Redemption[],
        errors: [],
        modified: false,
        ssnChanged: false,
        complete: false,
        showCoApplicantDeleteConfirmationModal: false,
        showCoApplicantAddConfirmationModal: false,
        substatusIds: []
    } as NewApplicationState;
}

function isEditMode(props: NewApplicationProps) {
    return window.location.pathname.endsWith('/edit');
}

function isCopyMode(props: NewApplicationProps) {
    return window.location.pathname.endsWith('/copy');
}

function clearApplicationProgess(state: NewApplicationState) {
    return {...state, details: {...state.details, applicationFormProgress: null }};
}

const mapStateToProps = (state: any) => ({
    ...state.metadataActionsReducer,
    ...state.userActionsReducer,
    ...state.settingsActionsReducer,
    ...state.applicantDataReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators({
    showToastMessage,
    ...ApplicationDataActionsCreator,
    ...SetTitleActionCreator
} as any, dispatch);

export default connect<NewApplicationStateProps, NewApplicationDispatchProps, {}, any>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(NewApplication));
