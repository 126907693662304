import React, {FunctionComponent} from "react";
import {Translate} from "react-localize-redux";
import {MessageType} from "../../../../../common/models/MessageType";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faMobile, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {connect} from "react-redux";
import {ApplicantDataStateProps} from "../../../models/ApplicantDataProps";

interface SendMessageProps extends ApplicantDataStateProps {
    type?: MessageType;
    name: string;
}
const SendMessage: FunctionComponent<SendMessageProps> = (props) => {
    const hasEmail = props.currentApplication?.applicant.email && props.currentApplication?.applicant.email !== 'GDPR_GONE';
    const hasMobileNumber = props.currentApplication?.applicant.mobileNumber && props.currentApplication?.applicant.mobileNumber !== 'GDPR_GONE';
    
    if (
        (props.type === MessageType.Email && !hasEmail) ||
        (props.type === MessageType.SMS && !hasMobileNumber) ||
        (!hasEmail && !hasMobileNumber)
    ) {
        return null;
    }
    
    let messageIcon = faPaperPlane;
    switch (props.type) {
        case MessageType.SMS:
            messageIcon = faMobile;
            break;
        case MessageType.Email:
            messageIcon = faEnvelope;
            break;
    }

    return (
        <div className="send-message-template-post-action">
            <div className="description"><Translate id="SEND_MESSAGE"/></div>
            <FontAwesomeIcon icon={messageIcon}/>
            <span>{props.name}</span>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.applicantDataReducer
});
export default connect<ApplicantDataStateProps, {}, {}, any>(mapStateToProps)(SendMessage);