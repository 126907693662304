import React, {
    CSSProperties,
    Dispatch,
    FunctionComponent,
    MouseEvent,
    MouseEventHandler,
    PropsWithChildren,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from 'react';
import {Dropdown, OverlayTrigger, Popover} from 'react-bootstrap';
import {getConsumerLoanLogs} from '../../applicant/api/getConsumerLoanLogs';
import {ConsumerLoanLog} from '../../applicant/models/ConsumerLoanLog';
import LogsAndCommentsTable from '../../applicant/components/logs-and-comments/LogsAndCommentsTable';
import {logsAndCommentsFiltersStorage} from '../../applicant/helpers/logsAndCommentsFiltersStorage';
import {ConsumerLoanLogType} from '../../applicant/models/ConsumerLoanLogType';
import {ConsumerLoanOverview} from '../models/ConsumerLoanOverview';
import {getConsumerLoanOverview} from '../api/getConsumerLoanOverview';
import LoanPicker from '../../applicant/components/LoanPicker';
import {SubstatusesProps} from '../../../common/interfaces/SubstatusesProps';
import SubstatusesActionsCreator from '../actions/SubstatusesActionsCreator';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Substatus} from '../models/Substatus';
import {loanStatusToColor} from '../../../common/helpers/loanStatusToColor';
import {Translate} from "react-localize-redux";
import {beautifyName} from "../../../common/helpers/beautifyName";
import {TransactionOverview} from '../../../common/models/TransactionOverview';
import {
    renderStatus,
    renderTransactionAmount,
    renderTransactionInterest
} from "../../applicant/components/bank-replies/BankReplies";
import {UserProps} from "../../../common/interfaces/UserProps";
import BankRepliesHistory from "../../applicant/components/bank-replies/BankRepliesHistory";
import {MetadataProps} from "../../../common/interfaces/MetadataProps";
import {OverlayTriggerRenderProps} from "react-bootstrap/OverlayTrigger";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import ApplicantActionsMenu, {ApplicantActionsMenuProps} from "./ApplicantActionsMenu";

interface ApplicantTooltipProps extends ApplicantActionsMenuProps {
    personId: number;
    applicationId: number;
    firstName: string | null;
    lastName: string | null;
    hasCoApplicant?: boolean;
}

type ApplicantTooltipContainerProps = ApplicantTooltipProps & SubstatusesProps & UserProps & MetadataProps & typeof SubstatusesActionsCreator;

const logsPageSize = 10;

const mapStateToProps = (state: any) => ({
    ...state.substatusesReducer,
    ...state.userActionsReducer,
    ...state.metadataActionsReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(SubstatusesActionsCreator, dispatch);

const ApplicantTooltipContainer = connect<SubstatusesProps & UserProps & MetadataProps, typeof SubstatusesActionsCreator, ApplicantTooltipProps, any>(mapStateToProps, mapActionCreatorsToProps)((props: PropsWithChildren<ApplicantTooltipContainerProps>) => {
    const [logs, setLogs] = useState<ConsumerLoanLog[]>([]);
    const [logsCount, setLogsCount] = useState(0);
    const [overview, setOverview] = useState<ConsumerLoanOverview|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (props.substatuses === undefined) {
            props.loadSubstatuses();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        Promise.all([
            loadOverview(props.personId, props.applicationId, setOverview),
            loadLogs(props.personId, props.applicationId, !!props.hasCoApplicant, setLogs, setLogsCount)
        ]).then(() => setIsLoading(false));
    }, [props.applicationId]); // eslint-disable-line react-hooks/exhaustive-deps

    let displayName: JSX.Element | string = <Translate id="NO_DATA"/>;
    if (props.firstName || props.lastName) {
        displayName = beautifyName(`${props.firstName || ''} ${props.lastName || ''}`, false)
    }
    
    const headerStyle: CSSProperties = {
        display: 'flex', 
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        columnGap: '12px',
        alignItems: 'center'
    }

    return (
        <>
            <Popover.Header
                as="h3"
                className="uppercase"
                style={headerStyle}
            >
                <span>
                    {displayName}{renderCoApplicantName(overview)}
                </span>
                <Dropdown drop="down" align="start">
                    <Dropdown.Toggle id="applicant-actions" className="btn-white-bg" style={{ padding: '3px 12px' }}>
                        <FontAwesomeIcon icon={faBars} />
                    </Dropdown.Toggle>
                    <ApplicantActionsMenu {...props}/>
                </Dropdown>
            </Popover.Header>
            <Popover.Body style={{minHeight: isLoading ? '300px' : '150px'}}>
                {renderOverview(props.applicationId, overview, props.substatuses)}
                {renderLogs(logs, logsCount)}
                {renderTransactions(props, overview?.transactions)}
            </Popover.Body>
        </>
    );
});

const renderCoApplicantName = (overview: ConsumerLoanOverview | null) =>
    overview && overview.coApplicantFirstName && overview.coApplicantLastName
    ? <span>, {beautifyName(`${overview.coApplicantFirstName} ${overview.coApplicantLastName}`, false)}</span>
    : null;

const renderOverview = (applicationId: number, overview: ConsumerLoanOverview | null, substatuses: Substatus[] | undefined) => {
    if (!overview || !substatuses) {
        return null;
    }

    return (
        <div className="applicant-tooltip-overview">
            <LoanPicker loans={overview.applications} selectedLoanId={applicationId}/>
            <div className="substatuses">{overview.substatusIds.map((id) => renderSubstatus(id, substatuses))}</div>
        </div>
    );
};

const renderSubstatus = (id: number, substatuses: Substatus[]) => {
    const substatus = substatuses.find((s) => s.id === id);
    return substatus
    ? <span key={id} className={`status ${getCssClass(substatus)}`}>{substatus.name}</span>
    : null;
};

const getCssClass = (substatus: Substatus) => {
    if (substatus.loanStatuses.length !== 1) {
        return 'status-gray';
    }

    return `status-${loanStatusToColor(substatus.loanStatuses[0])}`;
};

const renderLogs = (logs: ConsumerLoanLog[], logsCount: number) =>
    logsCount !== 0
    ? <LogsAndCommentsTable logs={logs} logCount={logsCount} pageNumber={0} pageSize={logsPageSize} orderDirection={1} />
    : null;

const renderTransactions = (props: ApplicantTooltipContainerProps, transactions?: TransactionOverview[]) => {
    if (!transactions?.length) {
        return null;
    }
    
    return (
        <table>
            <tbody>
                {transactions.map(transaction => (
                    <React.Fragment key={`transaction-${transaction.id}`}>
                        <tr className="bank-row transaction-overview-row">
                            <td><span>{props.metadata.banks.find(bank => bank.id === transaction.bankId)?.name}</span></td>
                            {renderTransactionAmount(props, transaction.amount, transaction.bankStatus)}
                            {renderTransactionInterest(transaction.nominalInterest)}
                            {renderStatus(transaction.bankStatus)}
                        </tr>
                        {transaction.bankReportHistory?.length ? 
                            <tr>
                                <td colSpan={4}>
                                    <BankRepliesHistory bankHistories={transaction.bankReportHistory} />
                                </td>
                            </tr> : null
                        }
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    )
}

async function loadOverview(personId: number, applicationId: number, setOverview: Dispatch<SetStateAction<ConsumerLoanOverview | null>>) {
    const result = await getConsumerLoanOverview(personId, applicationId);
    setOverview(result.data);
}

async function loadLogs(personId: number, applicationId: number, hasCoApplicant: boolean, setLogs: Dispatch<SetStateAction<ConsumerLoanLog[]>>, setLogsCount: Dispatch<SetStateAction<number>>) {
    const filters = logsAndCommentsFiltersStorage.get();
    const logTypes: ConsumerLoanLogType[] = [];

    if (filters.filterComments === true) {
        logTypes.push(ConsumerLoanLogType.Comment);
    }

    if (filters.filterEvents === true) {
        logTypes.push(ConsumerLoanLogType.Event);
        logTypes.push(ConsumerLoanLogType.CustomerCommunication);
    }

    const result = await getConsumerLoanLogs(personId, applicationId, hasCoApplicant, 0, logsPageSize, 1, logTypes);
    setLogs(result.loanLogs);
    setLogsCount(result.loanLogsCount);
}

const ApplicantTooltipTrigger: FunctionComponent<ApplicantTooltipProps> = (props) => {
    const hideOverlay = useRef<MouseEventHandler<HTMLDivElement>>(() => {/*no-op*/ });
    const requestHideOverlayId = useRef<NodeJS.Timeout>();

    const requestHideOverlay = (e: MouseEvent<HTMLDivElement>) => {
        const target = e.currentTarget;
        requestHideOverlayId.current = setTimeout(() => {
            e.currentTarget = target;
            hideOverlay.current(e)
        }, 200);
    }

    const cancelHideOverlay = () => {
        if (requestHideOverlayId.current) {
            clearTimeout(requestHideOverlayId.current);
        }
    }

    return (
        <OverlayTrigger
            delay={{show: 500, hide: 0}}
            placement="auto"
            overlay={
                <Popover
                    id="applicant-tooltip-container"
                    className="popover applicant-tooltip"
                    onMouseOver={cancelHideOverlay}
                    onMouseOut={e => requestHideOverlay(e)}
                    onClick={e => e.stopPropagation()}
                    onContextMenu={e => e.stopPropagation()}
                >
                    <ApplicantTooltipContainer {...props}/>
                </Popover>
            }
        >
            {/*render overlay target by function to get OT props with toggle handler*/}
            {/*see https://react-bootstrap.netlify.app/docs/components/overlays/#customizing-trigger-behavior*/}
            {
                (overlayProps: OverlayTriggerRenderProps) => {
                    hideOverlay.current = (overlayProps as any).onMouseOut;
                    return (
                        <div
                            id="applicant-tooltip-trigger"
                            ref={overlayProps.ref}
                            onMouseOver={(overlayProps as any).onMouseOver}
                            onMouseOut={e => requestHideOverlay(e)}
                        >
                            {props.children}
                        </div>
                    )
                }
            }
        </OverlayTrigger>
    );
}

const ApplicantTooltip = ApplicantTooltipTrigger;

export default ApplicantTooltip;
