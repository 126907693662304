import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {MessageType} from '../models/MessageType';
import {ProductType} from '../models/ProductType';
import {CommunicationTemplate} from '../models/CommunicationTemplate';
import {Button, Form, Modal} from 'react-bootstrap';
import GenericDropdown from './GenericDropdown';
import {Editor} from '@tinymce/tinymce-react';
import {LocalizeContextProps, Translate, TranslateFunction, withLocalize} from 'react-localize-redux';
import {postEmailMessage} from '../../applications/applicant/api/postEmailMessage';
import TextInputField from './input-fields/TextInputField';
import {ApplicantsFilter} from '../../applications/applicants/models/ApplicantsFilter';
import {mapProductTypesToSelectableItems} from '../helpers/productTypeFunctions';
import DropdownInputField from './input-fields/DropdownInputField';
import './send-modal.css';
import {postEmailToMany} from '../../applications/applicants/api/postEmailToMany';
import {connect} from 'react-redux';
import {SettingsProps} from '../interfaces/SettingsProps';
import DraggableModalDialog from './DraggableModalDialog';
import {bindActionCreators, Dispatch} from 'redux';
import {showToastMessage, ShowToastMessageProps} from '../actions/ToastMessagesActionCreator';
import {
    CommunicationTemplatesDispatchProps,
    CommunicationTemplatesState
} from '../interfaces/CommunicationTemplatesProps';
import {CommunicationTemplatesActionCreator} from '../actions/CommunicationTemplatesActionCreator';
import {UserProps} from '../interfaces/UserProps';
import {getLanguageItems} from '../helpers/getLanguageItems';
import {MailSender} from '../helpers/useMailSender';
import {beautifyLastName} from '../helpers/beautifyName';
import {Transaction} from '../../applications/applicant/models/Transaction';
import {Bank} from '../../applications/applicant/models/Bank';
import {fillEmailTemplateContentWithData} from '../helpers/SendMailAndSmsUtils';
import {IProps} from "@tinymce/tinymce-react/lib/es2015/main/ts/components/Editor";
import CommunicationTemplatesSelector from "./CommunicationTemplatesSelector";
import MailSenderSelector from "./MailSenderSelector";
import {ApplicantCommunicationModel} from "../models/ApplicantCommunicationModel";
import {isValueSet} from "../helpers/isValueSet";

export const editorConfig: IProps['init'] = {
    height: 500,
    relative_urls: false,
    remove_script_host : false,
    menubar: false,
    valid_elements: '*[*]',
    external_plugins: {
        pluginId: 'https://cdn.motty.no/tinymce_fullpage_plugin.min.js'
    },
    plugins:
        `advlist autolink lists link image 
        code table code help wordcount fullpage`
    ,
    toolbar:
        `undo redo | blocks | bold italic backcolor |
        alignleft aligncenter alignright alignjustify |
        bullist numlist outdent indent | link image | removeformat | code | visualaid | help`
};

interface SendEmailModalProps {
    show: boolean;
    productType?: ProductType;
    defaultTemplate?: string;
    applicant?: ApplicantCommunicationModel | null;
    incompleteFormLink?: string;
    applicationId?: number;
    selectedEmail?: string;
    appliantsFilter?: ApplicantsFilter;
    applicantsCount?: number;
    myApplicationLink?: string;
    debtInformationUpdateLink?: string;
    transactions?: Transaction[];
    banks?: Bank[];
    publicId?: string;
    onClose: () => void;
    onLanguageChanged?: (language: string) => void;
}

interface NamedEmail {
    name: string;
    email: string;
}

const SendEmailModal: FunctionComponent<SendEmailModalProps & SettingsProps & ShowToastMessageProps &
    CommunicationTemplatesState & CommunicationTemplatesDispatchProps & LocalizeContextProps & UserProps> = (props) => {

        const [selectedTemplate, setSelectedTemplate] = useState<CommunicationTemplate | undefined>(undefined);
        const [subject, setSubject] = useState('');
        const [productType, setProductType] = useState(props.productType || ProductType.ConsumerLoan);
        const [content, setContent] = useState('');
        const [selectedMailSender, setSelectedMailSender] = useState<MailSender | undefined>(undefined);
        const languages = useMemo(() => getLanguageItems(props.translate, false, false), []); // eslint-disable-line react-hooks/exhaustive-deps
        const [language, setLanguage] = useState<string | null | undefined>(props.applicant?.primaryLanguage);
        const [email, setEmail] = useState<string | null>(props.selectedEmail ? props.selectedEmail : null);
        const [errors, setErrors] = useState<string[]>([]);

        useEffect(() => { setEmail(props.selectedEmail ? props.selectedEmail : null); }, [props.selectedEmail]);

        const mainEmail = props.applicant ? props.applicant.email : null;
        const secondaryEmail = props.applicant ? props.applicant.additionalEmail : null;

        const emails: NamedEmail[] = [];

        if (isValueSet(mainEmail)) { emails.push({ name: props.translate('PRIMARY').toString(), email: mainEmail }); }
        if (isValueSet(secondaryEmail)) { emails.push({ name: props.translate('SECONDARY').toString(), email: secondaryEmail }); }
        const selectedEmail = emails.find((x) => x.email === email);

        const onSelectedEmailChanged = (x: NamedEmail) => setEmail(x.email);
        
        const translate = (key: string) => props.translate(key).toString();

        const sendEmail = () => {
            setErrors(!subject ? ['SubjectIsRequired'] : []);
            if (errors.length) {
                return;
            }
            if (props.applicant && props.applicationId && email) {
                const message = props.translate('SEND_EMAIL_SUCCESS', { fullname: getFullName(props.applicant), email }).toString();
                const onFailure = () => props.showToastMessage('error', translate('SEND_EMAIL'), translate('SEND_EMAIL_ERROR'));
                postEmailMessage(subject,
                    content,
                    selectedMailSender!.mail,
                    selectedMailSender!.name,
                    email,
                    getFullName(props.applicant),
                    props.applicationId).then(
                        (successResult) => {
                            if (successResult.success) {
                                props.showToastMessage('success', translate('SEND_EMAIL'), message);
                                props.onClose();
                            } else {
                                onFailure();
                            }
                        },
                        onFailure);

                if (isValueSet(language) && props.onLanguageChanged !== undefined && props.applicant.primaryLanguage !== language) {
                    props.onLanguageChanged(language);
                }
            }

            if (props.appliantsFilter) {
                const message = props.translate('SEND_EMAIL_TO_MANY_SUCCESS', { count: props.applicantsCount }).toString();
                const onFailure = () => props.showToastMessage('error', translate('SEND_EMAIL_TO_MANY_TITLE'), translate('SEND_EMAIL_ERROR'));

                postEmailToMany(subject, content, selectedMailSender!.name, selectedMailSender!.mail, props.appliantsFilter).then(
                    (successResult) => {
                        if (successResult.success) {
                            props.showToastMessage('success', translate('SEND_EMAIL_TO_MANY_TITLE'), message);
                            props.onClose();
                        } else {
                            onFailure();
                        }
                    },
                    onFailure);
            }
        };

        const onSelectedTemplateChanged = (item?: CommunicationTemplate, signature?: string) => {
            setSelectedTemplate(item);
            setSubject(item && item.subject !== null ? item.subject : '');
            const templateContentWithData = fillEmailTemplateContentWithData(
                (item && item.templateContent) || '',
                signature,
                props.userData.user,
                props.applicant,
                props.incompleteFormLink,
                props.myApplicationLink || '',
                props.debtInformationUpdateLink,
                props.transactions || [],
                props.banks || [],
                props.publicId
            );
            setContent(templateContentWithData);
        };

        const onLanguageChanged = (lang: string | number | null | undefined) => setLanguage(lang === null || lang === undefined ? null : lang.toString());

        const renderMailSelector = () => {
            return emails.length > 1 ? (
                <React.Fragment>
                    <div className="description">
                        <Translate id="SELECT_EMAIL" />
                    </div>
                    <div className="value">
                        <GenericDropdown
                            dropdownId="email"
                            selectedItem={selectedEmail}
                            displayValue={selectedEmailDisplayValue}
                            items={emails}
                            onSelectionChanged={onSelectedEmailChanged}
                        />
                    </div>
                </React.Fragment>
            ) : null;
        };

        return (
            <Modal show={props.show} centered={true} className="send-email-modal" dialogAs={DraggableModalDialog} backdrop={false} enforceFocus={false}>
                <form>
                    <Modal.Header>
                        <Modal.Title>
                            {renderTitle(props, email)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {renderInformation(props.applicantsCount)}
                            <div>
                                {renderMailSelector()}
                                {renderProductType(props.productType === undefined, productType, setProductType, props.translate)}
                                <DropdownInputField
                                    descriptionKey="PRIMARY_LANGUAGE"
                                    editMode={true}
                                    name="primaryLanguage"
                                    onValueChanged={onLanguageChanged}
                                    value={language || undefined}
                                    items={languages}
                                />
                                <CommunicationTemplatesSelector
                                    default={props.defaultTemplate}
                                    type={MessageType.Email}
                                    productType={productType}
                                    language={language}
                                    selected={selectedTemplate}
                                    onChange={onSelectedTemplateChanged}
                                />
                                <MailSenderSelector
                                    editMode={true}
                                    selected={selectedMailSender}
                                    storeSelectedSender={true}
                                    onChange={setSelectedMailSender}
                                />
                                <Form.Group controlId="email-form">
                                    <TextInputField
                                        descriptionKey="EMAIL_SUBJECT"
                                        editMode={true}
                                        name="subject"
                                        errors={errors}
                                        onValueChanged={setSubject}
                                        required={'SubjectIsRequired'}
                                        value={subject}
                                    />
                                    <div className="description">{props.translate('EMAIL_CONTENT')}</div>
                                    {renderEditor(content, setContent)}
                                </Form.Group>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" className="submit-button" onClick={sendEmail}>
                            <Translate id="SEND" />
                        </Button>
                        <Button variant="secondary" onClick={props.onClose}>
                            <Translate id="CANCEL" />
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    };

const selectedEmailDisplayValue = (x: NamedEmail) => (`${x.name} (${x.email})`);

const getFullName = (applicant: ApplicantCommunicationModel) => beautifyLastName(`${applicant.firstName || ''} ${applicant.lastName || ''}`);

const renderTitle = (props: SendEmailModalProps & LocalizeContextProps, email: string | null) => {
    if (props.applicant && email) {
        return props.translate('SEND_EMAIL_MODAL_TITLE',
            { fullname: getFullName(props.applicant), email });
    }

    return props.translate('SEND_EMAIL_TO_MANY_TITLE');
};

const renderInformation = (count?: number) =>
    count ? <div className="info"><Translate id="SEND_EMAIL_TO_MANY_INFO" data={{ count }} /></div> : null;

const renderProductType = (show: boolean, productType: ProductType, setProductType: (productType: ProductType) => void, translate: TranslateFunction) => {
    if (!show) {
        return null;
    }

    const items = mapProductTypesToSelectableItems(translate, true, false);
    const onChange = (value: string | number | null | undefined) => setProductType(value as ProductType);

    return (
        <DropdownInputField
            style={{ width: '210px' }}
            descriptionKey="PRODUCT_TYPE"
            name="products"
            value={productType}
            items={items}
            editMode={true}
            onValueChanged={onChange}
        />
    );
};

const renderEditor = (content: string, setContent: (c: string) => void) => {
    const onEditorChange = (c: string) => setContent(c);

    return (
        <Editor
            value={content}
            init={editorConfig}
            onEditorChange={onEditorChange}
        />
    );
};

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer,
    ...state.communicationTemplates
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CommunicationTemplatesActionCreator,
    showToastMessage
} as any, dispatch);

export default connect<SettingsProps & CommunicationTemplatesState, ShowToastMessageProps & CommunicationTemplatesDispatchProps & UserProps, SendEmailModalProps, any>
    (mapStateToProps, mapActionCreatorsToProps)(withLocalize(SendEmailModal));
