import React, {FunctionComponent, useMemo} from "react";
import {connect} from "react-redux";
import {ApplicantDataStateProps} from "../../../models/ApplicantDataProps";
import SubstatusesInputField from "../../../../../common/components/input-fields/SubstatusesInputField";
import {Translate} from "react-localize-redux";

interface RemoveSubstatusProps extends ApplicantDataStateProps {
    substatusIds: number[];
}
const RemoveSubstatus: FunctionComponent<RemoveSubstatusProps> = (props) => {
    const substatusIdsToRemove = useMemo(() => {
        return props.substatusIds.filter(id => props.currentApplication?.substatusIds.includes(id))
    }, [props.substatusIds]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!substatusIdsToRemove.length) {
        return null;
    }

    return (
        <div className="remove-substatus-template-post-action">
            <div className="description"><Translate id="REMOVE_SUBSTATUSES"/></div>
            <SubstatusesInputField substatusIds={substatusIdsToRemove}/>
        </div>
    )
}


const mapStateToProps = (state: any) => ({
    ...state.applicantDataReducer
});
export default connect<ApplicantDataStateProps, {}, {}, any>(mapStateToProps)(RemoveSubstatus);