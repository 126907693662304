import {FunctionComponent} from "react";
import "./dashboard.css";
import {AdministrationModule} from "../Modules/AdministrationModule";
import {AdministrationModules} from "../Modules/AdministrationModules";
import AdministrationTile from "./AdministrationTile/AdministrationTile";

interface DashboardProps {
    onModuleClick: (module: AdministrationModule) => void;
}
const Dashboard: FunctionComponent<DashboardProps> = (props) => {
    return (
        <div className="dashboard">
            {AdministrationModules.map((module, id) => (
                <AdministrationTile key={id} title={module.title} onTitleClick={() => props.onModuleClick(module)}>
                    {module.render()}
                </AdministrationTile>
            ))}
        </div>
    )
}
export default Dashboard;