import {
    faEnvelope,
    faPhone,
    IconDefinition,
    faCreditCard,
    faGavel,
    faHome,
    faCog,
    faPaperPlane,
    faMapSigns,
    faAddressCard
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {FunctionComponent, MouseEventHandler} from 'react';
import {LocalizeContextProps, Translate} from 'react-localize-redux';
import { getCivilStatusTranslateKey } from '../../../common/helpers/getCivilStatusTranslateKey';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import './applicant-information.css';
import { BisnodeIcon } from './BisnodeIcon';
import CopyToClipboardButton from '../../../common/components/CopyToClipboardButton';
import { SettingsProps } from '../../../common/interfaces/SettingsProps';
import { connect } from 'react-redux';
import { getDebtInformationUpdateLink, getIncompleteFormLink } from '../../../common/helpers/settingsHelpers';
import { LoanStatus } from '../../../common/models/LoanStatus';
import { ProductType } from '../../../common/models/ProductType';
import Tooltip from '../../../common/components/Tooltip';
import {isValueSet} from "../../../common/helpers/isValueSet";
import NumberFormatter from "../../../common/components/NumberFormatter";

interface ApplicantInformationsProps extends SettingsProps, LocalizeContextProps {
    applicant: ConsumerLoanApplicant;
    isCoApplicant?: boolean;
    loanStatus?: LoanStatus;
    sourceId?: number | null;
    productType?: ProductType | null;
    countryId: number;
    onClick?: MouseEventHandler<HTMLDivElement>;
    useCreditScoreSettings?: boolean;
    applicationPublicId?: string;
    onPhoneNumberClick?: (phoneNumber: string) => void;
    onEmailClick?: (email: string) => void;
    onMessageClick?: () => void;
    onCreditInformationClick?: () => void;
}

const GDPR_GONE = 'GDPR_GONE';

const ApplicantInformation: FunctionComponent<ApplicantInformationsProps> = (props) => {
    return (
        <div className="applicant-information" onClick={props.onClick}>
            {renderDisplayName(props)}
            <div className="details">
                {renderCivilStatus(props.applicant.civilStatus)}
                <div>
                    <NumberFormatter value={props.applicant.afterTaxMonthlyIncome}/> / <Translate id="MONTH_SHORT" />
                </div>
            </div>
            <div className="contact-info">
                <div>
                    <div>
                        {renderMobileNumber(props.applicant.mobileNumber, !props.applicant.mobileMarketingConsent, props.onPhoneNumberClick)}
                        {renderMobileNumber(props.applicant.additionalMobileNumber, !props.applicant.mobileMarketingConsent, props.onPhoneNumberClick)}
                    </div>
                    <div>
                        {renderEmail(props.applicant.email, !props.applicant.emailMarketingConsent, props.onEmailClick)}
                        {renderEmail(props.applicant.additionalEmail, !props.applicant.mobileMarketingConsent, props.onEmailClick)}
                    </div>
                    {renderSendEmailAndSmsButton(props)}
                    {!props.isCoApplicant ? renderCreditAndDebtInformation(props.applicant, props.onCreditInformationClick) : null}
                </div>
                <div>
                    {renderAddress(props.applicant)}
                    {renderLanguageInfo(props.applicant.primaryLanguage, props)}
                </div>
                {renderIncompleteFormLink(props, props.applicant.primaryLanguage, props.applicationPublicId, props.loanStatus !== LoanStatus.Incomplete, props.productType, props.sourceId, props.isCoApplicant)}
            </div>
        </div>
    );
};

function renderSendEmailAndSmsButton(props: ApplicantInformationsProps) {
    if (props.applicant.mobileNumber === 'GDPR_GONE' || props.applicant.email === 'GDPR_GONE') {
        return null;
    }

    if (props.applicant.mobileNumber && props.applicant.email && props.onMessageClick) {
        const onClickHandler: MouseEventHandler = (event) => {
            event.stopPropagation();
            if (props.onMessageClick) {
                props.onMessageClick();
            }
        };
        return (
            <div style={{ marginRight: '15px' }}>
                <Tooltip content={<Translate id="SEND_EMAIL_AND_SMS" />}>
                    <span onClick={onClickHandler} style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                </Tooltip>
            </div>
        );
    }

    return null;
}

function renderMobileNumber(mobileNumber: string | null | undefined, marketingConsentRevoked: boolean, onClick?: (mobileNumber: string) => void) {
    return isValueSet(mobileNumber) && mobileNumber !== '' && mobileNumber !== 'GDPR_GONE' ? (
        <div className={marketingConsentRevoked ? 'marketing-consent-revoked' : ''}>
            <FontAwesomeIcon icon={faPhone} flip="horizontal" />
            <CopyToClipboardButton content={mobileNumber}>
                <Tooltip content={marketingConsentRevoked ? <Translate id={'REVOKED_MARKETING_CONSENT_SMS'}/> : null}>
                    {showLink('tel', mobileNumber, onClick)}
                </Tooltip>
            </CopyToClipboardButton>
        </div>
    ) : null;
}

function renderEmail(email: string | null | undefined, marketingConsentRevoked: boolean, onClick?: (email: string) => void) {
    return isValueSet(email) && email !== '' && email !== 'GDPR_GONE' ? (
        <div className={marketingConsentRevoked ? 'marketing-consent-revoked' : ''}>
            <FontAwesomeIcon icon={faEnvelope} />
            <CopyToClipboardButton content={email}>
                <Tooltip content={marketingConsentRevoked ? <Translate id={'REVOKED_MARKETING_CONSENT_EMAIL'}/> : null}>
                    {showLink('mailto', email, onClick)}
                </Tooltip>
            </CopyToClipboardButton>
        </div>
    ) : null;
}

function renderIncompleteFormLink(settingsProps: SettingsProps, lang: string | null, publicId: string | undefined, applicationIsComplete: boolean, productType?: ProductType | null, sourceId?: number | null, isCoApplicant?: boolean) {
    if (publicId === undefined || productType === undefined || productType === null || sourceId === undefined || sourceId === null || applicationIsComplete) {
        return null;
    }

    const url = getIncompleteFormLink(settingsProps, lang, publicId, productType, sourceId, isCoApplicant);

    if (url === undefined) {
        return null;
    }

    const onClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => e.stopPropagation();

    return (
        <div style={{ width: '100%' }}>
            <CopyToClipboardButton content={url}>
                <a href={url} style={{ textTransform: 'none' }} onClick={onClickHandler} target="_blank" rel="noreferrer">
                    <span style={{ marginLeft: '3px' }}><FontAwesomeIcon icon={faAddressCard} /></span>
                    <Translate id="INCOMPLETE_FORM_LINK" />
                </a>
            </CopyToClipboardButton>
            {renderDebtInformationUpdateLink(settingsProps, publicId, isCoApplicant || false, onClickHandler)}
        </div>
    );
}

function renderDebtInformationUpdateLink(settingsProps: SettingsProps, publicId: string, isCoApplicant: boolean, onClickHandler: MouseEventHandler<HTMLAnchorElement>) {
    if(isCoApplicant) {
        return null; // not supported yet
    }

    const url = getDebtInformationUpdateLink(settingsProps, publicId);

    if (url) {
        return (
            <CopyToClipboardButton content={url}>
                <a href={url} style={{ textTransform: 'none' }} onClick={onClickHandler} target="_blank" rel="noreferrer">
                    <span style={{ marginLeft: '3px' }}><FontAwesomeIcon icon={faCreditCard} /></span>
                    <Translate id="DEBT_INFORMATION_UPDATE_LINK" />
                </a>
            </CopyToClipboardButton>
        );
    }

    return null;
}

function renderDisplayName(props: ApplicantInformationsProps) {
    const isApplicationComplete = props.loanStatus === LoanStatus.Investigation;

    return (
        <div className="applicant-name">
            {renderName(props.applicant)}
            {renderSocialSecurityNumber(props.applicant.socialSecurityNumber)}
            {props.isCoApplicant ? null : renderBisnodeIcon(props.applicant, isApplicationComplete, props.useCreditScoreSettings === true)}
            {renderCoApplicantMarker(props.isCoApplicant)}
        </div>
    );
}

function renderName(applicant: ConsumerLoanApplicant) {
    if (applicant.firstName === GDPR_GONE) {
        return <Translate id={GDPR_GONE} />;
    }

    if ((applicant.firstName === '' || applicant.firstName === null) && (applicant.lastName === '' || applicant.lastName === null)) {
        return <Translate id="NO_DATA" />;
    }

    return (
        <CopyToClipboardButton content={`${applicant.firstName} ${applicant.lastName}`}>
            <span className="uppercase vamiddle">{applicant.firstName} {applicant.lastName}</span>
        </CopyToClipboardButton>
    );
}

function renderSocialSecurityNumber(ssn: string) {
    return ssn && ssn !== GDPR_GONE ? (
        <CopyToClipboardButton content={ssn}>
            <span className="vamiddle">{ssn && ` (${ssn})`}</span>
        </CopyToClipboardButton>
    ) : null;
}

function showLink(prefix: string, value: string, onClick?: (value: string) => void) {
    const onClickHandler: MouseEventHandler<HTMLAnchorElement> = (e) => {
        if (onClick !== undefined) {
            onClick(value);
        }

        if (prefix === 'tel' || prefix === 'mailto') {
            e.preventDefault();
        }

        e.stopPropagation();
    };

    return <a href={`${prefix}:${value}`} onClick={onClickHandler}>{value}</a>;
}

function renderCoApplicantMarker(isCoApplicant?: boolean) {
    return isCoApplicant ? (<span className="co-applicant-marker"><Translate id="COAPPLICANT" /></span>) : null;
}

function renderCivilStatus(civilStatus: string) {
    return civilStatus ? (<div><Translate id={getCivilStatusTranslateKey(civilStatus)} /></div>) : null;
}

function renderCreditAndDebtInformation(applicant: ConsumerLoanApplicant, onCreditInformationClick?: () => void) {
    return (applicant.creditScore === null && applicant.numberOfDebtCollections === null) ? null : (
        <>
            {renderInformationValue(applicant.creditScore, faCreditCard, onCreditInformationClick)}
            {renderInformationValue(applicant.numberOfDebtCollections, faGavel, onCreditInformationClick)}
        </>
    );
}

function renderBisnodeIcon(applicant: ConsumerLoanApplicant, isApplicationComplete: boolean, useCreditScoreSettings: boolean) {
    if (!isApplicationComplete || !useCreditScoreSettings) {
        return null;
    }

    if (applicant.creditScore === null && applicant.numberOfDebtCollections === null) {
        return (
            <span style={{ verticalAlign: 'middle', marginLeft: '10px' }}>
                <FontAwesomeIcon icon={faCog} spin={true} />
            </span>
        );
    }

    return (<BisnodeIcon />);
}

function renderInformationValue(value: number | undefined | null, icon: IconDefinition, onCreditInformationClick?: () => void) {
    const onClickHandler: MouseEventHandler<HTMLDivElement> = (e) => {
        if (onCreditInformationClick !== undefined) {
            onCreditInformationClick();
        }

        e.stopPropagation();
    };
    
    return value !== undefined ? (
            <Tooltip
                content={<Translate id="SHOW_CREDIT_INFORMATION"/>}
                placement="top">
                <div className="credit-info" onClick={onClickHandler}>
                    <FontAwesomeIcon icon={icon}/>
                    <span>{value}</span>
                </div>
            </Tooltip>
        ) : null;
}

function renderAddress(applicant: ConsumerLoanApplicant) {
    if (!applicant.streetAddress && !applicant.postalCode && !applicant.city) {
        return null;
    }

    return (
        <div className="address">
            <FontAwesomeIcon icon={faHome} />
            <span>{applicant.streetAddress}, {applicant.postalCode} {applicant.city}</span>
        </div>
    );
}

function renderLanguageInfo(language: string | null, props: ApplicantInformationsProps) {
    if (!language) {
        return null;
    }
    return (
      <div className="language-info">
          <Tooltip 
              content={
                  <Translate 
                      id="CUSTOMER_PRIMARY_LANGUAGE" 
                      data={{ customerName: props.applicant.firstName || props.translate('CUSTOMER'), language: props.translate(`LANGUAGE.${language.toUpperCase()}`) }} 
                  />
              } 
              placement="top">
                <FontAwesomeIcon icon={faMapSigns} />
          </Tooltip>
          <span>{language.toUpperCase()}</span>
      </div>  
    );
}

const mapStateToProps = (state: any) => ({ ...state.settingsActionsReducer });

export default connect<SettingsProps, {}, {}, any>(mapStateToProps)(ApplicantInformation);
