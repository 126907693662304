import React, { FunctionComponent } from 'react';
import { Translate } from 'react-localize-redux';
import { Redemption } from '../models/Redemption';
import { faBuilding, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './property-information.css';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import { ConsumerLoanDetails } from "../models/ConsumerLoanDetails";
import {ProductType} from "../../../common/models/ProductType";
import {calculateLTV, calculateMortgageLoan} from "../helpers/calculators";
import { PropertiesSummary } from '../models/PropertiesSummary';
import NumberFormatter from "../../../common/components/NumberFormatter";

interface PropertyDetailsInformationsProps {
    property: PropertiesSummary | null;
    loanDetails: ConsumerLoanDetails;
    applicant: ConsumerLoanApplicant;
    coApplicant?: ConsumerLoanApplicant | null;
    onClick?: () => void;
    redemptions: Redemption[];
    numberOfLoans: number;
}

const GDPR_GONE = 'GDPR_GONE';

const PropertyInformation: FunctionComponent<PropertyDetailsInformationsProps> = (props) => {
    if (props.property == null || props.property.count === 0) {
        return null;
    }
    
    const mortgageLoan = calculateMortgageLoan(props.redemptions);
    const notRefinancedMortgageLoan = calculateMortgageLoan(props.redemptions, true);

    const sourceLTV = calculateLTV(mortgageLoan, props.property.totalCommonDebt, props.property.totalEstimatedValue);
    const targetLTV = calculateLTV(notRefinancedMortgageLoan + props.loanDetails.loanAmount, props.property.totalCommonDebt, props.property.totalEstimatedValue);

    return (
        <div className={`property-information${getExtraCssClass(props.numberOfLoans, !!props.coApplicant)}`}>
            
                <div className="property-title" onClick={props.onClick}><FontAwesomeIcon icon={faBuilding}/> <Translate id="PROPERTY.TITLE" /></div>
            
            <div className="details">
            <div className="value">
                    {renderValue(props.property.count)}
                    <div className="description"><Translate id="PROPERTY.COUNT" /></div>
                </div>
                <div className="value">
                    {renderPrice(props.property.totalEstimatedValue)}
                    <div className="description"><Translate id="PROPERTY.ESTIMATED_VALUE" /></div>
                </div>
                <div className="value">
                    {renderPercentage((sourceLTV * 100).toFixed(1))}
                    {targetLTV !== Infinity && props.loanDetails.productType === ProductType.Mortgage &&
                        <FontAwesomeIcon style={{margin: '0 4px'}} icon={faLongArrowAltRight}/>
                    }
                    {targetLTV !== Infinity && props.loanDetails.productType === ProductType.Mortgage &&
                        <span style={{color: targetLTV > 0.85 ? '#F05B59' : '#42D253'}}>
                            {renderPercentage((targetLTV * 100).toFixed(1))}
                        </span>
                    }
                    <div className="description"><Translate id="PROPERTY.LTV" /></div>
                </div>
            </div>
        </div>
    );
};

function renderValue(value: string | number | null | undefined, unit?: string) {
    if (value === GDPR_GONE) {
        return <Translate id={GDPR_GONE} />;
    }

    if (value === undefined || value === null || value === '' || value === 0 || value === Infinity) {
        value = '-';
    } else if (unit) {
        value += ' ' + unit;
    }

    return (<span className="uppercase vamiddle">{value}</span>);
}

function renderPercentage(value: string | number | undefined) {
    if (value === GDPR_GONE) {
        return <Translate id={GDPR_GONE} />;
    }
    value = parseFloat(value as any);

    if (!isFinite(value) || isNaN(value)) {
        value = '-';
    }

    return (<span className="uppercase vamiddle">{value}%</span>);
}

function renderPrice(value: number | null | undefined) {
    if (value === undefined || value === null || value === 0) {
        value = 0;
    }

    return (<span className="vamiddle"><NumberFormatter value={value}/></span>);
}

function getExtraCssClass(numberOfApplications: number, hasCooaplicant: boolean) {
    if (numberOfApplications === 4) {
        return ' right-4';
    }

    if (numberOfApplications >= 5) {
        if (hasCooaplicant) {
            return ' left-minus';
        }

        return ' right-5';
    }

    return '';
}

export default PropertyInformation;
