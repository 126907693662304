import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CommandResult } from '../../../common/helpers/CommandResult';

export const sendToBanks = async (personId: string | number, loanId: string | number, bankIds: number[], skipInvalid: boolean): Promise<CommandResult> => {
    const result = await AxiosClientFactory
        .getInstance()
        .post(`/api/applicants/${personId}/consumerLoans/${loanId}/sendtobanks`, { bankIds, skipInvalid });

    return new CommandResult(result);
};


