import { User } from "./User";

export enum InsuranceHistoryType {
    Created = 0,
    Change = 1,
    Invoice = 2,
    Report = 3
}

export enum InsuranceReportItemType
{
    NewBusiness = 0,
    PremiumPayment = 1,
    Cancellation = 2
}

export interface InsuranceHistoryReadModel {
    historyType: InsuranceHistoryType;
    timestamp: Date;
}

export interface ChangeInsuranceHistoryReadModel extends InsuranceHistoryReadModel {
    propertyName: string;
    user: User;
    oldValue?: string;
    newValue?: string;
}

export interface InvoiceInsuranceHistoryReadModel extends InsuranceHistoryReadModel {
    number: string;
    dueDate: Date;
    balance: number;
}

export interface ReportItemInsuranceHistoryReadModel extends InsuranceHistoryReadModel {
    itemType: InsuranceReportItemType;
    invoiceNumber: string;
}