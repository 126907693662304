import { ProductType } from "../../models/ProductType";
import React, {FunctionComponent} from "react";
import {faCreditCard, faHouse, faMoneyBills} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../Tooltip";
import {Translate} from "react-localize-redux";
import {getEnumTranslationKey} from "../../helpers/getTranslationKey";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ProductCellProps {
    product?: ProductType;
}
const ProductCell: FunctionComponent<ProductCellProps> = (props) => {
    let icon = faMoneyBills;
    switch (props.product) {
        case ProductType.CreditCard:
            icon = faCreditCard;
            break;
        case ProductType.Mortgage:
            icon = faHouse;
            break;
    }
    return (
        <Tooltip content={<Translate id={getEnumTranslationKey(ProductType, props.product, 'PRODUCT_TYPES')} />}>
            <FontAwesomeIcon icon={icon} style={{ color: '#707070', padding: '0 20px' }} />
        </Tooltip>
    )
}
export default ProductCell;