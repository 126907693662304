import React, {FunctionComponent} from "react";
import {
    CustomersFilterProps,
    getFilterProp,
    renderLoanStatusFilter,
    renderBooleanInputFieldFilter,
    renderCustomerAdvisorsFilter,
    renderCustomerStatusesFilter,
    renderDateTimeInputFieldFilter,
    renderStringCollectionInputFilter,
    renderSubstatusesInputFieldFilter,
    renderLastStatusChangeFromFilter,
    renderLastStatusChangeToFilter,
    renderLastStatusChangeFilter,
    renderPaidAmountFromFilter,
    renderPaidAmountToFilter,
    renderNominalInterestFromFilter,
    renderNominalInterestToFilter,
    renderUnsecuredDebtsFromFilter,
    renderUnsecuredDebtsToFilter,
    renderHasClawbackFilter,
    renderLastContactFilter,
    renderMinimumGrossYearlyIncomeFilter,
    renderPropertyEstimatedValueFilter,
    renderPropertyAreaMarketScoreFilter
} from "./CustomersFilterContainer";
import {ProductType} from "../../../../common/models/ProductType";
import {getEnumTranslationKey} from "../../../../common/helpers/getTranslationKey";
import TextInputField from "../../../../common/components/input-fields/TextInputField";

const CustomersFilter: FunctionComponent<CustomersFilterProps> = (props) => (
    <>
        <div className="readonly">
            {renderLoanStatusFilter(props)}
            {renderLanguagesFilter(props)}
            {renderProductTypesFilter(props)}
            {renderIncludedSubstatusesFilter(props)}
            {renderExcludedSubstatusesFilter(props)}
            {renderMobileNumbersFilter(props)}
            {renderSkipActiveFollowUpsFilter(props)}
            {renderSubmittedFromFilter(props)}
            {renderSubmittedToFilter(props)}
            {renderLastStatusChangeFromFilter(props)}
            {renderLastStatusChangeToFilter(props)}
            {renderLastStatusChangeFilter(props)}
            {renderLastContactFilter(props)}
            {renderPaidAmountFromFilter(props)}
            {renderPaidAmountToFilter(props)}
            {renderNominalInterestFromFilter(props)}
            {renderNominalInterestToFilter(props)}
            {renderHasClawbackFilter(props)}
            {renderExcludeInkassoFilter(props)}
            {renderHasPropertyFilter(props)}
            {renderPropertyEstimatedValueFilter(props)}
            {renderPropertyAreaMarketScoreFilter(props)}
            {renderMinimumGrossYearlyIncomeFilter(props)}
            {renderUnsecuredDebtsFromFilter(props)}
            {renderUnsecuredDebtsToFilter(props)}
        </div>
        <div 
            className="edit" 
            style={{marginTop: '12px'}}
        >
            {renderCustomerAdvisorsFilter(props)}
            {renderCustomerStatusesFilter(props)}
        </div>
    </>
);

const renderLanguagesFilter = (props: CustomersFilterProps) => {
    const value = getFilterProp<string[]>('languages', props);
    if (!value || !value.length) {
        return null;
    }

    const languages = value.map(language => props.translate('LANGUAGE.' + language.toUpperCase()).toString()).join(', ');
    return renderTextInputField(languages, 'LANGUAGE.LABEL');
};

const renderProductTypesFilter = (props: CustomersFilterProps) => {
    const value = getFilterProp<number[]>('productType', props);
    if (!value || !value.length) {
        return null;
    }

    const productTypes = value.map(productType => props.translate(getEnumTranslationKey(ProductType, productType, 'PRODUCT_TYPES')).toString()).join(', ');
    return renderTextInputField(productTypes, 'PRODUCT_TYPES.LABEL');
};

const renderIncludedSubstatusesFilter = (props: CustomersFilterProps) =>
    renderSubstatusesInputFieldFilter('includedSubstatuses', 'INCLUDED_SUBSTATUSES', props);

const renderExcludedSubstatusesFilter = (props: CustomersFilterProps) =>
    renderSubstatusesInputFieldFilter('excludedSubstatuses', 'EXCLUDED_SUBSTATUSES', props);

const renderSkipActiveFollowUpsFilter = (props: CustomersFilterProps) =>
    renderDateTimeInputFieldFilter('skipActiveFollowUps', 'CAMPAIGNS_VIEW.SKIP_ACTIVE_FOLLOW_UPS', props);

const renderSubmittedFromFilter = (props: CustomersFilterProps) =>
    renderDateTimeInputFieldFilter('submittedFrom', 'SUBMITTED_FROM', props);

const renderSubmittedToFilter = (props: CustomersFilterProps) =>
    renderDateTimeInputFieldFilter('submittedTo', 'SUBMITTED_TO', props);

const renderExcludeInkassoFilter = (props: CustomersFilterProps) =>
    renderBooleanInputFieldFilter('excludeInkasso', 'CAMPAIGNS_VIEW.EXCLUDE_INKASSO', props);

const renderHasPropertyFilter = (props: CustomersFilterProps) =>
    renderBooleanInputFieldFilter('hasProperty', 'PROPERTY_REQUIRED', props);

const renderMobileNumbersFilter = (props: CustomersFilterProps) =>
    renderStringCollectionInputFilter('mobileNumbers', 'MOBILE_NUMBERS', props);

const renderTextInputField = (value: string, descriptionKey: string) => (
    <div>
        <TextInputField
            name={descriptionKey}
            value={value}
            descriptionKey={descriptionKey}
        />
    </div>
);

export default CustomersFilter;