import React, { FunctionComponent, useState, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Translate } from 'react-localize-redux';

type Action = () => void;

interface ConfirmationModalProps {
    show: boolean;
    title: string | ReactNode;
    message: string | ReactNode;
    onConfirm: Action;
    onCancel: Action;
}

interface ConfirmationButtonProps {
    onClick: Action | undefined;
}

interface CancellationButtonProps {
    onClick: Action;
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = (props) => (
    <Modal show={props.show} centered={true}>
        <Modal.Header>
            <Modal.Title>
                {props.title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {props.message}
        </Modal.Body>
        <Modal.Footer>
            <ConfirmationButton onClick={props.onConfirm} />
            <CancellationButton onClick={props.onCancel} />
        </Modal.Footer>
    </Modal>
);

const ConfirmationButton: FunctionComponent<ConfirmationButtonProps> = (props) => (
    <Button variant="primary" onClick={props.onClick}>
        <Translate id="CONFIRM" />
    </Button>
);

const CancellationButton: FunctionComponent<CancellationButtonProps> = (props) => (
    <Button variant="secondary" onClick={props.onClick}>
        <Translate id="CANCEL" />
    </Button>
);

export function useConfirmationModal(title: string | ReactNode, message: string | ReactNode, onConfirm: Action, onCancel?: Action): [ConfirmationModalProps, () => void] {
    const [show, setShow] = useState(false);
    const onConfirmCallback = () => {
        setShow(false);
        onConfirm();
    };

    const onCancelCallback = () => {
        setShow(false);
        if (onCancel) {
            onCancel();
        }
    };

    return [{
        show,
        title,
        message,
        onConfirm: onConfirmCallback,
        onCancel: onCancelCallback
    }, () => setShow(true)];
}

export default ConfirmationModal;