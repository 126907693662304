import React, {FunctionComponent, MouseEventHandler, ReactNode, useRef, useState} from "react";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import TextInputField from "../../input-fields/TextInputField";
import {Translate} from "react-localize-redux";
import Tooltip from "../../Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCommentMedical} from "@fortawesome/free-solid-svg-icons";

interface AddCommentActionProps {
    trigger?: (ref: React.Ref<any>, onClick: MouseEventHandler) => ReactNode;
    onSave: (comment: string) => void;
}
const AddCommentAction: FunctionComponent<AddCommentActionProps> = (props) => {
    const [comment, setComment] = useState<string | undefined>();
    // retrieve toggle handler to close overlay after popover button click
    const toggleOverlay = useRef<() => void>(() => {/*no-op*/ });

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Popover>
                    <Popover.Body onClick={e => e.stopPropagation()}>
                        <TextInputField
                            name="customer-comment"
                            value={comment}
                            onValueChanged={setComment}
                            editMode={true}
                            rows={2}
                        />
                        <Button
                            disabled={!comment}
                            size="sm"
                            style={{ width: '100%', marginTop: '8px' }}
                            onClick={() => {
                                props.onSave(comment!);
                                toggleOverlay.current();
                            }}                        >
                            <Translate id="SAVE" />
                        </Button>
                    </Popover.Body>
                </Popover>
            }
            trigger="click"
            rootClose={true}
            onToggle={() => setComment(undefined)}
        >
            {/*render overlay target by function to get OT props with toggle handler*/}
            {/*see https://react-bootstrap.netlify.app/docs/components/overlays/#customizing-trigger-behavior*/}
            {
                overlayProps => {
                    let toggleOverlayHandler = (overlayProps as any).onClick;
                    toggleOverlay.current = toggleOverlayHandler;
                    return (
                        props.trigger ? props.trigger(overlayProps.ref, toggleOverlayHandler) : (
                            <Tooltip content={<Translate id="ADD_COMMENT" />}>
                                <FontAwesomeIcon
                                    ref={overlayProps.ref}
                                    icon={faCommentMedical}
                                    onClick={toggleOverlayHandler}
                                />
                            </Tooltip>
                        )                            
                    )
                }
            }
        </OverlayTrigger>
    )
}
export default AddCommentAction;