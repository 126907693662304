import {CommentNotification} from "../../applications/applicant/models/CommentNotification";

export enum TemplatePostActionType {
    ADD_SUBSTATUS = 'add-substatus',
    REMOVE_SUBSTATUS = 'remove-substatus',
    SEND_SMS = 'send-sms',
    SEND_EMAIL = 'send-email',
    SEND_EMAIL_AND_SMS = 'send-email-and-sms',
    DENY_APPLICATION = 'deny-application'
}
export interface TemplatePostAction {
    type: TemplatePostActionType;
    data?: any;
}
export interface CommentTemplate {
    name: string;
    content: string;
    teamId?: number;
    userIds?: number[];
    notification?: CommentNotification;
    actions?: TemplatePostAction[]
}