import React, {FunctionComponent, useEffect, useState} from "react";
import "./string-collection-input-field.css";
import {Dropdown} from "react-bootstrap";
import TextInputField from "./TextInputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
import {ValidationProps} from "../../helpers/useValidation";

interface StringCollectionInputFieldProps extends LocalizeContextProps, ValidationProps {
    value: string[] | null | undefined;
    name: string;
    description?: string; // or translation key
    edit?: boolean;
    rows?: number;
    className?: string;
    onValueChange?: (value: string[]) => void;
}
const StringCollectionInputField: FunctionComponent<StringCollectionInputFieldProps> = (props) => {
    const [valueStr, setValueStr] = useState<string | undefined>();
    
    useEffect(() => {
        setValueStr(props.edit ? props.value?.join('\n') : props.value?.join(', '));
    }, [props.edit, props.value]);
    
    const onValueChanged = (valueStr: string) => {
        let value: string[] = [];
        if (valueStr) {
            value = valueStr.split('\n');
        }
        props.onValueChange && props.onValueChange(value);
    }
    
    return (
        <Dropdown drop="down" className="string-collection-input-field">
            <Dropdown.Toggle>
                <TextInputField
                    className="text-field"
                    name={props.name}
                    value={`${props.value?.filter(v => !!v).length || 0} ${props.translate('SELECTED')}`}
                    editMode={props.edit}
                    descriptionKey={props.description}
                    errors={props.errors}
                    errorCodes={props.errorCodes}
                    afterContent={!props.edit ? <FontAwesomeIcon style={{marginLeft: '4px'}} icon={faQuestionCircle}/> : null}
                    onValueChanged={() => {/* noop */}}
                />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {props.edit ?
                    <TextInputField
                        name={props.name}
                        value={valueStr}
                        editMode={true}
                        rows={props.rows}
                        onValueChanged={onValueChanged}
                    /> : valueStr
                }
            </Dropdown.Menu>
        </Dropdown>
    );
}

StringCollectionInputField.defaultProps = {
    rows: 4
}
export default withLocalize(StringCollectionInputField);