import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, ReactNode } from 'react';
import { Translate } from 'react-localize-redux';
import CurrentPageView from '../../../../common/components/CurrentPageView';
import PagePicker from '../../../../common/components/PagePicker';
import PageSizePicker from '../../../../common/components/PageSizePicker';
import { ConsumerLoanLog } from '../../models/ConsumerLoanLog';
import LogsAndCommentsRow from './LogsAndCommentsRow';
import { preventDoubleClickSelection } from '../../../../common/helpers/preventDoubleClickSelection';

interface LogsAndCommentsTableProps {
    logs: ConsumerLoanLog[];
    logCount: number;
    pageSize: number;
    pageNumber: number;
    orderDirection: number;
    minRowCount?: number;
    onOrderDirectionChanged?: (orderDirection: number) => void;
    onPageSizePicked?: (pageSize: number) => void;
    onPagePicked?: (pageNumber: number) => void;
    onRowDoubleClick?: (log: ConsumerLoanLog) => void;
}

const LogsAndCommentsTable: FunctionComponent<LogsAndCommentsTableProps> = (props) => {
    const renderTable = () => {
        const onHeaderClicked = () => props.onOrderDirectionChanged && props.onOrderDirectionChanged((props.orderDirection + 1) % 2);

        const sortIcon = props.orderDirection === 0 ? faSortUp : faSortDown;

        return (
            <table className="logs-and-comments-table">
                <thead>
                    <tr>
                        <th colSpan={2}><Translate id="ENTRY" /></th>
                        <th className="cursor-pointer" onClick={onHeaderClicked}><Translate id="DATE" /><FontAwesomeIcon icon={sortIcon} className="ms-1" /></th>
                    </tr>
                </thead>
                <tbody>
                    {renderRows()}
                </tbody>
            </table>
        );
    };

    const renderRows = () => {
        const rows: ReactNode[] = [];
        props.logs.forEach((log) => {
            const onDoubleClick = () => props.onRowDoubleClick && props.onRowDoubleClick(log);
            rows.push(
                <LogsAndCommentsRow 
                    key={`log-${log.logType}-${log.id}`} 
                    log={log} 
                    onDoubleClick={onDoubleClick}
                />
            );
        });

        if (props.minRowCount !== undefined) {
            while (rows.length < props.minRowCount) { rows.push(<tr key={`log-${rows.length}`}><td colSpan={3} /></tr>); }
        }

        return rows;
    };

    return (
        <div className="logs-and-comments-wrapper">
            <div onMouseDown={preventDoubleClickSelection}>
                {renderTable()}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <CurrentPageView
                        variant="light"
                        count={props.logCount}
                        pageSize={props.pageSize}
                        currentPage={props.pageNumber}
                    />
                    {getPageSizePicker(props)}
                </div>
                <div>{getPagePicker(props)}</div>
            </div>
        </div>
    );
};

const getPageSizePicker = (props: LogsAndCommentsTableProps) =>
    props.onPageSizePicked ?
    (
    <PageSizePicker
        variant="light"
        pageSize={props.pageSize}
        onPageSizePicked={props.onPageSizePicked}
        pickableSizes={[10, 25, 50]}
    />
    )
    : undefined;

const getPagePicker = (props: LogsAndCommentsTableProps) =>
    props.onPagePicked ?
    (
    <PagePicker
        variant="light"
        count={props.logCount}
        pageSize={props.pageSize}
        onPagePicked={props.onPagePicked}
        pageNumber={props.pageNumber}
    />
    )
    : undefined;
export default LogsAndCommentsTable;
