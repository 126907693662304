import InputField, {InputFieldProps} from "./InputField";
import Autosuggest, {RenderSuggestionsContainerParams, SuggestionsFetchRequestedParams} from "react-autosuggest";
import React, {FunctionComponent, ReactNode, useEffect, useMemo, useState} from "react";
import {Bank} from "../../../applications/applicant/models/Bank";
import {useValidation, ValidationProps} from "../../helpers/useValidation";
import {connect} from "react-redux";
import {MetadataProps} from "../../interfaces/MetadataProps";
import {isValueSet} from "../../helpers/isValueSet";

interface BankInputFieldProps extends Omit<InputFieldProps, 'placeholderKey' | 'afterContent' | 'wrapperProps' | 'wrapper'>, ValidationProps {
    value: Bank | Bank['id'] | Bank['name'] | null | undefined;
    onValueChange?: (bank: Bank) => void;
    onCustomInput?: (name: string) => void;
}
const BanksInputField: FunctionComponent<BankInputFieldProps & MetadataProps> = (props) => {
    const [bankSuggestions, setBankSuggestions] = useState<Bank[]>([]);
    const [errorCode, internalError, ref, onTouched] = useValidation(props);
    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        if (props.onCustomInput) {
            setSearchValue(props.value as string);
        } else {
            setSearchValue(getBankLabel(props.value));
        }
    }, [props.value]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const inputProps = useMemo(() => ({
        value: searchValue,
        onChange: (_, {newValue}) => {
            onTouched();
            setSearchValue(newValue);
            props.onCustomInput?.(newValue);
            const bank = getBankObject(newValue);
            if (bank) {
                props.onValueChange?.(bank);
            }
        },
        className: 'form-control'
    }), [searchValue, props.onCustomInput, props.onValueChange]); // eslint-disable-line react-hooks/exhaustive-deps 


    const getBankObject = (bank: Bank | Bank['id'] | Bank['name'] | null | undefined) => {
        if (isBankObject(bank)) {
            return bank;
        }
        return bankSuggestions.find(b => b.id === bank || b.name === bank);
    }

    const getBankLabel = (bank: Bank | Bank['id'] | Bank['name'] | null | undefined) => {
        if (isBankObject(bank)) {
            return bank.name;
        }
        return getBankObject(bank)?.name || '';
    }
    
    const handleSuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams, banks: Bank[]) => {
        if (request.reason === 'input-changed') {
            const filter = request.value.toLocaleLowerCase();
            setBankSuggestions(filterSuggestions(banks, filter));
        }
    }
    
    const filterSuggestions = (banks: Bank[], filter: string) => {
        return banks
            .filter((b) => b.name.toLocaleLowerCase().includes(filter));
    }
    const onSuggestionsFetchRequested = (request: SuggestionsFetchRequestedParams) => handleSuggestionsFetchRequested(request, props.metadata.banks);
    const onSuggestionsClearRequested = () => setBankSuggestions([]);
    const renderInputComponent = (p) => <input {...p} ref={ref} />;

    if (!props.editMode) {
        return (
            <>
                {getBankLabel(props.value)}
            </>
        )
    }
    
    return (
        <InputField
            className={props.className}
            description={props.description}    
            descriptionKey={props.descriptionKey} 
            editMode={true} 
            error={internalError} 
            errorCode={errorCode}
            errorCodesData={props.errorCodesData}
        >
            <Autosuggest
                focusInputOnSuggestionClick={false}
                suggestions={bankSuggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getBankLabel}
                renderSuggestion={renderSuggestion}
                renderSuggestionsContainer={renderSuggestionsContainer}
                renderInputComponent={renderInputComponent}
                inputProps={inputProps}
            />
        </InputField>
    )
}

function isBankObject(bank: Bank | Bank['id'] | Bank['name'] | null | undefined): bank is Bank {
    return isValueSet(bank) && typeof bank === 'object';
}

function renderSuggestion(bank: Bank) {
    return <div>{bank.name}</div>;
}

function renderSuggestionsContainer(params: RenderSuggestionsContainerParams): ReactNode {
    return (
        <div {...params.containerProps}>
            <div className="suggestion-wrapper">{params.children}</div>
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    ...state.metadataActionsReducer
});

export default connect<MetadataProps, {}, BankInputFieldProps, any>(mapStateToProps)(BanksInputField);