import React, {useState} from "react";
import "./import-bank-data.css";
import {AdministrationModule} from "../AdministrationModule";
import {Button} from "react-bootstrap";
import ImportBankDataScenarios, {importBankDataScenarios} from "./ImportBankDataScenarios";
import {AggregatedBankData, ImportBankDataScenario, ImportBankDataScenarioName, MatchedBankData} from "./model";
import AggregateBankData from "./AggregateBankData";
import AggregatedBankDataPreview from "./AggregatedBankDataPreview";
import MatchedBankDataPreview from "./MatchedBankDataPreview";
import BanksInputField from "../../../../common/components/input-fields/BanksInputField";
import {Bank} from "../../../../applications/applicant/models/Bank";
import {validateBankData} from "../../../api/validateBankData";
import {postBankData} from "../../../api/postBankData";
import Translate_i18next from "../../../../common/components/Translate_i18next";
import {showToastMessage, ShowToastMessageProps} from "../../../../common/actions/ToastMessagesActionCreator";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {useTranslate_i18next} from "../../../../common/useTranslate_i18next";

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    showToastMessage
}, dispatch);

const ImportBankData = connect<{}, ShowToastMessageProps>(null, mapActionCreatorsToProps)((props: ShowToastMessageProps) => {
    const translate_i18next = useTranslate_i18next();
    const [importBankDataScenario, setImportBankDataScenario] = useState<ImportBankDataScenario>(importBankDataScenarios[ImportBankDataScenarioName.CLAWBACKS]);
    const [bank, setBank] = useState<Bank>();
    const [aggregatedBankData, setAggregatedBankData] = useState<AggregatedBankData[]>([]);
    const [matchedBankData, setMatchedBankData] = useState<MatchedBankData[]>([]);
    
    const matchBankData = () => {
        if (!bank || !aggregatedBankData.length) {
            return;
        }
        validateBankData(bank.id, importBankDataScenario.name, aggregatedBankData).then(res => {
            if (res.status === 200) {
                setMatchedBankData(res.data);
            } else {
                props.showToastMessage('error', translate_i18next('FAILED_TO_MATCH_DATA'), '');
            }
        });
    }
    
    const importBankData = () => {
        if (!bank || !matchedBankData.length) {
            return;
        }
        postBankData(bank.id, importBankDataScenario.name, matchedBankData).then(res => {
            const result = res.status === 200 ? 'success' : 'error';
            props.showToastMessage(result, translate_i18next(`IMPORT_BANK_DATA_${result.toUpperCase()}`), '');
            if (result === 'success') {
                setBank(undefined);
                setAggregatedBankData([]);
                setMatchedBankData([]);
            }
        });
    }
    
    const renderMatchButton = () => (
        <Button
            style={{alignSelf: 'end'}}
            disabled={!aggregatedBankData.length || !bank}
            onClick={matchBankData}
        >
            <Translate_i18next id="MATCH"/>
        </Button>
    )
    
    const renderImportButton = () => (
        <Button
            style={{alignSelf: 'end'}}
            disabled={!matchedBankData.some(data => data.personId && data.applicationId)}
            onClick={importBankData}
        >
            <Translate_i18next id="IMPORT_FOR_BANK" data={{bank: bank?.name}}/>
        </Button>
    )
    
    return (
        <div className="import-bank-data">
            {!matchedBankData.length ?
                <>
                    <ImportBankDataScenarios scenario={importBankDataScenario} onScenarioChanged={setImportBankDataScenario}/>
                    <div>
                        <BanksInputField
                            descriptionKey="BANK"
                            value={bank}
                            editMode={!matchedBankData.length}
                            required="RedemptionLenderRequired"
                            onValueChange={setBank}
                        />
                    </div>
                    <AggregateBankData scenario={importBankDataScenario} onDataAggregated={setAggregatedBankData}/>
                </> : null
            }
            {!matchedBankData.length ?
                <AggregatedBankDataPreview scenario={importBankDataScenario} data={aggregatedBankData}/> :
                <MatchedBankDataPreview scenario={importBankDataScenario} data={matchedBankData}/>
            }
            {!matchedBankData.length ?
                renderMatchButton() :
                renderImportButton()
            }
        </div>
    )
})

const ImportBankDataModule: AdministrationModule = {
    render: () => <ImportBankData/>,
    title: <Translate_i18next id="ADMINISTRATION_MODULES.IMPORT_BANK_DATA"/>
}
export default ImportBankDataModule;