import {FunctionComponent, ReactElement} from "react";
import "./administration-tile.css";

interface AdministrationTileProps {
    title: string | ReactElement;
    onTitleClick?: () => void;
}
const AdministrationTile: FunctionComponent<AdministrationTileProps> = (props) => {
    return (
        <div className="administration-tile">
            <div className="title" onClick={props.onTitleClick}>
                {props.title}
            </div>
            <div className="content">
                {props.children}
            </div>
        </div>
    )
}
export default AdministrationTile;