import React, {FunctionComponent, useEffect, useRef} from "react";
import DateTimeInputField, {DateTimeInputFieldKind} from "../input-fields/DateTimeInputField";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-solid-svg-icons";


interface FollowUpCellProps {
    followUpDate?: Date | string | null;
    hasReminder?: boolean;
    editMode?: boolean;
    onChange?: (followUp?: Date | null) => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

const FollowUpCell: FunctionComponent<FollowUpCellProps> = (props) => {
    const cellRef = useRef<HTMLDivElement>(null);
    const { editMode, onFocus, onBlur, hasReminder } = props;

    useEffect(() => {
        const checkFocus = (e: Event) => {
            if (cellRef.current?.contains(e.target as HTMLElement)) {
                onFocus?.();
            } else if (editMode) {
                onBlur?.();
            }
        }
        document.addEventListener('click', checkFocus);
        return () => {
            document.removeEventListener('click', checkFocus);
        }
    }, [editMode, onBlur, onFocus]);
    
    const reminderIcon = <FontAwesomeIcon style={{color: '#707070', opacity: hasReminder ? 1 : 0 }} icon={faBell} />;
    const cellStyle = {
        cursor: 'pointer', 
        display: 'flex',
        alignItems: 'center',
        columnGap: '5px'
    }
    
    return (
        <div ref={cellRef} style={cellStyle}>
            {reminderIcon}
            <DateTimeInputField
                name="followUpDate"
                value={props.followUpDate}
                editMode={props.editMode}
                showToday
                defaultOpen={true}
                kind={DateTimeInputFieldKind.DateWithTime}
                timeFrom={8}
                timeTo={20}
                onValueChanged={props.onChange}
            />
        </div>
    )
}
export default FollowUpCell;