import React, {CSSProperties, FunctionComponent, SetStateAction, useEffect, useState} from 'react';
import {OverlayTrigger, Popover, Table} from 'react-bootstrap';
import {LocalizeContextProps, Translate, withLocalize} from 'react-localize-redux';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import GenericDropdown from '../../../common/components/GenericDropdown';
import GlobalSearch from '../../../common/components/GlobalSearch';
import {MultiSelect} from '../../../common/components/MultiSelect';
import RefreshButton from '../../../common/components/RefreshButton';
import {mapFollowUpsToSelectableItems} from '../../../common/helpers/followUpFunctions';
import {mapLoanStatusesToSelectableItems} from '../../../common/helpers/loanStatusFunctions';
import {mapProductTypesToSelectableItems} from '../../../common/helpers/productTypeFunctions';
import {SelectableItem} from '../../../common/models/SelectableItem';
import {TableState} from '../../../common/models/TableState';
import MetadataActionsCreator from '../../applicant/actions/MetadataActionsCreator';
import {AdvertisementSite} from '../../applicant/models/AdvertisementSite';
import {ApplicationSource} from '../../applicant/models/ApplicationSource';
import {
    ApplicantsViewTableActionCreators,
    ApplicantsViewTableActionProps
} from '../actions/ApplicantsViewDataActionCreators';
import {getApplicants} from '../api/getApplicants';
import {getApplicantsCount} from '../api/getApplicantsCount';
import {Applicant} from '../models/Applicant';
import ApplicantsTableHeader from './ApplicantsTableHeader';
import ApplicantsTableRows from './ApplicantsTableRows';
import './applicantsview.css';
import {SettingsProps} from '../../../common/interfaces/SettingsProps';
import {SetApplicationTitleActionProps} from '../../../common/interfaces/SetApplicationTitleActionProps';
import {SetTitleActionCreator} from '../../../common/actions/SetTitleActionCreator';
import {getMainBackgroundColorStyle} from '../../../common/helpers/settingsHelpers';
import {MetadataProps} from '../../../common/interfaces/MetadataProps';
import SubstatusesFilter from './SubstatusesFilter';
import qs from 'qs';
import {getLanguageItems, LanguageItem} from '../../../common/helpers/getLanguageItems';
import {faCircle, faFilter, faTag, faUser, faUserTag} from '@fortawesome/free-solid-svg-icons';
import ActionMenu from './ActionMenu';
import ApplicantsTotals from './ApplicantsTotals';
import {ApplicantsTotal} from '../models/ApplicantsTotal';
import {getApplicantsTotals} from '../api/getApplicantsTotals';
import moment from 'moment';
import {FiltersPopover, PopoverFilters} from './FiltersPopover';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ApplicantsTableHeaderModel} from '../models/ApplicantsTableHeaderModel';
import {ApplicantsTableStateProps} from '../../../common/interfaces/ApplicantsTableStateProps';
import {getExcludedTeamItems, getTeamItems} from '../../../common/helpers/getTeamItems';
import {exportApplications as exportApplicationsAPI, exportCustomers as exportCustomersAPI} from '../api/export';
import {File} from '../../../common/helpers/File';
import {ApplicantsFilter} from '../models/ApplicantsFilter';
import TablePagination from "../../../common/components/table/TablePagination";
import {isValueSet} from "../../../common/helpers/isValueSet";
import {UserProps} from "../../../common/interfaces/UserProps";
import {Location, NavigateFunction, useLocation, useNavigate} from 'react-router';
import {CustomerAdvisorsTeamsProps} from '../../../common/interfaces/CustomerAdvisorsTeamsProps';
import CustomerAdvisorsTeamsActionsCreator, {
    CustomerAdvisorsTeamsActions
} from '../actions/CustomerAdvisorsTeamsActionsCreator';
import {ApplicantsGroupBy} from "../models/ApplicantsGroupBy";
import Tooltip from "../../../common/components/Tooltip";
import {getEnumTranslationKey} from '../../../common/helpers/getTranslationKey';
import {ProductType} from "../../../common/models/ProductType";
import ApplicantsFilterBookmarks from "./ApplicantsFilterBookmarks";
import {putFollowUpDate} from "../../applicant/api/putFollowUpDate";
import {handleCommandResult} from "../../../common/helpers/CommandResult";
import {showToastMessage, ShowToastMessageProps} from "../../../common/actions/ToastMessagesActionCreator";
import {LoanStatus} from "../../../common/models/LoanStatus";
import SendEmailAndSmsModal from "../../../common/components/SendEmailAndSmsModal";
import {postComment} from "../../applicant/api/postComment";
import SendSmsModal from "../../../common/components/SendSmsModal";
import SendEmailModal from "../../../common/components/SendEmailModal";
import {putDisableNotifications} from "../api/putDisableNotifications";
import CalendarView from './CalendarView/CalendarView';

const headers: ApplicantsTableHeaderModel[] = [
    { index: 0, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.APPLICANT' },
    { index: 9, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.FORM_PROGRESS', width: 135 },
    { index: 1, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.DATE', width: 131 },
    { index: 2, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.FOLLOW_UP', width: 134 },
    { index: 3, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.PAYMENT_DATE', width: 159 },
    { index: 4, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.LOAN_AMOUNT', width: 159, numeric: true },
    { index: 5, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.GRANTED_AMOUNT', width: 192, numeric: true },
    { index: 6, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.PRODUCT' },
    { index: 7, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.ADVERTISEMENT' },
    { index: 8, translateKey: 'APPLICANTS_VIEW.TABLE_HEADERS.STATUS' }
];

export type ApplicantsViewFilter = TableState & ApplicantsFilter;

export type ApplicantsViewProps = LocalizeContextProps & SettingsProps & MetadataProps & ApplicantsTableStateProps & 
                                    UserProps & CustomerAdvisorsTeamsProps & CustomerAdvisorsTeamsActions & 
                                    ApplicantsViewTableActionProps & ShowToastMessageProps;

interface ApplicantsViewState {
    applicants: Applicant[];
    count: number;
    isLoadingApplicants: boolean;
    isLoadingCount: boolean;
    totals: ApplicantsTotal[];
}

interface AdvertisementItem {
    id: number;
    name: string;
    type: 'AdvertisementSite' | 'Advertisement';
}

export const substatusesLoanStatus = -1;

const ApplicantsView: FunctionComponent<ApplicantsViewProps & SetApplicationTitleActionProps> = (props) => {
    const [editApplicantFollowUp, setEditApplicantFollowUp] = useState<Applicant>();
    const [sendSmsApplicant, setSendSmsApplicant] = useState<Applicant>();
    const [sendEmailApplicant, setSendEmailApplicant] = useState<Applicant>();
    const [sendSmsAndEmailApplicant, setSendSmsAndEmailApplicant] = useState<Applicant>();
    
    const [loanStatuses] = useState<SelectableItem<number>[]>([
        ...mapLoanStatusesToSelectableItems(props.translate),
        { id: substatusesLoanStatus, name: props.translate('SHOW_FILTERING_ALTERNATIVES').toString() }
    ]);
    const [followUps] = useState<SelectableItem<number>[]>(mapFollowUpsToSelectableItems(props.translate));
    const [productTypes] = useState<SelectableItem<ProductType | 'unsecured-debts'>[]>([
        ...mapProductTypesToSelectableItems(props.translate, true),
        {name: props.translate('PRODUCT_TYPES.PREFIX').toString() + props.translate('PRODUCT_TYPES.UNSECURED_DEBTS').toString(), id: 'unsecured-debts'}
    ]);
    const [languages] = useState<LanguageItem[]>(getLanguageItems(props.translate, true));

    const [advertisementItems, setAdvertisementItems] = useState<AdvertisementItem[] | null>(null);
    const [banks, setBanks] = useState<SelectableItem<number>[] | null>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState<ApplicantsViewState>({
        applicants: [],
        count: 0,
        isLoadingApplicants: true,
        isLoadingCount: true,
        totals: []
    });

    useEffect(() => {
        parseQueryString(props, location, navigate);
        props.setTitle('Applicants');
        refreshData(props, setState);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.customerAdvisorsTeams === undefined) {
            props.loadCustomerAdvisorsTeams();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.isLoadingApplicants || state.isLoadingCount) {
            return;
        }
        parseQueryString(props, location, navigate);
        refreshData(props, setState);
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [
        props.tableState.advertisementSiteIds?.length,
        props.tableState.advertisementIds?.length,
        props.tableState.applicationSourceIds?.length,
        props.tableState.substatusIds?.length,
        props.tableState.bankId,
        props.tableState.followUp,
        props.tableState.loanStatus,
        props.tableState.products,
        props.tableState.orderBy,
        props.tableState.orderDirection,
        props.tableState.pageNumber,
        props.tableState.pageSize,
        props.tableState.pageNumber,
        props.tableState.primaryLanguage,
        props.tableState.teamId,
        props.tableState.excludedTeamId,
        props.tableState.primaryLanguage,
        props.tableState.closeDateFrom,
        props.tableState.closeDateTo,
        props.tableState.followUpDateFrom,
        props.tableState.followUpDateTo,
        props.tableState.propertyRequired,
        props.tableState.amountFrom,
        props.tableState.amountTo,
        props.tableState.refinancedAmountFrom,
        props.tableState.refinancedAmountTo,
        props.tableState.nominalInterestFrom,
        props.tableState.publisherId,
        props.tableState.customerAdvisorsIds,
        props.tableState.groupBy
    ]);
    /* eslint-enable react-hooks/exhaustive-deps */

    useEffect(() => {
        parseQueryString(props, location, navigate);
        if (banks === null && props.metadata.isLoaded) {
            setBanks([{
                id: null,
                name: props.translate('SHOW_ALL').toString()
            }].concat(props.metadata.banks.sort((b1, b2) => b1.name.localeCompare(b2.name)) as any));
        }
        if (advertisementItems === null && props.metadata.isLoaded) {
            setAdvertisementItems(getAdvertisementItems(props.metadata.advertisementSites));
        }
    }, [props.metadata.isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateApplicantFollowUp = async (applicant: Applicant, followUpDate?: Date | null) => {
        applicant.followUpDate = followUpDate;
        const result = await putFollowUpDate(applicant.id, applicant.applicationId, followUpDate);
        handleCommandResult(result, props, 'APPLICANTS_VIEW.FOLLOW_UP_DATE_UPDATE_SUCCESS');
        const isFollowUpFilterSet = isValueSet(props.tableState.followUp) || props.tableState.followUpDateFrom || props.tableState.followUpDateTo;
        const isSortingByFollowUp = props.tableState.orderBy === 2;
        if (result.success && (isFollowUpFilterSet || isSortingByFollowUp)) {
            refreshData(props, setState);
        }
    }

    const updateApplicantNotifications = async (applicant: Applicant, disable: boolean) => {
        applicant.disableNotifications = disable;
        const result = await putDisableNotifications(applicant.id, applicant.applicationId, disable);
        handleCommandResult(result, props, `APPLICANTS_VIEW.${disable ? 'DE' : ''}ACTIVATE_ALERT_SUCCESS`);
    }
    
    const saveApplicantComment = async (applicant: Applicant, comment: string) => {
        const result = await postComment(applicant.id, applicant.applicationId, comment);
        handleCommandResult(result, props, 'SAVE_COMMENT_SUCCESS');
    }
    
    const createNewApplication = (applicant: Applicant) => window.open(`/applicant/${applicant.id}/application/${applicant.applicationId}/copy`, '_blank')

    if (banks === null || advertisementItems === null) {
        return null;
    }

    const verticalTableActionsStyle = {
        float: 'right',
        marginRight: '-48px',
        marginTop: '-20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '42px'
    } as CSSProperties;
    
    const groupByButtonStyle = (groupBy: ApplicantsGroupBy) => ({
        color: (props.tableState.groupBy !== undefined ? props.tableState.groupBy : ApplicantsGroupBy.ByApplicantAndProduct) === groupBy 
            ? '#559AF4' : 'rgb(112, 112, 112)',
        border: 'none'
    });

    const addButtonStyle = {
        float: 'right',
        marginRight: '-40px',
        color: 'rgb(112, 112, 112)'
    } as CSSProperties;

    const filterActionsStyle = {
        position: 'relative',
        float: 'right',
        marginRight: '-30px',
        marginTop: '5px',
        color: 'rgb(112, 112, 112)',
        display: 'flex',
        columnGap: '8px'
    } as CSSProperties;

    const filterModal = (
        <Popover id="filter-popover" className="filter-popover">
            <Popover.Body>
                <FiltersPopover
                    amountFrom={props.tableState.amountFrom}
                    amountTo={props.tableState.amountTo}
                    closeDateFrom={props.tableState.closeDateFrom}
                    closeDateTo={props.tableState.closeDateTo}
                    followUpDateFrom={props.tableState.followUpDateFrom}
                    followUpDateTo={props.tableState.followUpDateTo}
                    propertyRequired={props.tableState.propertyRequired}
                    refinancedAmountFrom={props.tableState.refinancedAmountFrom}
                    refinancedAmountTo={props.tableState.refinancedAmountTo}
                    nominalInterestFrom={props.tableState.nominalInterestFrom}
                    primaryLanguage={props.tableState.primaryLanguage}
                    languages={languages}
                    teamId={props.tableState.teamId}
                    excludedTeamId={props.tableState.excludedTeamId}
                    teams={getTeamItems(props.translate, props.metadata.teams)}
                    exclusionTeams={getExcludedTeamItems(props.translate, props.metadata.teams)}
                    publisherId={props.tableState.publisherId}
                    translate={props.translate}
                    userProps={props}
                    customerAdvisorsIds={props.tableState.customerAdvisorsIds}
                    onClearFilters={() => clearFilters(props)}
                    onApplyFilter={filters => applyFilters(props, filters)}
                    onCancel={closeFilterPopover}
                />
            </Popover.Body>
        </Popover>
    );

    const filterClass = 'filter' + (isLimitedView(props, advertisementItems) ? ' limited-view' : '');

    return (
        <main className="main-content" style={getMainBackgroundColorStyle(props)}>
            <div className="jumbotron text-center bg-white flex-column justify-content-center row pb-4 px-0 applicants-view">
                <div className="col-12" style={{ paddingLeft: '50px', paddingRight: '50px' }}>
                    <ApplicantsTotals totals={state.totals} />
                    <div style={addButtonStyle}>
                        <ActionMenu
                            newAppliaction={() => navigate('/applicant/application/new')}
                            exportCustomers={() => exportCustomers(props)}
                            exportApplications={() => exportApplications(props)}
                        />
                    </div>
                    <div className={filterClass}>
                        <GlobalSearch />
                        <GenericDropdown
                            dropdownId="status"
                            selectedItem={loanStatuses.find((i) => i.id === props.tableState.loanStatus)}
                            items={loanStatuses}
                            onSelectionChanged={status => setStatus(props, status)}
                            toggleClassName={getFilterFieldClassName(props.tableState.loanStatus)}
                        />
                        <GenericDropdown
                            dropdownId="followup"
                            selectedItem={followUps.find((i) => i.id === props.tableState.followUp)}
                            items={followUps}
                            onSelectionChanged={followUp => setFollowUp(props, followUp)}
                            toggleClassName={getFilterFieldClassName(props.tableState.followUp)}
                        />
                        <GenericDropdown
                            dropdownId="banks"
                            displayValue={item => getBankDisplayValue(props, item)}
                            selectedItem={banks.find((b) => b.id === props.tableState.bankId)}
                            items={banks}
                            onSelectionChanged={bank => setBank(props, bank)}
                            toggleClassName={getFilterFieldClassName(props.tableState.bankId)}
                        />
                        <GenericDropdown
                            dropdownId="products"
                            selectedItem={productTypes.find((i) => {
                                if (i.id === 'unsecured-debts') {
                                    return props.tableState.products?.includes(ProductType.ConsumerLoan) &&
                                            props.tableState.products?.includes(ProductType.CreditCard);
                                            
                                } else {
                                    return i.id === (props.tableState.products?.length ? props.tableState.products[0] : null);
                                }
                            })}
                            items={productTypes}
                            onSelectionChanged={i => {
                                if (i.id === 'unsecured-debts') {
                                    setProducts(props, [ProductType.ConsumerLoan, ProductType.CreditCard]);
                                } else {
                                    setProducts(props, i.id === null ? [] : [i.id])
                                }
                            }}
                            toggleClassName={getFilterFieldClassName(props.tableState.products ? props.tableState.products[0] : null)}
                        />
                        {props.metadata.applicationSources && props.metadata.applicationSources.length !== 0 &&
                            <MultiSelect
                                selectedItems={getSelectedApplicationSources(props)}
                                items={props.metadata.applicationSources}
                                onSelectionChanged={sources => setApplicationSources(props, sources)}
                                placeHolder={props.translate('HEADER.FILTER_APPLICATION_SOURCE_PLACEHOLDER').toString()}
                                getItemCustomCssClass={getApplicationSourceItemClass}
                                className={getApplicationSourceItemsClassName(props.tableState)}
                            />
                        }
                        {advertisementItems && advertisementItems.length !== 0 &&
                            <MultiSelect
                                selectedItems={getSelectedAdvertisementItems(props, advertisementItems)}
                                items={advertisementItems}
                                onSelectionChanged={sites => setAdvertisementSites(props, sites)}
                                placeHolder={props.translate('HEADER.FILTER_ADVERTISEMENT_SITE_PLACEHOLDER').toString()}
                                getItemCustomCssClass={getAdvertisementItemClass}
                                className={getAdvertisementItemsClassName(props.tableState)}
                            />
                        }
                    </div>
                    <div style={filterActionsStyle}>
                        <ApplicantsFilterBookmarks/>
                        <div>
                            <OverlayTrigger
                                placement="bottom"
                                overlay={filterModal}
                                trigger="click"
                                rootClose={true}
                            >
                                <span className="cursor-pointer"><FontAwesomeIcon icon={faFilter}/></span>
                            </OverlayTrigger>
                            {renderFilterButtonIndicator(props.tableState)}
                        </div>
                    </div>
                </div>
                {renderSubstatusesFilter(props)}
                <hr className="table-separator" />
                <div className="col-12 pt-4 table-container">
                    <div style={verticalTableActionsStyle}>
                        <RefreshButton
                            isLoading={state.isLoadingApplicants || state.isLoadingCount}
                            onClick={() => refreshData(props, setState)}
                        />
                        <hr style={{width: '80%', marginLeft: '10%'}}/>
                        {[
                            ApplicantsGroupBy.ByApplicantAndProduct,
                            ApplicantsGroupBy.None,
                            ApplicantsGroupBy.ByApplicant
                        ].map(groupBy => (
                            <Tooltip
                                key={groupBy}
                                content={
                                    <Translate
                                        id={getEnumTranslationKey(ApplicantsGroupBy, groupBy, 'APPLICANTS_GROUP_BY')}
                                    />
                                }
                            >
                                <button
                                    className="btn"
                                    style={groupByButtonStyle(groupBy)}
                                    onClick={() => setGroupBy(props, groupBy)}
                                >
                                    <FontAwesomeIcon icon={(() => {
                                        switch (groupBy) {
                                            case ApplicantsGroupBy.ByApplicantAndProduct:
                                                return faUserTag;
                                            case ApplicantsGroupBy.ByApplicant:
                                                return faUser;
                                            case ApplicantsGroupBy.None:
                                                return faTag;
                                            default:
                                                return faUserTag;
                                        }
                                    })()}/>
                                </button>
                            </Tooltip>
                        ))}
                        <button
                            className="btn"
                            style={{color: 'rgb(112, 112, 112)', border: 'none'}}
                        >
                            <CalendarView onFilterChange={props.setFilters}/>
                        </button>
                    </div>
                    <Table
                        className="applicants-table"
                        hover={true}
                        borderless={true}
                    >
                        <ApplicantsTableHeader
                            onClick={orderBy => setSorting(props, orderBy)}
                            headers={headers.filter(h => props.tableState.loanStatus !== LoanStatus.Paid ? h.index !== 3 : true)}
                        />
                        <ApplicantsTableRows
                            isLoading={state.isLoadingApplicants || state.isLoadingCount}
                            applicants={state.applicants}
                            teams={props.metadata.teams}
                            editApplicantFollowUp={editApplicantFollowUp}
                            onEditApplicantFollowUpChange={setEditApplicantFollowUp}
                            onApplicantFollowUpChange={updateApplicantFollowUp}
                            onCreateNewApplication={createNewApplication}
                            onApplicantCommentSave={saveApplicantComment}
                            onApplicantNotificationsChange={updateApplicantNotifications}
                            onSendSmsApplicantChange={setSendSmsApplicant}
                            onSendEmailApplicantChange={setSendEmailApplicant}
                            onSendSmsAndEmailApplicantChange={setSendSmsAndEmailApplicant}
                            onClick={(applicantId, applicationId, newTab) => onRowClick(applicantId, applicationId, newTab, navigate)}
                        />
                    </Table>
                </div>
            </div>
            <TablePagination
                isLoading={state.isLoadingApplicants || state.isLoadingCount}
                pageNumber={props.tableState.pageNumber}
                pageSize={props.tableState.pageSize}
                onPagePicked={page => setPage(props, page)}
                onPageSizePicked={pageSize => setPageSize(props, pageSize)}
                count={state.count}
                pickableSizes={[20, 50, 100, 250, 500]}
            />
            {!!sendSmsAndEmailApplicant &&
                <SendEmailAndSmsModal
                    show={true}
                    productType={sendSmsAndEmailApplicant?.product}
                    applicant={sendSmsAndEmailApplicant}
                    applicationId={sendSmsAndEmailApplicant?.applicationId}
                    publicId={sendSmsAndEmailApplicant?.publicId}
                    onClose={() => setSendSmsAndEmailApplicant(undefined)}
                />
            }
            {!!sendSmsApplicant &&
                <SendSmsModal
                    show={true}
                    selectedMobileNumber={sendSmsApplicant.mobileNumber!}
                    productType={sendSmsApplicant?.product}
                    applicant={sendSmsApplicant}
                    applicationId={sendSmsApplicant?.applicationId}
                    publicId={sendSmsApplicant?.publicId}
                    onClose={() => setSendSmsApplicant(undefined)}
                />
            }
            {!!sendEmailApplicant && 
                <SendEmailModal
                    show={true}
                    selectedEmail={sendEmailApplicant.email!}
                    productType={sendEmailApplicant?.product}
                    applicant={sendEmailApplicant}
                    applicationId={sendEmailApplicant?.applicationId}
                    publicId={sendEmailApplicant?.publicId}
                    onClose={() => setSendEmailApplicant(undefined)}
                />
            }
        </main>
    );
}

const setStatus = (props: ApplicantsViewProps, i: SelectableItem<number>) => props.setFilters({ loanStatus: i.id });

const setFollowUp = (props: ApplicantsViewProps, i: SelectableItem<number>) => props.setFilters({ followUp: i.id });

const setBank = (props: ApplicantsViewProps, i: SelectableItem<number>) => props.setFilters({ bankId: i.id });

const setProducts = (props: ApplicantsViewProps, products: ProductType[]) => props.setFilters({ products });

const setAdvertisementSites = (props: ApplicantsViewProps, items: AdvertisementItem[]) => props.setFilters({
    advertisementSiteIds: items.filter((a) => a.type === 'AdvertisementSite').map((i) => i.id),
    advertisementIds: items.filter((a) => a.type === 'Advertisement').map((i) => i.id)
});

const setApplicationSources = (props: ApplicantsViewProps, applicationSources: ApplicationSource[]) => props.setFilters({
    applicationSourceIds: applicationSources.map((i) => i.id)
});

const setPageSize = (props: ApplicantsViewProps, pageSize: number) => props.setFilters({ pageSize });

const setPage = (props: ApplicantsViewProps, pageNumber: number) => props.setFilters({ pageNumber });

const setOrder = (props: ApplicantsViewProps, orderBy: number, orderDirection: number) => props.setFilters({ orderBy, orderDirection });

const setSubstatuses = (props: ApplicantsViewProps, substatusIds: number[]) => props.setFilters({ substatusIds });

const setGroupBy = (props: ApplicantsViewProps, groupBy: ApplicantsGroupBy) => props.setFilters({ groupBy });

const getBankDisplayValue = (props: ApplicantsViewProps, bank: SelectableItem<number>): string => (`${props.translate('BANK_PREFIX').toString()}${bank.name}`);

const getAdvertisementItemClass = (item: AdvertisementItem) => item.type === 'Advertisement' ? 'advertisement-item' : '';

const getApplicationSourceItemClass = () => 'application-source-item';

const isLimitedView = (props: ApplicantsViewProps, advertisementItems: AdvertisementItem[] | null) => !props.metadata.applicationSources || props.metadata.applicationSources.length === 0
    || !advertisementItems || advertisementItems.length === 0;

function applyFilters(props: ApplicantsViewProps, filters: PopoverFilters) {
    props.setFilters(filters);
    closeFilterPopover();
}

function clearFilters(props: ApplicantsViewProps) {
    props.resetTableState();
    closeFilterPopover();
}

function closeFilterPopover() {
    document.body.click();
}

function getSelectedAdvertisementItems(props: ApplicantsViewProps, advertisementItems: AdvertisementItem[] | null) {
    if (advertisementItems === null) {
        return [];
    }

    return advertisementItems
        .filter((as) => (as.type === 'AdvertisementSite' && props.tableState.advertisementSiteIds && props.tableState.advertisementSiteIds.includes(as.id))
            || (as.type === 'Advertisement' && props.tableState.advertisementIds && props.tableState.advertisementIds.includes(as.id)));
}

function getSelectedApplicationSources(props: ApplicantsViewProps) {
    if (props.tableState.applicationSourceIds === undefined) {
        return [];
    }

    return props.metadata.applicationSources.filter((as) => props.tableState.applicationSourceIds?.includes(as.id));
}

function getAdvertisementItemsClassName(tableState: ApplicantsViewFilter) {
    if ((tableState.advertisementIds || []).length > 0 || (tableState.advertisementSiteIds || []).length > 0) {
        return 'filter-active';
    }

    return undefined;
}

function getApplicationSourceItemsClassName(tableState: ApplicantsViewFilter) {
    if ((tableState.applicationSourceIds || []).length > 0) {
        return 'filter-active';
    }

    return undefined;
}

function renderFilterButtonIndicator(tableState: ApplicantsViewFilter) {    
    if (tableState.propertyRequired === true
        || isValueSet(tableState.followUpDateFrom) || isValueSet(tableState.followUpDateTo)
        || isValueSet(tableState.closeDateFrom) || isValueSet(tableState.closeDateTo)
        || isValueSet(tableState.amountFrom) || isValueSet(tableState.amountTo)
        || isValueSet(tableState.refinancedAmountFrom) || isValueSet(tableState.refinancedAmountTo)
        || isValueSet(tableState.nominalInterestFrom) || isValueSet(tableState.primaryLanguage)
        || isValueSet(tableState.teamId) || isValueSet(tableState.excludedTeamId)
        || isValueSet(tableState.publisherId) || (tableState.customerAdvisorsIds?.length || 0) > 0) {
        return <div className="filter-indicator"><FontAwesomeIcon icon={faCircle} /></div>;
    }

    return null;
}

function refreshData(props: ApplicantsViewProps, setState: React.Dispatch<SetStateAction<ApplicantsViewState>>) {
    setState((state) => ({
        ...state,
        isLoadingApplicants: true,
        isLoadingCount: true
    }));

    const filters = getApplicantsFilter(props);

    getApplicants({
        ...filters,
        orderBy: props.tableState.orderBy,
        orderDirection: props.tableState.orderDirection,
        pageNumber: props.tableState.pageNumber,
        pageSize: props.tableState.pageSize
    }).then((applicants) => setState((state) => ({ ...state, applicants, isLoadingApplicants: false })));

    getApplicantsCount(filters).then((count) => setState((state) => ({ ...state, count, isLoadingCount: false })));

    let totalFilters = filters;

    if (!filters.closeDateFrom && !filters.closeDateTo) {
        totalFilters = { ...filters, closeDateFrom: moment().startOf('month').toDate() };
    }

    getApplicantsTotals(totalFilters).then((totals) => setState((state) => ({ ...state, totals })));
}

function getApplicantsFilter(props: ApplicantsViewProps): ApplicantsFilter {
    const tableState = props.tableState;
    const substatusIds = tableState.loanStatus !== null ? tableState.substatusIds : undefined;
    const loanStatus = !tableState.loanStatus || tableState.loanStatus < 0 ? undefined : tableState.loanStatus;
    return {
        followUp: tableState.followUp,
        products: tableState.products,
        bankId: tableState.bankId,
        loanStatus,
        advertisementSiteIds: tableState.advertisementSiteIds,
        advertisementIds: tableState.advertisementIds,
        applicationSourceIds: tableState.applicationSourceIds,
        substatusIds,
        primaryLanguage: tableState.primaryLanguage,
        teamId: tableState.teamId,
        excludedTeamId: tableState.excludedTeamId,
        closeDateFrom: tableState.closeDateFrom,
        closeDateTo: tableState.closeDateTo,
        followUpDateFrom: tableState.followUpDateFrom,
        followUpDateTo: tableState.followUpDateTo,
        propertyRequired: tableState.propertyRequired,
        amountFrom: tableState.amountFrom,
        amountTo: tableState.amountTo,
        refinancedAmountFrom: tableState.refinancedAmountFrom,
        refinancedAmountTo: tableState.refinancedAmountTo,
        nominalInterestFrom: tableState.nominalInterestFrom,
        publisherId: tableState.publisherId,
        customerAdvisorsIds: tableState.customerAdvisorsIds,
        groupBy: tableState.groupBy
    };
}

function onRowClick(applicantId: number, applicationId: number, newTab: boolean, navigate: NavigateFunction) {
    const path = `/applicant/${applicantId}/application/${applicationId}`;

    if (newTab) {
        window.open(path, '_blank');
    } else {
        navigate(path);
    }
}

function setSorting(props: ApplicantsViewProps, orderBy: number) {
    if (orderBy === props.tableState.orderBy && props.tableState.orderDirection === 0) {
        setOrder(props, orderBy, 1);
    } else {
        setOrder(props, orderBy, 0);
    }
}

function renderSubstatusesFilter(props: ApplicantsViewProps) {
    if (props.tableState.loanStatus === null) {
        return null;
    }

    const loanStatusFilter = props.tableState.loanStatus === substatusesLoanStatus ? undefined : props.tableState.loanStatus;

    return (
        <div className="col-12 substatuses">
            <SubstatusesFilter
                selectedSubstatusIds={props.tableState.substatusIds || []}
                onSubstatusesChanged={substatuses => setSubstatuses(props, substatuses)}
                loanStatus={loanStatusFilter}
            />
        </div>
    );
}

function parseQueryString(props: ApplicantsViewProps, location: Location, navigate: NavigateFunction) {
    if (location.search) {
        const pageNumber = qs.parse(location.search, { ignoreQueryPrefix: true }).pageNumber;

        if (pageNumber !== undefined && pageNumber !== null) {
            const pageNumberParsed = Number(pageNumber);
            if (!isNaN(pageNumberParsed) && pageNumberParsed !== props.tableState.pageNumber) {
                setPage(props, pageNumberParsed);
            }

            navigate(location.pathname);
        }
    }
}

function exportCustomers(props: ApplicantsViewProps) {
    exportFile(props, 'Customers', exportCustomersAPI);
}

function exportApplications(props: ApplicantsViewProps) {
    exportFile(props, 'Applications', exportApplicationsAPI);
}

function getAdvertisementItems(advertisementSites: AdvertisementSite[]) {
    let items: AdvertisementItem[] = [];

    for (const as of advertisementSites) {
        items.push({ id: as.id, name: as.name, type: 'AdvertisementSite' });
        items = items.concat(as.advertisements.map((a) => ({ id: a.id, name: `${as.name} - ${a.name}`, type: 'Advertisement' })));
    }

    return items;
}

function getFilterFieldClassName(value: Date | string | number | null | undefined) {
    return isValueSet(value) ? 'filter-active' : undefined;
}

async function exportFile(props: ApplicantsViewProps, name: string, getBlob: (params: ApplicantsFilter) => Promise<Blob>) {
    const fileName = `${name}_${moment().format('YYYYMMDD')}_${moment().format('HHmm')}`;
    const blob = await getBlob(getApplicantsFilter(props));
    new File(fileName, blob).download();
}

const mapStateToProps = (state: any) => ({
    ...state.customerAdvisorsTeamsReducer,
    ...state.applicantsViewDataReducer,
    ...state.settingsActionsReducer,
    ...state.metadataActionsReducer,
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CustomerAdvisorsTeamsActionsCreator,
    ...ApplicantsViewTableActionCreators,
    ...MetadataActionsCreator,
    ...SetTitleActionCreator,
    showToastMessage
}, dispatch);

export default connect<ApplicantsViewProps, SetApplicationTitleActionProps, {}, {}>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(ApplicantsView));
