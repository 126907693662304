import {AggregatedBankData, BankDataEntry, ImportBankDataScenario} from "./model";
import React, {FunctionComponent} from "react";
import {getTranslationKey} from "../../../../common/helpers/getTranslationKey";
import {isValueSet} from "../../../../common/helpers/isValueSet";
import {isDateType, isMultiType} from "./helpers";
import Translate_i18next from "../../../../common/components/Translate_i18next";

interface AggregatedBankDataPreviewProps {
    scenario: ImportBankDataScenario;
    data: AggregatedBankData[];
}
const AggregatedBankDataPreview: FunctionComponent<AggregatedBankDataPreviewProps> = (props) => {
    
    const renderBankDataFieldCell = (data: AggregatedBankData, entry: BankDataEntry) => {
        const value = data[entry.field];
        if (!isValueSet(value)) {
            return null;
        }
        if (isMultiType(value, entry.kind)) {
            return value;
        }
        if (isDateType(value, entry.kind)) {
            return value.toLocaleDateString();
        }
    }
    
    return (
        <table className="aggregated-bank-data">
            <thead>
            <tr>
                {props.scenario.entries.map((entry, id) => (
                    <th key={id}><Translate_i18next id={getTranslationKey(entry.field, 'BANK_DATA_FIELD')}/></th>
                ))}
            </tr>
            </thead>
            <tbody>
            {props.data.map((row, id) => (
                <tr key={id}>
                    {props.scenario.entries.map((entry, index) => (
                        <td key={`${id}${index}`}>
                            {renderBankDataFieldCell(row, entry)}
                        </td>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    )
}
export default AggregatedBankDataPreview;