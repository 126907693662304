import React, { FunctionComponent, useState } from 'react';
import { faBell, faSave, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimeInputField, { DateTimeInputFieldKind } from '../input-fields/DateTimeInputField';
import { Reminder } from '../../models/Reminder';
import './reminders.css';
import Tooltip from '../Tooltip';
import { Translate } from 'react-localize-redux';
import { beautifyLastName } from '../../helpers/beautifyName';

interface ReminderItemProps {
    reminder: Reminder;
    onDateChange: (personId: string | number, applicationId: string | number, date: Date | null) => void;
    onOpen: (personId: string | number, applicationId: string | number) => void;
    onHideItems: () => void;
}

const RemiderItem: FunctionComponent<ReminderItemProps> = (props) => {
    const [reminderDate, setReminderDate] = useState<Date | null>(props.reminder.reminderDate);

    const applicationUrl = `/applicant/${props.reminder.personId}/application/${props.reminder.applicationId}`;

    const onDateChangeHandler = () => props.onDateChange(props.reminder.personId, props.reminder.applicationId, reminderDate);

    const onOpenHandler = () => props.onOpen(props.reminder.personId, props.reminder.applicationId);

    const renderFullName = () => {
        if ((props.reminder.firstName === '' || props.reminder.firstName === null) && (props.reminder.lastName === '' || props.reminder.lastName === null)) {
            return <Translate id="NO_DATA" />;
        }

        return beautifyLastName(`${props.reminder.firstName} ${props.reminder.lastName}`);
    };

    return (
        <div className="reminder-item">
            <div style={{ cursor: 'pointer' }} onClick={props.onHideItems}>
                <Tooltip content={<Translate id="HIDE_REMINDERS" />}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                </Tooltip>
            </div>
            <div className="reminder-icon">
                <FontAwesomeIcon icon={faBell} />
            </div>
            <div className="reminder-customer-data">
                <a href={applicationUrl} target="_blank" rel="noreferrer" onClick={onOpenHandler}>
                    {renderFullName()}
                </a>
            </div>
            <div>
                <DateTimeInputField
                    name="followUpDate"
                    value={reminderDate}
                    showToday
                    editMode={true}
                    onValueChanged={setReminderDate}
                    kind={DateTimeInputFieldKind.DateWithTime}
                    timeFrom={8}
                    timeTo={20}
                    popperPlacement="right-end"
                />
            </div>
            <div className="update-follow-up" onClick={onDateChangeHandler}>
                <FontAwesomeIcon icon={faSave} />
            </div>
        </div>
    );
};

export default RemiderItem;
