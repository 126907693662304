import { NewApplicationState } from '../models/NewApplicationState';
import { NewApplicationAction } from '../actions/newApplicationActionsCreator';
import { ConsumerLoanApplicant } from '../models/ConsumerLoanApplicant';
import { ConsumerLoanApplicantDetails } from '../models/ConsumerLoanApplicantDetails';
import { ConsumerLoanDetails } from '../models/ConsumerLoanDetails';
import { LoanStatus } from '../../../common/models/LoanStatus';
import moment from 'moment';
import { NewApplicationTemplate } from '../models/NewApplicationTemplate';
import {Redemption} from "../models/Redemption";
import { isValueSet } from '../../../common/helpers/isValueSet';

const creditInformationExpirationTimeInHours = 24;

export function newApplicationActionsReducer(state: NewApplicationState, action: NewApplicationAction): NewApplicationState {
    switch (action.type) {
        case 'SET_CONSUMER_LOAN':
            return {
                ...state,
                loanStatus: action.data.loanStatus,
                details: action.data.details,
                applicant: { ...state.applicant, ...action.data.applicant } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                coApplicant: (state.coApplicant || action.data.coApplicant)
                    ? { ...state.coApplicant, ...action.data.coApplicant } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails : undefined,
                redemptions: action.data.redemptions,
                substatusIds: action.data.substatusIds
            };
        case 'COPY_CONSUMER_LOAN':
            return {
                ...state,
                loanStatus: LoanStatus.Incomplete,
                details: action.defaults !== null
                    ? applyDefaults(clearDetailsOnCopy(action.data.details), action.defaults)
                    : clearDetailsOnCopy(action.data.details),
                applicant: {
                    ...state.applicant,
                    ...action.data.applicant,
                    id: 0,
                    ...copyCreditInformation(action.data.applicant, action.data.details.submittedDate)
                } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                coApplicant: (state.coApplicant || action.data.coApplicant) ? {
                    ...state.coApplicant,
                    ...action.data.coApplicant
                } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails : undefined,
                redemptions: action.data.redemptions.map((r) => ({ ...r, id: 0 })),
                substatusIds: action.defaults !== null && isValueSet(action.defaults.substatusId)
                    ? action.data.substatusIds.concat([action.defaults.substatusId]).filter(distinct)
                    : action.data.substatusIds,
                parentId: action.data.id
            };
        case 'SET_APPLICANT_DETAILS':
            return {
                ...state,
                applicant: { ...state.applicant, ...action.data } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails
            };
        case 'COPY_APPLICANT_DETAILS':
            return {
                ...state,
                applicant: { ...state.applicant, ...action.data, ...copyCreditInformation(action.data, action.submittedDate) } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails
            };
        case 'SET_COAPPLICANT_DETAILS':
            return {
                ...state,
                coApplicant: { ...state.applicant, ...action.data } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails
            };
        case 'UPDATE_PRODUCT_TYPE':
            return {
                ...state,
                details: { ...state.details, productType: action.data }
            };
        case 'UPDATE_CONSUMER_LOAN_DETAILS':
            return {
                ...state,
                details: action.data,
                modified: true
            };
        case 'UPDATE_APPLICANT':
            if (state.coApplicant !== undefined && state.coApplicant.livingAtApplicantAddress === true) {
                return {
                    ...state,
                    applicant: { ...state.applicant, ...action.data } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                    coApplicant: {
                        ...state.coApplicant,
                        streetAddress: action.data.streetAddress,
                        postalCode: action.data.postalCode,
                        city: action.data.city
                    } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                    modified: true
                };
            }

            return {
                ...state,
                applicant: { ...state.applicant, ...action.data } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                modified: true,
                ssnChanged: state.ssnChanged || state.applicant.socialSecurityNumber !== action.data.socialSecurityNumber
            };
        case 'UPDATE_COAPPLICANT':
            if (state.coApplicant !== undefined
                && (state.coApplicant.livingAtApplicantAddress === undefined || state.coApplicant.livingAtApplicantAddress === false)
                && action.data.livingAtApplicantAddress === true) {
                return {
                    ...state,
                    coApplicant: {
                        ...state.coApplicant,
                        ...action.data,
                        streetAddress: state.applicant.streetAddress,
                        postalCode: state.applicant.postalCode,
                        city: state.applicant.city
                    } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                    modified: true
                };
            }

            return {
                ...state,
                coApplicant: { ...state.coApplicant, ...action.data } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                modified: true
            };
        case 'UPDATE_REDEMPTIONS':
            return {
                ...state,
                redemptions: action.data,
                modified: true
            };
        case 'SWITCH_APPLICANTS':
            return {
                ...state,
                applicant: (state.coApplicant &&
                {
                    ...state.coApplicant,
                    creditScore: state.coApplicant.creditScore || 0,
                    numberOfDebtCollections: state.coApplicant.numberOfDebtCollections || 0
                }) as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                coApplicant: state.applicant,
                redemptions: state.redemptions.map(r => r.debtOwner === 'shared with co applicant' ? r : switchDebtOwner(r)),
                modified: true,
                ssnChanged: true
            };
        case 'DELETE_COAPPLICANT':
            return {
                ...state,
                coApplicant: undefined,
                modified: true
            };
        case 'ADD_COAPPLICANT':
            return {
                ...state,
                coApplicant: {
                    countryId: action.data.countryId,
                    firstName: '',
                    lastName: '',
                    afterTaxMonthlyIncome: 0
                } as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                modified: true
            };
        case 'ADD_EXISTING_COAPPLICANT':
            return {
                ...state,
                coApplicant: action.data as ConsumerLoanApplicant & ConsumerLoanApplicantDetails,
                modified: true
            };
        case 'SET_ERRORS':
            return {
                ...state,
                errors: action.data
            };
        case 'SET_VALIDATION_STATUS':
            return {
                ...state,
                validationLoanStatus: action.data
            };
        case 'HIDE_DELETE_COAPPLICANT_CONFIRMATION':
            return {
                ...state,
                showCoApplicantDeleteConfirmationModal: false
            };
        case 'SHOW_DELETE_COAPPLICANT_CONFIRMATION':
            return {
                ...state,
                showCoApplicantDeleteConfirmationModal: true
            };
        case 'HIDE_ADD_COAPPLICANT_CONFIRMATION':
            return {
                ...state,
                showCoApplicantAddConfirmationModal: false
            };
        case 'SHOW_ADD_COAPPLICANT_CONFIRMATION':
            return {
                ...state,
                showCoApplicantAddConfirmationModal: true
            };
        case 'SET_SUBSTATUSES':
            return {
                ...state,
                substatusIds: action.substatusIds
            };
        case 'SET_NEW_APPLICATION_DEFAULTS':
            return action.defaults === null ? state : {
                ...state,
                details: applyDefaults({ ...state.details }, action.defaults),
                substatusIds: action.defaults.substatusId ? [action.defaults.substatusId] : []
            };
    }
}

function clearDetailsOnCopy(details: ConsumerLoanDetails) {
    return {
        productType: details.productType,
        bankAccount: details.bankAccount,
        purpose: details.purpose
    } as ConsumerLoanDetails;
}

function applyDefaults(details: ConsumerLoanDetails, defaults: NewApplicationTemplate): ConsumerLoanDetails {
    let newDetails = { ...details };

    if (isValueSet(defaults.advertisementId)) {
        newDetails = { ...newDetails, advertisementId: defaults.advertisementId };
    }

    if (isValueSet(defaults.disableNotifications)) {
        newDetails = { ...newDetails, disableNotifications: defaults.disableNotifications };
    }

    if (isValueSet(defaults.followUpInDays)) {
        newDetails = { ...newDetails, followUpDate: moment().add(defaults.followUpInDays, 'days').toDate() };
    }

    if (isValueSet(defaults.followUpReminder)) {
        newDetails = { ...newDetails, followUpReminder: defaults.followUpReminder };
    }

    if (isValueSet(defaults.sourceId)) {
        newDetails = { ...newDetails, sourceId: defaults.sourceId };
    }

    return newDetails;
}

function distinct<T>(value: T, index: number, self: T[]): boolean {
    return self.indexOf(value) === index;
}

function copyCreditInformation(data: ConsumerLoanApplicant | ConsumerLoanApplicantDetails, submittedDate?: Date) {
    if(submittedDate === undefined
        || submittedDate === null 
        || moment(submittedDate).add(creditInformationExpirationTimeInHours, 'hours').isBefore(moment.now())) {
            return {
                creditScore: null,
                numberOfDebtCollections: null
            };
    }

    return {
        creditScore: data.creditScore,
        numberOfDebtCollections: data.numberOfDebtCollections
    };
}

function switchDebtOwner(r: Redemption): Redemption {
    return {
        ...r,
        debtOwner: r.debtOwner === 'applicant' ? 'co applicant' : 'applicant'
    }
}