import React, {FunctionComponent, useMemo} from "react";
import {connect} from "react-redux";
import {ApplicantDataStateProps} from "../../../models/ApplicantDataProps";
import SubstatusesInputField from "../../../../../common/components/input-fields/SubstatusesInputField";
import {Translate} from "react-localize-redux";

interface AddSubstatusProps extends ApplicantDataStateProps {
    substatusIds: number[];
}
const AddSubstatus: FunctionComponent<AddSubstatusProps> = (props) => {
    const substatusIdsToAdd = useMemo(() => {
        return props.substatusIds.filter(id => !props.currentApplication?.substatusIds.includes(id))
    }, [props.substatusIds]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (!substatusIdsToAdd.length) {
        return null;
    }
    
    return (
        <div className="add-substatus-template-post-action">
            <div className="description"><Translate id="ADD_SUBSTATUSES"/></div>
            <SubstatusesInputField substatusIds={substatusIdsToAdd}/>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.applicantDataReducer
});
export default connect<ApplicantDataStateProps, {}, {}, any>(mapStateToProps)(AddSubstatus);