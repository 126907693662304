import React, {useEffect, useRef} from 'react';
import {history} from '../../index';
import {LocalizeProvider, TranslateFunction} from 'react-localize-redux';
import NavigationPrompt from "../components/NavigationPrompt";
import {createRoot, Root} from "react-dom/client";
import { useStore } from 'react-redux';

export function useNavigationConfirmation(
    active: boolean, 
    translate: TranslateFunction, 
    title?: string, 
    message?: string, 
    onNavigation?: () => void, 
    onCancel?: () => void
) {
    const store = useStore();
    const wasActive = useRef<boolean>(false);
    const unblockNavigation = useRef<() => void>(() => { /* default empty function */ });
    let root: Root | undefined;
    
    const mountRoot = () => {
        const element = document.getElementById('navigation-prompt-container') as HTMLElement;
        if (element) {
            root = createRoot(element);
        }
    }
    
    if (active && !wasActive.current) {
        unblockNavigation.current = history.block(({retry}) => {
            if (root) {
                root.unmount();
            }
            mountRoot();
            root!.render(
                <LocalizeProvider store={store}>
                    <NavigationPrompt
                        title={translate(title || 'UNSAVED_INFORMATION').toString()}
                        message={translate(message || 'UNSAVED_INFORMATION_CONFIRMATION').toString()}
                        callback={(navigate) => {
                                if (navigate) {
                                    onNavigation && onNavigation();
                                    unblockNavigation.current();
                                    retry();
                                } else {
                                    onCancel && onCancel();
                                }
                            }
                        }
                    />
                </LocalizeProvider>
            );
        });
        wasActive.current = true;
    }
    
    useEffect(() => unblockNavigation.current, []);
    
    return unblockNavigation.current;
}
