import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, FunctionComponent, PropsWithChildren } from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';

interface RefreshButtonProps {
    isLoading: boolean;
    onClick?: () => void;
    style?: CSSProperties;
}

const RefreshButton: FunctionComponent<PropsWithChildren<RefreshButtonProps & LocalizeContextProps>> = (props) => {
    const style = { color: '#707070', border: 'none', ...(props.style || {}) };

    if (props.isLoading) {
        return (
            <button className="btn" disabled={true} style={style} title={props.translate("REFRESH").toString()}>
                <FontAwesomeIcon icon={faSyncAlt} spin={true} />
                {props.children}
            </button>
        );
    } else {
        return (
            <button className="btn" onClick={props.onClick} style={style} title={props.translate("REFRESH").toString()}>
                <FontAwesomeIcon icon={faSyncAlt} />
                {props.children}
            </button>
        );
    }
};

export default withLocalize(RefreshButton);