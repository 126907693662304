import React, {FunctionComponent, useEffect, useState} from "react";
import "./credit-information-modal.css";
import {Button, Modal} from "react-bootstrap";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {ConsumerLoan} from "../../models/ConsumerLoan";
import {CreditInformation} from "../../models/CreditInformation";
import {formatDate} from "../../../../common/helpers/dateFormatter";
import {getCreditInformation} from "../../api/getCreditInformation";
import CreditInformationCompare from "./CreditInformationCompare";
import CreditInformationDetails from "./CreditInformationDetails";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "../../../../common/components/Tooltip";

interface CreditInformationModalProps extends LocalizeContextProps {
    show: boolean;
    application: ConsumerLoan;
    personId: number;
    onHide: () => void;
}

const CreditInformationModal: FunctionComponent<CreditInformationModalProps> = (props) => {
    const [latest, setLatest] = useState<CreditInformation>();
    const [previous, setPrevious] = useState<CreditInformation>();
    const [compareMode, setCompareMode] = useState<boolean>(false);
    
    useEffect(() => {
        if (props.show && !latest) {
            getCreditInformation(props.personId, props.application.id).then(res => {
                setLatest(res.data[0]);
                setPrevious(res.data[1]);
            });
        }
    }, [props.show]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <Modal 
            className="credit-information-modal" 
            show={props.show} 
            centered={true}
            size="xl"
            onHide={props.onHide}
        >
            <Modal.Header>
                <Modal.Title>
                    <div>
                        <Translate
                            id={!compareMode ?
                                `CREDIT_INFORMATION_MODAL.${latest ? 'CREDIT_INFORMATION_FROM' : 'CREDIT_INFORMATION'}` :
                                `CREDIT_INFORMATION_MODAL.COMPARING`
                            }
                            data={{
                                latest: formatDate(latest?.timestamp, props.translate),
                                previous: formatDate(previous?.timestamp, props.translate)
                            }}
                        />
                        {latest &&
                            <Tooltip content={<Translate id="PRINT"/>}>
                                <FontAwesomeIcon
                                    icon={faPrint}
                                    className="print-credit-information"
                                    onClick={window.print}
                                />
                            </Tooltip>
                        }
                    </div>
                    {previous && 
                        <Button 
                            className="compare-credit-information"
                            size="sm" 
                            variant="primary"
                            onClick={() => setCompareMode(!compareMode)}
                        >
                            <Translate
                                id={`CREDIT_INFORMATION_MODAL.${compareMode ? 'SHOW_LATEST': 'COMPARE_WITH'}`}
                                data={{previous: formatDate(previous.timestamp, props.translate)}}
                            />
                        </Button>
                    }
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {compareMode ? 
                    <CreditInformationCompare latest={latest!} previous={previous!}/> :
                    <CreditInformationDetails details={latest}/>
                }
            </Modal.Body>
        </Modal>
    );
}
export default withLocalize(CreditInformationModal);