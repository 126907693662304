import { AxiosPromise } from 'axios';
import { AxiosClientFactory } from '../../../common/helpers/AxiosClientFactory';
import { CachingConfig } from '../../../common/helpers/Caching';


export const getUnqualifiedBanks = (personId: string | number, loanId: string | number): AxiosPromise<number[]> => {
    return AxiosClientFactory
        .getInstance()
        .get<number[]>(`/api/applicants/${personId}/consumerLoans/${loanId}/unqualifyingbanks`, { cache: CachingConfig.DEFAULT });
};
