import React, { FunctionComponent } from "react";
import {
    getFilterProp,
    RecipientsFilterProps,
    renderBooleanInputFieldFilter,
    renderDateTimeInputFieldFilter,
    renderDropdownInputFieldFilter,
    renderStringCollectionInputFilter,
    renderSubstatusesInputFieldFilter
} from "./RecipientsFilterContainer";
import {
    CampaignRecipientStatus,
    CampaignType
} from "../../../../../../common/models/Campaign";
import TextInputField from "../../../../../../common/components/input-fields/TextInputField";
import { getEnumTranslationKey } from "../../../../../../common/helpers/getTranslationKey";
import { isValueSet } from "../../../../../../common/helpers/isValueSet";
import mapEnumToSelectableItems from "../../../../../../common/helpers/mapEnumToSelectableItems";
import CustomerAdvisorsInputField from "../../../../../../common/components/input-fields/CustomerAdvisorsInputField";
import InputField from "../../../../../../common/components/input-fields/InputField";
import { ProductType } from "../../../../../../common/models/ProductType";
import { ApplicationStatus } from "../../../../../../common/models/ApplicationStatus";

const RecipientsFilter: FunctionComponent<RecipientsFilterProps> = (props) => (
    <>
        <div className="readonly">
            {renderLastApplicationStatusFilter(props)}
            {renderLanguageFilter(props)}
            {renderProductTypesFilter(props)}
            {renderIncludedSubstatusesFilter(props)}
            {renderExcludedSubstatusesFilter(props)}
            {props.campaign ?
                props.campaign.type === CampaignType.SMS ? renderMobileNumbersFilter(props)
                    : renderEmailsFilter(props)
                : null
            }
            {renderLastCampaignFilter(props)}
            {renderSkipActiveFollowUpsFilter(props)}
            {renderSubmittedFromFilter(props)}
            {renderSubmittedToFilter(props)}
            {renderCustomerAdvisorsFilter(props)}
            {renderExcludeInkassoFilter(props)}
            {renderHasPropertyFilter(props)}
        </div>
        <div style={{ width: 'calc(100% / 6)', marginTop: '12px' }}>
            {renderRecipientStatusFilter(props)}
        </div>
    </>
);

const renderLastApplicationStatusFilter = (props: RecipientsFilterProps) => {
    const value = getFilterProp<ApplicationStatus | null>('lastApplicationStatus', props);
    if (!isValueSet(value)) {
        return null;
    }

    const lastApplicationStatus = props.translate(getEnumTranslationKey(ApplicationStatus, value, 'LOAN_STATUS')).toString()
    return renderTextInputField(lastApplicationStatus, 'LOAN_STATUS.LABEL');
};

const renderLanguageFilter = (props: RecipientsFilterProps) => {
    const value = getFilterProp<string | null>('language', props);
    if (!isValueSet(value)) {
        return null;
    }

    const language = props.translate(`LANGUAGE.${value!.toUpperCase()}`).toString();
    return renderTextInputField(language, 'LANGUAGE.LABEL');
};

const renderProductTypesFilter = (props: RecipientsFilterProps) => {
    const value = getFilterProp<number[]>('productType', props);
    if (!value || !value.length) {
        return null;
    }

    const productTypes = value.map(productType => props.translate(getEnumTranslationKey(ProductType, productType, 'PRODUCT_TYPES')).toString()).join(', ');
    return renderTextInputField(productTypes, 'PRODUCT_TYPES.LABEL');
};

const renderCustomerAdvisorsFilter = (props: RecipientsFilterProps) => {
    const value = getFilterProp<number[]>('customerAdvisors', props) || [];
    if (!value || !value.length) {
        return null;
    }

    return (
        <div>
            <InputField descriptionKey="CUSTOMER_ADVISOR">
                <CustomerAdvisorsInputField customerAdvisorIds={value} />
            </InputField>
        </div>
    );
};

const renderIncludedSubstatusesFilter = (props: RecipientsFilterProps) =>
    renderSubstatusesInputFieldFilter('includedSubstatuses', 'INCLUDED_SUBSTATUSES', props);

const renderExcludedSubstatusesFilter = (props: RecipientsFilterProps) =>
    renderSubstatusesInputFieldFilter('excludedSubstatuses', 'EXCLUDED_SUBSTATUSES', props);

const renderLastCampaignFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('lastCampaign', 'CAMPAIGNS_VIEW.LAST_CAMPAIGN', props);

const renderSkipActiveFollowUpsFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('skipActiveFollowUps', 'CAMPAIGNS_VIEW.SKIP_ACTIVE_FOLLOW_UPS', props);

const renderSubmittedFromFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('submittedFrom', 'SUBMITTED_FROM', props);

const renderSubmittedToFilter = (props: RecipientsFilterProps) =>
    renderDateTimeInputFieldFilter('submittedTo', 'SUBMITTED_TO', props);

const renderExcludeInkassoFilter = (props: RecipientsFilterProps) =>
    renderBooleanInputFieldFilter('excludeInkasso', 'CAMPAIGNS_VIEW.EXCLUDE_INKASSO', props);

const renderHasPropertyFilter = (props: RecipientsFilterProps) =>
    renderBooleanInputFieldFilter('hasProperty', 'PROPERTY_REQUIRED', props);

const renderMobileNumbersFilter = (props: RecipientsFilterProps) =>
    renderStringCollectionInputFilter('mobileNumbers', 'MOBILE_NUMBERS', props);

const renderEmailsFilter = (props: RecipientsFilterProps) =>
    renderStringCollectionInputFilter('emails', 'EMAILS', props);

const renderRecipientStatusFilter = (props: RecipientsFilterProps) => (
    renderDropdownInputFieldFilter(
        'recipientStatus',
        'CAMPAIGN_RECIPIENT_STATUS.LABEL',
        mapEnumToSelectableItems(
            CampaignRecipientStatus,
            item => props.translate(getEnumTranslationKey(CampaignRecipientStatus, item, 'CAMPAIGN_RECIPIENT_STATUS', 'ALL')).toString(),
            true
        ),
        props
    )
);

const renderTextInputField = (value: string, descriptionKey: string) => (
    <div>
        <TextInputField
            name={descriptionKey}
            value={value}
            descriptionKey={descriptionKey}
        />
    </div>
);

export default RecipientsFilter;