import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import React, {FunctionComponent, useMemo, useState} from "react";
import {SettingsProps} from "../../../common/interfaces/SettingsProps";
import {connect} from "react-redux";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {getApplicantsFilterBookmarks} from "../../../common/helpers/settingsHelpers";
import {ApplicantsViewFilter} from "./ApplicantsView";
import {bindActionCreators, Dispatch} from "redux";
import {
    ApplicantsViewTableActionCreators,
    ApplicantsViewTableActionProps
} from "../actions/ApplicantsViewDataActionCreators";
import {Storage} from "../../../common/helpers/Storage";
import {ApplicantsTableStateProps} from "../../../common/interfaces/ApplicantsTableStateProps";
import TextInputField from "../../../common/components/input-fields/TextInputField";
import Tooltip from "../../../common/components/Tooltip";


interface IApplicantsFilterBookmarks {
    [bookmarkName: string]: ApplicantsViewFilter
}

type ApplicantsFilterBookmarksStateProps = SettingsProps & LocalizeContextProps & ApplicantsTableStateProps;
type ApplicantsFilterBookmarksDispatchProps = ApplicantsViewTableActionProps;
type ApplicantsFilterBookmarksProps = ApplicantsFilterBookmarksStateProps & ApplicantsFilterBookmarksDispatchProps;

const ApplicantsFilterBookmarks: FunctionComponent<ApplicantsFilterBookmarksProps> = (props) => {
    const currentFilter = props.tableState;
    const personalBookmarksStorageKey = 'applicants-filter-personal-bookmarks';
    const commonBookmarks: IApplicantsFilterBookmarks = useMemo(() => getApplicantsFilterBookmarks(props), [props]);
    const [personalBookmarks, setPersonalBookmarks] = useState<IApplicantsFilterBookmarks>(JSON.parse(Storage.getItem(personalBookmarksStorageKey) || '{}'));
    const [addBookmarkName, setAddBookmarkName] = useState<string>();
    
    const onBookmarkSelect = (filter: ApplicantsViewFilter) => {
        props.setFilters(filter);
    }
    
    const onBookmarkAdd = (name?: string) => {
        if (!name) {
            return;
        }
        personalBookmarks[name] = currentFilter;
        setAddBookmarkName(undefined);
        updatePersonalBookmarks();
    }
    
    const onBookmarkDelete = (name: string) => {
        delete personalBookmarks[name];
        updatePersonalBookmarks();
    }
    
    const updatePersonalBookmarks = () => {
        setPersonalBookmarks({...personalBookmarks});
        Storage.setItem(personalBookmarksStorageKey, JSON.stringify(personalBookmarks));
    }
    
    const isCurrentFilter = (filter: ApplicantsViewFilter) => JSON.stringify(filter) === JSON.stringify(currentFilter);
    
    return (
        <Dropdown drop="down" align="end" className="applicants-filter-bookmarks">
            <Dropdown.Toggle className="btn-white-bg" style={{ padding: 0 }}>
                <FontAwesomeIcon icon={faBookmark} />
            </Dropdown.Toggle>
            <div className="dropdown-arrow-down" />
            <Dropdown.Menu>
                {!!Object.keys(commonBookmarks).length && 
                    <>
                        <Dropdown.Header key="common-bookmarks">
                            <Translate id="COMMON_BOOKMARKS" />
                        </Dropdown.Header>
                        {Object.entries(commonBookmarks).map(([name, filter]) => (
                            <Dropdown.Item key={name} active={isCurrentFilter(filter)} onClick={() => onBookmarkSelect(filter)}>
                                {name}
                            </Dropdown.Item>
                        ))}
                    </>
                }
                {!!Object.keys(personalBookmarks).length && 
                    <>
                        <Dropdown.Divider key="bookmarks-divider" />
                        <Dropdown.Header key="personal-bookmarks">
                            <Translate id="PERSONAL_BOOKMARKS" />
                        </Dropdown.Header>
                        {Object.entries(personalBookmarks).map(([name, filter]) => (
                            <Dropdown.Item key={name} active={isCurrentFilter(filter)} onClick={() => onBookmarkSelect(filter)}>
                                {name}
                                <Tooltip content={<Translate id="DELETE_BOOKMARK"/>}>
                                    <FontAwesomeIcon
                                        className={`delete-bookmark-button`}
                                        icon={faTrashAlt}
                                        onClick={(e) => {e.stopPropagation(); onBookmarkDelete(name)}}
                                    />
                                </Tooltip>
                            </Dropdown.Item>
                        ))}
                    </>
                }
                <Dropdown.Divider key="add-bookmark-divider" className="add-bookmark-divider"/>
                <Dropdown.Header key="add-bookmark" className="add-bookmark">
                    <TextInputField 
                        name="add-bookmark-name"
                        editMode
                        placeholderKey="TYPE_BOOKMARK_NAME"
                        value={addBookmarkName} 
                        onValueChanged={setAddBookmarkName}
                    />
                    <Tooltip content={<Translate id="ADD_BOOKMARK"/>}>
                        <FontAwesomeIcon
                            className={`add-bookmark-button ${!addBookmarkName ? 'disabled' : ''}`}
                            icon={faPlus}
                            onClick={() => onBookmarkAdd(addBookmarkName!)}
                        />
                    </Tooltip>
                </Dropdown.Header>
            </Dropdown.Menu>
        </Dropdown>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.applicantsViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...ApplicantsViewTableActionCreators
}, dispatch);

export default connect<ApplicantsFilterBookmarksStateProps, ApplicantsFilterBookmarksDispatchProps>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(ApplicantsFilterBookmarks));
