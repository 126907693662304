import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from './en.translations.json';

export enum Language {
    English = 'en'
}

type Resource = {translation: any};
type Resources = Partial<{
    [key in Language]: Resource;
}>;

// order relates to fallback if translation is missing
export const AVAILABLE_LANGUAGES = [
    Language.English
];

const getResource = (lng: Language) => {
    switch (lng) {
        case Language.English:
            return en;
        default:
            return en;
    }
}

const resources: Resources = AVAILABLE_LANGUAGES.reduce((res, lng) => {
    res[lng] = {translation: getResource(lng)}
    return res;
}, {} as Resources)

export const getDefaultLanguage = (): Language => {
    let defaultLanguage = Language.English;
    const browserLanguage = navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
    switch (true) {
        case browserLanguage.includes('en') && AVAILABLE_LANGUAGES.includes(Language.English):
            defaultLanguage = Language.English;
            break;
    }
    return defaultLanguage;
}

let initialized = false;
export const initLocalization = (preferredLanguage?: Language | null) => {
    let lng = getDefaultLanguage();
    if (preferredLanguage && AVAILABLE_LANGUAGES.includes(preferredLanguage)) {
        lng = preferredLanguage;
    }
    if (!initialized) {
        i18n
            .use(initReactI18next)
            .init({
                resources,
                lng,
                fallbackLng: AVAILABLE_LANGUAGES
            });
        initialized = true;
    }
    return lng;
}
