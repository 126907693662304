import React, { FunctionComponent, useMemo } from 'react';
import { ApplicantBoxProps } from './ApplicantBox';
import { BooleanInputField } from '../../../common/components/input-fields/BooleanInputField';
import TextInputField from '../../../common/components/input-fields/TextInputField';
import NumberInputField, { NumberInputFieldKind } from '../../../common/components/input-fields/NumberInputField';
import DateTimeInputField, { DateTimeInputFieldKind } from '../../../common/components/input-fields/DateTimeInputField';
import DropdownInputField from '../../../common/components/input-fields/DropdownInputField';
import { getRepaymentMethods } from '../../../common/helpers/getRepaymentMethods';
import { getRepaymentMethodTranslateKey } from '../../../common/helpers/getRepaymentMethodTranslateKey';
import { RepaymentMethod } from '../../../common/models/RepaymentMethod';
import { withLocalize } from 'react-localize-redux';

const DanishApplicantFields: FunctionComponent<ApplicantBoxProps> = (props: ApplicantBoxProps) => {
    const onValueChanged = (value: string | number | boolean | Date | null | undefined, name: string): void => props.onApplicantChanged({ ...props.applicant, [name]: value });
    const getItemValue = (item: { name: string, value: string | RepaymentMethod }): string | RepaymentMethod => (item.value);
    const getItemDisplayValue = (item: { name: string }): string => (props.translate(item.name).toString());
    const repaymentMethods = useMemo(() => getRepaymentMethods().map((value) => ({ name: getRepaymentMethodTranslateKey(value), value })), []);

    return (
        <React.Fragment>
            <BooleanInputField
                descriptionKey="OWN_SUMMER_RESIDENCE"
                disabled={false}
                name="ownSummerResidence"
                editMode={true}
                onValueChanged={onValueChanged}
                value={props.applicant.ownSummerResidence}
            />
            <TextInputField
                descriptionKey="EMPLOYER_PHONE"
                editMode={true}
                name="employerPhone"
                required={props.isComplete ? 'EmployerPhoneIsRequired' : undefined}
                errors={props.errors}
                errorCodes={['EmployerPhoneIsRequired']}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.employerPhone}
            />
            <BooleanInputField
                descriptionKey="JOB_INSURANCE"
                disabled={false}
                name="jobInsurance"
                editMode={true}
                onValueChanged={onValueChanged}
                value={props.applicant.jobInsurance}
            />
            <NumberInputField
                descriptionKey="HOUSEHOLD_CARS"
                editMode={true}
                name="houseHoldCars"
                kind={NumberInputFieldKind.NumberOf}
                onValueChanged={onValueChanged}
                value={props.applicant.houseHoldCars}
            />
            <TextInputField
                descriptionKey="HOUSEHOLD_BANK_NAME"
                editMode={true}
                required={props.isComplete ? 'HouseholdBankNameIsRequired' : undefined}
                name="houseHoldBankName"
                errors={props.errors}
                errorCodes={['HouseholdBankNameIsRequired']}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.houseHoldBankName}
            />
            <DateTimeInputField
                descriptionKey="HOUSEHOLD_BANK_SINCE"
                name="bankSinceYear"
                editMode={true}
                required={props.isComplete ? 'BankSinceYearIsRequired' : undefined}
                errors={props.errors}
                errorCodes={['BankSinceYearIsRequired']}
                overrideInternalErrors={['valueMissing']}
                onValueChanged={onValueChanged}
                value={props.applicant.bankSinceYear}
                kind={DateTimeInputFieldKind.Year}
            />
            <BooleanInputField
                descriptionKey="CREDIT_CARD_OWNERSHIP"
                disabled={false}
                name="creditCardOwnership"
                editMode={true}
                onValueChanged={onValueChanged}
                value={props.applicant.creditCardOwnership}
            />
            <DropdownInputField
                descriptionKey="REPAYMENT_METHOD"
                editMode={true}
                name="repaymentMethod"
                required={props.isComplete ? 'RepaymentMethodIsRequired' : undefined}
                errors={props.errors}
                errorCodes={['RepaymentMethodIsRequired']}
                overrideInternalErrors={['valueMissing']}
                keyValue={getItemValue}
                displayValue={getItemDisplayValue}
                items={repaymentMethods}
                onValueChanged={onValueChanged}
                value={props.applicant.repaymentMethod}
            />
        </React.Fragment>
    );

};

export default withLocalize(DanishApplicantFields);
