import {LoanStatus} from "../../../../common/models/LoanStatus";

export enum BankDataField {
    CASE_ID = 'bankInternalRefId',
    CLAWBACK = 'clawbackAmount',
    ISSUED_ON = 'issuedOn',
    LOAN_STATUS = 'loanStatus',
    PAID_AMOUNT = 'paidAmount',
    PAYMENT_DATE = 'paymentDate'
}

interface ApplicationLink {
    applicationId?: number;
    personId?: number;
}

export enum BankDataKind {
    Multi,
    Date,
    Money,
    LoanStatus
}
type BankDataType = string[] | Date | number | LoanStatus;

export interface BankDataEntry {
    field: BankDataField;
    kind: BankDataKind;
    default?: BankDataType;
}

export enum ImportBankDataScenarioName {
    CLAWBACKS = 'clawbacks'
}

export interface ImportBankDataScenario {
    name: ImportBankDataScenarioName;
    entries: BankDataEntry[];
    matchedEntries: BankDataEntry[];
}

type OptionalObject<Keys extends string, TValue> = Partial<Record<Keys, TValue>>;
type JustPlainType<T> = T extends (infer U)[] ? JustPlainType<U> : T;
type AggregatedBankDataType = JustPlainType<BankDataType>;

export type BankData = OptionalObject<BankDataField, BankDataType>;
export type AggregatedBankData = OptionalObject<BankDataField, AggregatedBankDataType>;
export type MatchedBankData = AggregatedBankData & ApplicationLink;