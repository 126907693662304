import {FunctionComponent} from "react";
import {connect} from "react-redux";
import {SettingsProps} from "../interfaces/SettingsProps";
import {appendCurrencySymbolForCountryId} from "../helpers/currencySymbolFunctions";
import {getCountryId} from "../helpers/settingsHelpers";

interface NumberFormatterProps extends SettingsProps {
    value?: number | null;
}

const NumberFormatter: FunctionComponent<NumberFormatterProps> = (props) => {
    return (
        <>
            {appendCurrencySymbolForCountryId(props.value, getCountryId(props))}
        </>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});

export default connect<SettingsProps>(mapStateToProps)(NumberFormatter);