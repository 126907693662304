import React, {FunctionComponent, ReactElement, useEffect, useState} from "react";
import "./credit-information-modal.css";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {
    CreditInformation,
    CreditInformationCompanyEngagement,
    CreditInformationIncome,
    CreditInformationPaymentItem
} from "../../models/CreditInformation";
import {formatDate} from "../../../../common/helpers/dateFormatter";
import {
    companyEngagementsTableHeaders,
    CreditInformationDetailsTable,
    incomesTableHeaders,
    paymentsTableHeaders
} from "./CreditInformationDetails";
import {Compare, compare, CreditInformationComparison} from "./compareCreditInformation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import NumberFormatter from "../../../../common/components/NumberFormatter";

interface CreditInformationCompareProps extends LocalizeContextProps {
    latest: CreditInformation;
    previous: CreditInformation;
}

const CreditInformationCompare: FunctionComponent<CreditInformationCompareProps> = (props) => {
    const [comparison, setComparison] = useState<CreditInformationComparison>();
    
    useEffect(() => {
        if (props.latest && props.previous) {
            setComparison(compare(props.latest, props.previous))
        }
    }, [props.latest, props.previous]);
    
    if (!comparison) {
        return <Translate id="DATA_ARE_IDENTICAL"/>
    }

    return (
        <div className="credit-information-compare">
            {renderCreditInformationPersonalInfo(comparison)}
            {comparison.incomes?.length ? renderIncomesCompareTable(comparison.incomes) : null}
            {comparison.paymentRemarks?.length ? renderPaymentsCompareTable(comparison.paymentRemarks, <Translate id="CREDIT_INFORMATION_MODAL.PAYMENT_REMARKS"/>) : null}
            {comparison.voluntaryPledges?.length ? renderPaymentsCompareTable(comparison.voluntaryPledges, <Translate id="CREDIT_INFORMATION_MODAL.VOLUNTARY_PLEDGES"/>) : null}
            {comparison.companyEngagements?.length ? renderCompanyEngagementsCompareTable(comparison.companyEngagements) : null}            
        </div>
    );
}

const renderPaymentsCompareTable = (comparison: Compare<CreditInformationPaymentItem>[], title: ReactElement | string) => (
    <CreditInformationDetailsTable
        data={comparison}
        headers={paymentsTableHeaders}
        title={title}
        renderRow={compare => {
            const {latest, previous} = {...compare};
            return (
                <>
                    <td className="span-2">{renderChangeCell(
                        previous?.type, 
                        latest?.type, 
                        type => type || '-'
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.source, 
                        latest?.source,
                        source => source || '-'
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.date,
                        latest?.date,
                        date => formatDate(date)
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.refNr, 
                        latest?.refNr,
                        refNr => refNr || '-'
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.amount,
                        latest?.amount,
                        amount => <NumberFormatter value={amount}/>
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.creditor, 
                        latest?.creditor,
                        creditor => creditor || '-'
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.status, 
                        latest?.status,
                        status => status || '-'
                    )}</td>
                </>
            )
        }}
    />
)

const renderCreditInformationPersonalInfo = (comparison: CreditInformationComparison) => (
    <div className="personal-info">
        <div>                    
            <span className="uppercase title">
                {comparison.firstName?.latest} {comparison.middleName?.latest} {comparison.lastName?.latest}
            </span>
            <span>{comparison.address?.latest}, {comparison.postCode?.latest} {comparison.city?.latest}</span>
            <span className="secondary">
                {formatDate(comparison.birthDate?.latest)}, {comparison.gender?.latest}
            </span>
            <span className="secondary">{comparison.statusText?.latest}</span>
        </div>
        <div>
            <span className="uppercase secondary"><Translate id="CREDIT_INFORMATION_MODAL.CREDIT_SCORE"/></span>
            <span className="credit-score">
                {comparison.creditScore?.previous !== comparison.creditScore?.latest ?
                    <>
                        <span className="secondary">{comparison.creditScore?.previous}</span>
                        <FontAwesomeIcon icon={faArrowRight} style={{margin: '0 8px'}}/>
                    </> : null
                }
                {comparison.creditScore?.latest}
            </span>
        </div>
    </div>
)

const renderIncomesCompareTable = (comparison: Compare<CreditInformationIncome>[]) => (
    <CreditInformationDetailsTable
        data={comparison}
        headers={incomesTableHeaders}
        title={<Translate id="CREDIT_INFORMATION_MODAL.INCOMES"/>}
        renderRow={compare => {
            const {latest, previous} = {...compare};
            return (
                <>
                    <td>{renderChangeCell(
                        previous?.incomeYear,
                        latest?.incomeYear,
                        incomeYear => incomeYear
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.grossIncome,
                        latest?.grossIncome,
                        grossIncome => <NumberFormatter value={grossIncome}/>
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.netIncome,
                        latest?.netIncome,
                        netIncome => <NumberFormatter value={netIncome}/>
                    )}</td>
                    <td>{renderChangeCell(
                        previous?.sumTax,
                        latest?.sumTax,
                        sumTax => <NumberFormatter value={sumTax}/>
                    )}</td>
                </>
            )
        }}
    />
)

const renderCompanyEngagementsCompareTable = (comparison: Compare<CreditInformationCompanyEngagement>[]) => (
    <CreditInformationDetailsTable
        data={comparison}
        headers={companyEngagementsTableHeaders}
        title={<Translate id="CREDIT_INFORMATION_MODAL.ROLES"/>}
        renderRow={compare => {
            const {latest, previous} = {...compare};
            return (
                <>
                    <td>{renderChangeCell(previous?.function, latest?.function)}</td>
                    <td>{renderChangeCell(
                            previous?.appointmentDate,
                            latest?.appointmentDate,
                        appointmentDate => formatDate(appointmentDate)
                    )}</td>
                    <td>{renderChangeCell(previous?.companyName, latest?.companyName)}</td>
                    <td>{renderChangeCell(previous?.companyNumber, latest?.companyNumber)}</td>
                    <td>{renderChangeCell(
                            previous?.companyRegistrationDate,
                            latest?.companyRegistrationDate,
                            companyRegistrationDate => formatDate(companyRegistrationDate)
                    )}</td>
                    <td>{renderChangeCell(previous?.companyStatusText, latest?.companyStatusText)}</td>
                    <td>{renderChangeCell(
                            previous?.creditLimit,
                            latest?.creditLimit,
                            creditLimit => <NumberFormatter value={creditLimit}/>
                    )}</td>
                    <td>{renderChangeCell(
                            previous?.creditRating,
                            latest?.creditRating,
                            creditRating => creditRating || '-'
                    )}</td>
                </>
            )
        }}
    />
)

function renderChangeCell<T>(previous?: T, latest?: T, renderData?: (data?: T) => ReactElement | string | number | null | undefined) {
    if ((!previous && !latest) || previous === latest) {
        return renderData?.(latest) || latest;
    }
    if (!previous && latest) {
        return <span className="added">{renderData?.(latest) || latest}</span>
    }
    if (previous && !latest) {
        return <span className="removed">{renderData?.(previous) || previous}</span>
    }
    return (
        <div className="modified">
            <span className="secondary">{renderData?.(previous) || previous}</span>
            <FontAwesomeIcon icon={faArrowDown}/>
            <span>{renderData?.(latest) || latest}</span>
        </div>
    )
}




export default withLocalize(CreditInformationCompare);