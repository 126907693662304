import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, ReactNode } from 'react';
import { User } from '../models/User';
import Tooltip from '../../../common/components/Tooltip';
import { Translate } from 'react-localize-redux';
import Translation from '../../../common/components/Translation';

interface ChangeItemProps {
    propertyName?: ReactNode | string;
    oldValue?: string;
    newValue?: string;
    user: User | null;
    customContent?: ReactNode;
}

const ChangeItem: FunctionComponent<ChangeItemProps> = (props) => {
    return (
        <>
            <td>
                <div className="change-log-icon">
                    <Tooltip content={props.user ? getUserDisplayName(props.user) : undefined}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </Tooltip>
                </div>
            </td>
            <td>{props.customContent || <Translate id="WAS_CHANGED_FROM_TO" data={getContentData(props)}/>}</td>
        </>
    );
};

const getContentData = (props: ChangeItemProps) => {
    return {propertyName: props.propertyName, oldValue: renderValue(props.oldValue), newValue: renderValue(props.newValue)};
};

const renderValue = (value: string | undefined) => {
    if (value === undefined || value === null || value === '') {
        return <strong><Translate id="EMPTY_VALUE" /></strong>;
    }

    return <strong>{value}</strong>;
};

const getUserDisplayName = (user: User) => {
    return user.username === 'BankStatusWebJob' ? <Translate id="THE_SYSTEM_UPDATED"/> : <Translation id={user.username} default={user.displayName}/>;
};

export default ChangeItem;
