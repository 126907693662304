import { faPaperPlane, faTimesCircle, faCheckDouble, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import React, { FunctionComponent, useState } from 'react';
import ActionIcon from '../../../../common/components/ActionIcon';
import { isBankReportReadOnly, hasBankIntegrtaion } from '../../helpers/transactionsLogic';
import { BankStatus } from '../../../../common/models/BankStatus';
import { Bank } from '../../models/Bank';
import { Transaction } from '../../models/Transaction';
import { ProductType } from '../../../../common/models/ProductType';
import SendToBanksConfirmationModal from './SendToBanksConfirmationModal';

interface BankRepliesButtonsProps {
    selectionMode: boolean;
    selectedBankIds: number[];
    editMode: boolean;
    canEnterEdit: boolean;
    banks: Bank[];
    transactions: Transaction[];
    productType: ProductType | null;
    isRefreshing: boolean;
    onRefresh: () => void;
    setSelectionMode(selectionMode: boolean): void;
    setSelectedBankIds(selectedBankIds: number[]): void;
    addAutomaticBankReport(selectedBankIds: number[], validate: boolean, actionKey: string): void;
    createEmptyTransaction(bankId: number): Transaction;
}

const BankRepliesButtons: FunctionComponent<BankRepliesButtonsProps> = (props) => {
    const [show, setShow] = useState<boolean>(false);
    const onSendWithValidation = () => {
        props.addAutomaticBankReport(props.selectedBankIds, true, 'SEND_MANY');
        props.setSelectionMode(false);
        props.setSelectedBankIds([]);
        setShow(false);
    };

    const onSendWithoutValidation = () => {
        props.addAutomaticBankReport(props.selectedBankIds, false, 'SEND_MANY');
        props.setSelectionMode(false);
        props.setSelectedBankIds([]);
        setShow(false);
    };

    return (
        <React.Fragment>
            {renderSelectionButtons(props, () => setShow(true))}
            <SendToBanksConfirmationModal
                show={show}
                selectedBankIds={props.selectedBankIds}
                onSendWithValidation={onSendWithValidation}
                onSendWithoutValidation={onSendWithoutValidation}
                onCancel={() => setShow(false)}
            />
        </React.Fragment>
    );
};

function renderSelectionButtons(props: BankRepliesButtonsProps, showModal: () => void) {
    if (props.selectionMode) {
        const onCancel = () => {
            props.setSelectionMode(false);
            props.setSelectedBankIds([]);
        };

        const isDisabled = props.selectedBankIds.length === 0 || props.editMode || !props.canEnterEdit
            || props.selectedBankIds.some((bid) => !checkIfCanSend(bid, props));

        return (
            <div>
                <ActionIcon
                    action={showModal}
                    icon={faPaperPlane}
                    tooltipKey="SEND_MANY"
                    disabled={isDisabled}
                />
                <ActionIcon
                    action={onCancel}
                    icon={faTimesCircle}
                    tooltipKey="CANCEL"
                />
            </div>
        );
    }

    const onSwitch = () => props.setSelectionMode(true);
    return (
        <div>
            <ActionIcon
                action={props.onRefresh}
                icon={faSyncAlt}
                iconSpin={props.isRefreshing}
                tooltipKey="REFRESH"
                disabled={props.isRefreshing}
            />
            <ActionIcon
                action={onSwitch}
                icon={faCheckDouble}
                tooltipKey="SELECT_MANY_BANKS"
                disabled={!props.canEnterEdit}
            />
        </div>
    );
}

function checkIfCanSend(bankId: number, props: BankRepliesButtonsProps) {
    const bank = props.banks.find((b) => b.id === bankId);
    const transaction = props.transactions.find((t) => t.bankId === bankId) || props.createEmptyTransaction(bankId);
    return !isBankReportReadOnly(transaction) && bank && hasBankIntegrtaion(bank, props.productType) &&
        (transaction.id === null || // send
            (transaction.bankReportId !== undefined && transaction.bankStatus !== BankStatus.Paid && transaction.bankStatus !== BankStatus.Denied) // resend
        );
}

export default BankRepliesButtons;
